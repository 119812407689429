import { Box, Button, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { BackIcon } from "../../utils/icon/AdminPage/Icon"


export const RoundedBtn = ({ col = 'black', txt, fn }) => {
    return (
        <Button onClick={fn} sx={{ color: col, background: 'transparent', height: '20px', border: `1px solid ${col}`, borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: 'transparent' } }}>{txt}</Button>
    )
}

export const ComponentTopBtn = ({ btn }) => {
    const { name, tab, setTab, i, first, last } = btn;
    return (
        <Button disableTouchRipple disableFocusRipple disableGutters onClick={() => setTab(i)} sx={{ border: '0.8px solid #797F8F80', minWidth: '168px', background: tab === i ? '#8A18D0' : 'inherit', color: tab === i ? '#fff' : '#000', px: '18px', py: '15px', borderRadius: 0, borderTopLeftRadius: { xs: '8px', md: first ? '8px' : '0px' }, borderTopRightRadius: { xs: '8px', md: last ? '8px' : '0px' }, mx: { xs: '5px', md: 0 }, my: { xs: 1, md: 0 }, textTransform: 'capitalize', borderBottomLeftRadius: { xs: '8px', md: 0 }, borderBottomRightRadius: { xs: '8px', md: 0 }, '&:hover': { background: tab === i ? '#8A18D0' : 'inherit', color: tab === i ? '#fff' : '#000' }, }}>
            {name}
        </Button>
    )
}


export const Back = ({ prop }) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (prop?.url) {
            navigate(prop.url);
        } else {
            navigate(-1);
        }
    };
    return (
        <Button disableRipple sx={{ textTransform: 'capitalize', '&:hover': { background: 'transparent' }, mb: 2 }} onClick={() => { handleBack() }}>
            {/* <Button disableRipple sx={{ textTransform: 'capitalize', mb: 2 }} onClick={() => { navigate(-1) }}> */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <Link to={prop.url} style={{ display: 'flex', alignItems: 'center' }}> */}
                <BackIcon />
                <Typography sx={{ pl: 1, fontWeight: '600', fontSize: '20px', color: '#000', }}>
                    {prop.txt}
                </Typography>
                {/* </Link> */}
            </Box>
        </Button>
    )
}

export const ComboTxtBtn = ({ prop }) => {
    const { name, txtStyl, btnIkn, btnTxt, btnTxtStyl, btnStyl, btnLnk, fn } = prop
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
            {name && <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000000', ...txtStyl }}>{name}</Typography>}
            {btnTxt
                &&
                <Link to={btnLnk}>
                    <Button onClick={() => fn()} disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', px: 2, color: '#fff', textTransform: 'capitalize', '&:hover': { background: '#B37FFC' }, ...btnStyl, }}>
                        {btnIkn && btnIkn}
                        <Typography sx={{ ml: btnIkn ? 1 : 0, fontWeight: '600', fontSize: '10px', ...btnTxtStyl }}>{btnTxt}</Typography>
                    </Button>
                </Link>
            }
        </Box>
    )
}

export const SideNavBtnsAccordation = ({ e, index, nav, subNav, onNavClick, onSubNavClick, navStyles, subNavStyles }) => {
    return (
        <ListItem disableRipple disableGutters disablePadding sx={{ width: '100%', px: 2 }} key={index}>
            <Accordion sx={navStyles.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: nav === index ? navStyles.expandIconActive : navStyles.expandIconInactive }} />} sx={{ ...navStyles.summary, background: nav === index ? navStyles.summaryActive : '', color: nav === index ? navStyles.summaryTextActive : '', }} >
                    <ListItemButton disableTouchRipple disableFocusRipple disableGutters disablePadding sx={{ ...navStyles.listItemButton, color: nav === index ? navStyles.listItemButtonTextActive : '', '&:hover': { background: nav === index ? navStyles.listItemButtonHoverActive : navStyles.listItemButtonHoverInactive, }, }} onClick={() => { onNavClick(index); }} >
                        {e.icon}
                        <Typography sx={{ pl: '15px', textWrap: 'nowrap', transition: 'width 0.5s ease-in-out', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }} > {e.name} </Typography>
                    </ListItemButton>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0, py: 0 }}>
                    {e.subNav.map((f, j) => (
                        <Link to={f.to} className="w-[100%]" key={j}>
                            <ListItemButton disableTouchRipple disableFocusRipple disableGutters disablePadding sx={{ ...subNavStyles.listItemButton, background: subNav === j ? subNavStyles.listItemButtonActive : '', color: subNav === j ? subNavStyles.listItemButtonTextActive : '', '&:hover': { background: subNav === j ? subNavStyles.listItemButtonHoverActive : subNavStyles.listItemButtonHoverInactive, }, }} onClick={() => { onSubNavClick(j); }} >
                                <Typography sx={{ pl: '15px', textWrap: 'nowrap', transition: 'width 0.5s ease-in-out', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', }} > {f.name} </Typography>
                            </ListItemButton>
                        </Link>
                    ))}
                </AccordionDetails>
            </Accordion>
        </ListItem>
    );
};
export const SideNavBtns = () => { }