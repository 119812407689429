import { Box, Typography } from "@mui/material"
import { PieChart } from "@mui/x-charts"
import { ComboTxtBtn } from "../../../../../../components/@extended/Button"


export const Pie = ({ prop }) => {
    const ser = [
        {
            data: prop?.data,
            highlightScope: { fade: 'global', highlight: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            innerinnerRadius: 30,
            outerRadius: 100,
            paddingAngle: 0,
            cornerRadius: 0,
            startAngle: -90,
            endAngle: 270,
            cx: 98,
            cy: 100,
        }
    ]
    return (
        <Box>
            {/* <Typography sx={{ fontWeight: '600', fontSize: '16px', color: '#000', my: 1 }}>Overall Rating</Typography> */}
            <ComboTxtBtn prop={prop} />
            {prop?.data ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1, width: '100%', maxWidth: '100%', border: '1px solid #EAECF0', borderRadius: '8px', gap: 1 }}>
                    <Box sx={{ minWidth: '200px', }}>
                        <Box sx={{ position: 'relative', width: '200px', height: '200px' }}>
                            {/* PieChart */}
                            <PieChart
                                slotProps={{ legend: { hidden: true } }}
                                height="210"
                                width="210"
                                series={ser}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                }}
                            >
                                <Box sx={{ height: '70px', width: '70px', borderRadius: '50%', background: '#F3E7FA', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <Typography sx={{ fontWeight: '700', fontSize: '14px' }}>{prop?.num}</Typography>
                                    <Typography variant="h6" sx={{ fontSize: '10px', color: '#2E2E30' }}>{prop?.title}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: 'calc(100% - 200px)' }}>
                        <Box>
                            <Box sx={{ height: '28px', background: '#F3E7FA', borderRadius: '4px', p: 2, my: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: '12px', color: '#000', fontWeight: '600', }}>{prop?.sideTitle}</Typography>
                            </Box>
                            <Box sx={{}}>
                                {prop?.sideDatas?.map((e, i) => (
                                    <Box key={i} sx={{ display: 'flex', alignItems: 'center', my: 1, }}>
                                        <Box sx={{ background: e.color, height: '20px', width: '20px', borderRadius: '2px' }}></Box>
                                        <Typography sx={{ fontSize: '10px', ml: 1, color: '#4F4F4F', fontWeight: '500' }}>{e.text}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                :
                <Box sx={{ height: '215px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #EAECF0', borderRadius: '8px', }}>
                    <Typography>{prop?.load ? 'Loading...' : 'Data Not Available'}</Typography>
                </Box>
            }
        </Box>
    )
}
const valueFormater = (v) => {
    return ``;
}