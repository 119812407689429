import { Avatar, Box, Divider, Rating, Typography, Select, MenuItem } from "@mui/material"
import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart';
import { CorporateNormalUserTable } from "../../../../../../components/@extended/ModifiedTable.jsx";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { SellerData } from "../../../../../../utils/data/SellerData.jsx";
import { modifyUserSellerEcormTable, modifyUserSellerProductTable, modifyUserSellerTable } from "../../../../../../components/@extended/CustomFunctions.jsx";
// import User from '../../../../../assets/logo/user.png'
import User from '../../../../../../assets/logo/user.png'
import { GSTIcon, InviteIcon, LocIcon, PhnIcon } from "../../../../../../utils/icon/CorporateNormalUserDatas/Icon.js";
import { SimpleCard, SimpleCard2, ReviewCard } from "../../../../../../components/card/Plan/index.jsx";
import StarIcon from '@mui/icons-material/Star';
import { useLocation } from "react-router-dom";
import http from '../../../../../../utils/http-common.js'
// import User from '../../assets/logo/user.png'

// Seller Profile - It consists of Profile Pic, name, description, gst, address, mail, phn
export const SellerProfile = ({ prop }) => {
    const name = prop?.seller_details?.Seller_name || '';
    const description = prop?.seller_details?.desc || ''
    const gst = prop?.seller_details?.Seller_nam || ''
    const address = prop?.seller_details?.GST_address || ''
    const mail = prop?.seller_details?.Seller_email || ''
    const phn = prop?.seller_details?.GST_phone || ''
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Avatar src={User} alt={''} sx={{ mr: 2, height: '85px', width: '85px', border: '2px solid #0000001A', p: '10px', borderRadius: '50%', background: '#EDE6FF' }} variant="square" />
            <Box>
                <Typography sx={{ fontWeight: '600', fontSize: '20px', lineHeight: '28px', color: '#000' }}>{name}</Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '14px', color: '#25252D' }}>{description}</Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    {gst &&
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <GSTIcon />
                                <Typography sx={{ fontWeight: '400', fontSize: '12px', color: '#25252D' }}>{gst}</Typography>
                            </Box>
                            <Divider sx={{ background: '#25252D', width: '0.5px' }} orientation="vertical" flexItem />
                        </>
                    }
                    {address &&
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <LocIcon />
                                <Typography sx={{ fontWeight: '400', fontSize: '12px', color: '#25252D' }}>{address}</Typography>
                            </Box>
                            <Divider sx={{ background: '#25252D', width: '0.5px' }} orientation="vertical" flexItem />
                        </>
                    }
                    {mail &&
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <InviteIcon />
                                <Typography sx={{ fontWeight: '400', fontSize: '12px', color: '#25252D' }}>{mail}</Typography>
                            </Box>
                            <Divider sx={{ background: '#25252D', width: '0.5px' }} orientation="vertical" flexItem />
                        </>
                    }
                    {phn &&
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <PhnIcon />
                            <Typography sx={{ fontWeight: '400', fontSize: '12px', color: '#25252D' }}>{phn}</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

// Seller Table - It consists of Brand, Product List, MRP, Discount, Final Price, Link
export const SellerTbl = () => {
    const [tab, setTab] = useState(0);
    const [seller_id, setSeller_id] = useState(null);
    const [resP, setResP] = useState([]);
    const [resE, setResE] = useState([]);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const subnav = useSelector((state) => state.navs.sideNav.subNav);
    const [subNav, setSubNav] = useState(subnav !== -1 ? subnav : 0);
    // const tbl = modifyUserSellerProductTable(SellerData[subNav].subBrandDetails);
    const tblP = tab === 0 ? modifyUserSellerProductTable(resP) : modifyUserSellerEcormTable(resE);
    const tblE = tab === 0 ? modifyUserSellerProductTable(resP) : modifyUserSellerEcormTable(resE);
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);

    const filteredDataP = tblP.filter((item) => {
        const searchLower = searchTerm.toLowerCase();

        const brandMatch = item?.brand && typeof item.brand === 'string' && item.brand.toLowerCase().includes(searchLower);
        const productListMatch = item?.product_list && typeof item.product_list === 'string' && item.product_list.toLowerCase().includes(searchLower);
        const mrpMatch = item?.MRP && typeof item.MRP === 'string' && item.MRP.toLowerCase().includes(searchLower);

        return brandMatch || productListMatch || mrpMatch;
    });
    if (filteredDataP.length === 0) {
        filteredDataP.push({
            brand: '',
            product_list: '',
            MRP: '',
            discount: '',
            final_price: '',
            Link: '',
        });
    }
    const filteredDataE = tblE.filter((item) => {
        const searchLower = searchTerm.toLowerCase();

        const eCommerceMatch = item?.['E-Commerce'] && typeof item['E-Commerce'] === 'string' && item['E-Commerce'].toLowerCase().includes(searchLower);
        const productsMatch = item?.Products && typeof item.Products === 'string' && item.Products.toLowerCase().includes(searchLower);

        return eCommerceMatch || productsMatch;
    });
    if (filteredDataE.length === 0) {
        filteredDataE.push({
            'E-Commerce': '',
            Products: '',
            Total_Reviews: '',
            Total_Ratings: '',
        });
    }
    const getQueryParams = (search) => {
        console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("seller_id")) {
            setSeller_id(params.get("seller_id"));
        }
        if (params.get("card")) {
            // setCard(Number(params.get("card")));
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    const GetP = async () => {
        try {
            const response = await http.post('master_service/seller_info/product', JSON.stringify({
                seller_id: seller_id,
            }))
            console.log("response tbl:", response.data);
            setResP(response?.data?.products)
            // setTblDataP(responseP?.data.bundle?.brands[0]?.ecommerce || [])
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    const GetE = async () => {
        try {
            const response = await http.post('master_service/seller_info/ecommerce', JSON.stringify({
                seller_id: seller_id,
            }))
            console.log("response tbl:", response.data);
            setResE(response?.data?.site_details)
            // setTblDataP(responseP?.data.bundle?.brands[0]?.ecommerce || [])
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => { if (seller_id !== null) { GetP(); GetE() } }, [seller_id])
    const props = { Btns: [{ name: 'Product List', }, { name: 'E-commerce List', },], border: true, setTab: setTab, tab: tab, title: tab === 0 ? 'Product List Table' : 'E-Commerce Table', searchTerm: searchTerm, setSearchTerm: setSearchTerm, filteredData: tab === 0 ? filteredDataP : filteredDataE, rowsPerPage, page, setPage }
    console.log('startIndex, endIndex, paginatedData, filteredData', filteredDataP, filteredDataE);
    // console.log('startIndex, endIndex, paginatedData, filteredData', startIndex, endIndex, paginatedData, filteredData);
    return (
        <CorporateNormalUserTable prop={props} />
    );
};

// Price Range and Seller Summery 
export const PRSS = ({ prop }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography>Price Range</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                <SimpleCard2
                    x={{
                        title: prop?.price_range?.Minimum_Price || 0,
                        value: 'Minimum Price',
                    }}
                    wd={`${(100 / 2) - 0.5}%`}
                />
                <SimpleCard2
                    x={{
                        title: prop?.price_range?.Maximum_Price || 0,
                        value: 'Maximum Price',
                    }}
                    wd={`${(100 / 2) - 0.5}%`}
                />
            </Box>
            <Typography>Seller Summary</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                <SimpleCard2
                    x={{
                        title: prop?.seller_summary['E-Commerce Platforms'] || 0,
                        value: 'E-Commerce Platforms',
                    }}
                    wd={`${(100 / 2) - 0.5}%`}
                />
                <SimpleCard2
                    x={{
                        title: prop?.seller_summary['Total No.of.Products'] || 0,
                        value: 'Total No. of Products',
                    }}
                    wd={`${(100 / 2) - 0.5}%`}
                />
            </Box>
        </Box>
    )
}
function calculatePositiveRating(prop) {
    const sellerRatings = prop?.['Overall Rating']?.Seller_Ratings;
    if (sellerRatings == null || sellerRatings <= 0) {
        return 100; // Return 0 for null or non-positive ratings
    }
    return Math.round(100 / sellerRatings);
}

// Function to calculate negative rating
function calculateNegativeRating(prop) {
    const sellerRatings = prop?.['Overall Rating']?.Seller_Ratings;
    if (sellerRatings == null || sellerRatings <= 0) {
        return 0; // Return 100 for null or non-positive ratings
    }
    return Math.abs(Math.round(100 - (100 / sellerRatings)));
}

export const OverallRating = ({ prop }) => {
    console.log('rnd', Math.abs(Math.round(prop && (100 / prop['Overall Rating']?.Seller_Ratings === null ? 0 : prop['Overall Rating']?.Seller_Ratings) - 100)))
    const ser = [
        {
            data: [
                { id: 0, value: calculatePositiveRating(prop), color: '#008000', label: 'Positive' },
                { id: 1, value: calculateNegativeRating(prop), color: '#FF0000', label: 'Negative' },
            ],
            innerRadius: 61,
            outerRadius: 51,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -125,
            endAngle: 130,
            cx: 93,
            cy: 100,
        }
    ]
    return (
        <Box>
            <Typography sx={{ fontWeight: '600', fontSize: '16px', color: '#000', my: 1 }}>Overall Rating</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '100%', border: '1px solid #EAECF0', borderRadius: '8px', gap: 1 }}>
                <Box sx={{ minWidth: '200px', }}>
                    <Box sx={{ position: 'relative', width: '200px', height: '200px' }}>
                        {/* PieChart */}
                        <PieChart
                            slotProps={{ legend: { hidden: true } }}
                            height="200"
                            width="200"
                            series={ser}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                textAlign: 'center',
                            }}
                        >
                            <Box sx={{ height: '70px', width: '70px', borderRadius: '50%', background: '#F3E7FA', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{prop?.['Overall Rating']?.Seller_Ratings ? (Math.round(100 / (prop?.['Overall Rating']?.Seller_Ratings || 0))) : 0}%</Typography>
                                <Typography variant="h6" sx={{ fontSize: '10px', color: '#2E2E30' }}>Positive</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: 'calc(100% - 200px)' }}>
                    <Box sx={{ mb: 1 }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '12px', color: '#000', my: 1 }}>Seller Rating</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                            {/* <Typography sx={{ fontWeight: '700', fontSize: '12px', color: '#2B2B2B' }}>{Number(5.4951668.toFixed(1))}</Typography> */}
                            <Typography sx={{ fontWeight: '700', fontSize: '12px', color: '#2B2B2B' }}>{Number(prop?.['Overall Rating']?.Seller_Ratings ? prop?.['Overall Rating']?.Seller_Ratings?.toFixed(1) : 0)}</Typography>
                            <Rating name="rating" value={parseFloat(prop?.['Overall Rating']?.Seller_Ratings)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize="small" style={{ color: '#00000033' }} />} icon={<StarIcon fontSize="small" style={{ color: '#F4A403' }} />} />
                            <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#2B2B2B' }}>({prop?.['Overall Rating']?.Seller_Rating_count || 0} ratings)</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '12px', color: '#000', my: 1 }}>Seller Review Rating</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                            <Typography sx={{ fontWeight: '700', fontSize: '12px', color: '#2B2B2B' }}>{parseFloat(prop?.['Overall Rating']?.Seller_Review_Ratings)}</Typography>
                            <Rating name="rating" value={parseFloat(prop?.['Overall Rating']?.Seller_Review_Ratings)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize="small" style={{ color: '#00000033' }} />} icon={<StarIcon fontSize="small" style={{ color: '#F4A403' }} />} />
                            <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#2B2B2B' }}>({prop?.['Overall Rating']?.Seller_Reviews_count || 0} ratings)</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}


// export const Reviews = ({ prop }) => {
//     const [selectedReview, setSelectedReview] = useState('All Reviews');
//     console.log('prop values:-------->', prop);


//     return (
//         <Box sx={{}}>
//             <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
//                 <Typography sx={{ fontWeight: "600", fontSize: "16px", color: "#000" }}>Review</Typography>
//                 <Select
//                     value={selectedReview}
//                     onChange={(e) => setSelectedReview(e.target.value)}
//                     displayEmpty
//                     sx={{
//                         p: 0,
//                         height: '36px',
//                         width: "150px",
//                     }}
//                     inputProps={{ 'aria-label': 'Without label' }}
//                 >
//                     <MenuItem value='All Reviews'>All Reviews</MenuItem>
//                     <MenuItem value='Top Reviews'>Top Reviews</MenuItem>
//                     <MenuItem value='Worst Reviews'>Worst Reviews</MenuItem>
//                 </Select>
//             </Box>
//             <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', maxHeight: '23vh', overflowY: 'auto', gap: 1 }}>
//                 <ReviewCard
//                     title="Alexandria Mermath"
//                     date="25 Oct 2024"
//                     content="Lorem ipsum dolor sit amet consectetur. Ultricies dictum egestas aliquet bibendum ut sit dui. Tempor odio convallis pulvinar dis gravida interdum a id."
//                     rating="4.0"
//                 />
//                 <ReviewCard
//                     title="JAlexandria Mermath"
//                     date="25 Oct 2024"
//                     content="Lorem ipsum dolor sit amet consectetur. Ultricies dictum egestas aliquet bibendum ut sit dui. Tempor odio convallis pulvinar dis gravida interdum a id."
//                     rating="5.0"
//                 />
//             </Box>
//         </Box>
//     )
// }


export const Reviews = ({ prop }) => {
    const [selectedReview, setSelectedReview] = useState('All Reviews');

    console.log('prop values:', prop);

    const getFilteredReviews = () => {
        if (!Array.isArray(prop?.OverallRating?.Seller_Reviews)) return [];

        const sortedReviews = [...prop.OverallRating.Seller_Reviews].sort((a, b) =>
            new Date(b.date) - new Date(a.date)
        );

        if (selectedReview === 'Latest Reviews') {
            return sortedReviews;
        } else if (selectedReview === 'Old Reviews') {
            return sortedReviews.reverse();
        }
        return prop.OverallRating.Seller_Reviews;
    };

    const hasReviews = Array.isArray(prop?.OverallRating?.Seller_Reviews) && prop.OverallRating.Seller_Reviews.length > 0;

    return (
        <Box sx={{}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                <Typography sx={{ fontWeight: "600", fontSize: "16px", color: "#000" }}>Review</Typography>
                <Select
                    value={selectedReview}
                    onChange={(e) => setSelectedReview(e.target.value)}
                    displayEmpty
                    disabled={!hasReviews}
                    sx={{
                        p: 0,
                        height: '36px',
                        width: "150px",
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value='All Reviews'>All Reviews</MenuItem>
                    <MenuItem value='Latest Reviews'>Latest Reviews</MenuItem>
                    <MenuItem value='Old Reviews'>Old Reviews</MenuItem>
                </Select>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', maxHeight: '23vh', overflowY: 'auto', gap: 1 }}>
                {hasReviews ? (
                    getFilteredReviews().map((review, index) => (
                        <ReviewCard
                            key={index}
                            title={review.site_name}
                            date={review.date}
                            content={review.reviews}
                        />
                    ))
                ) : (
                    <Typography>No Reviews Available</Typography>
                )}
            </Box>
        </Box>
    )
}



