import { Box, Typography } from "@mui/material";
import Sunburst from "sunburst-chart";
import * as d3 from "d3";
import 'd3-transition';
import { useEffect, useRef, useState } from "react";
import { ReportColor, SunburstDatas } from "../../../../../../utils/data/CorporateNormalUserDatas/Data";
import { ComboTxtBtn } from "../../../../../../components/@extended/Button";
import { BtnLoad } from "../../../../../../utils/data/LandingPage/Data";
import http from '../../../../../../utils/http-common'
import { downloadCSV } from "../../../../../../components/Public/DownloadCSV";
import { useDispatch, useSelector } from "react-redux";
import { handleClick, setMessage, setSeverity } from "../../../../../../slice/SnackPop";
import { DownloadIcn } from "../../../../../../utils/icon/IndividualPages/Icon";

export const Sunbursts = () => {
    const [load, setLoad] = useState(false);
    const m = useSelector((s) => s?.reportFilter?.reportFilter)
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(400);

    const [indBundleId, setIndBundleId] = useState(null);
    const [d, setD] = useState(null);
    const [rawTbl, setRawTbl] = useState([]);
    const dispatch = useDispatch();

    // const [d, setD] = useState(SunburstDatas)
    // const [sd, setSd] = useState([])
    // const [sd, setSd] = useState([{ color: '#2BA1DB', dataKey: 'Makita', label: 'Makita', text: 'Makita' }, { color: '#8A18D0', dataKey: 'Ibell', label: 'Ibell', text: 'Ibell' }, { color: '#F68D2B', dataKey: 'Dewalt', label: 'Dewalt', text: 'Dewalt' },])
    // const d = [
    //     { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, },
    //     { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, },
    //     { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, },
    //     { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, },
    // ];
    // const sd = [{ color: '#2BA1DB', text: 'Makita' }, { color: '#8A18D0', text: 'Ibell' }, { color: '#F68D2B', text: 'Dewalt' },]

    const Fetch = async () => {
        try {
            setLoad(true);
            const response = await http.post(
                `/master_service/reports/e-commerce_brand_breakdown`,
                JSON.stringify({ ind_bundle_id: indBundleId, month: m?.month, year: Number(m?.year) })
            );
            // console.log('response sunburst', response?.data);
            const dataArray = {
                name: '',
                size: 1,
                color: 'white',
                children: [],
            };
            const rawAlterData = [];
            const data = response?.data?.brands?.sort((a, b) => a.brand_name.localeCompare(b.brand_name))?.map((e, i) => {
                const b = { name: e?.brand_name, size: 1, color: ReportColor[i], children: [] };
                e?.ecommerce?.sort((a, b) => a.site_name.localeCompare(b.site_name))?.map((k) => {
                    b?.children.push({ name: k.site_name, size: k.no_of_products, color: b?.color + '80', children: [] });
                    rawAlterData.push({ brand: e?.brand_name, site: k.site_name, product_count: k.no_of_products });
                });
                dataArray?.children.push(b);
            });
            setRawTbl(rawAlterData);
            setD(dataArray);
            setLoad(false);
        } catch (error) {
            console.error('Error fetching data:', error?.response?.data?.message);
            setLoad(false);
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    };

    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = chartContainerRef.current.offsetWidth - 3;
                setChartWidth(containerWidth); // Set the width to the container's width
            }
        };

        // Initial width calculation
        updateChartWidth();

        // Update width on window resize
        window.addEventListener('resize', updateChartWidth);
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, [d]);

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);

    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId, m]);
    const bndlId = useSelector((s) => s?.ids?.ids?.ind_bundle_id)
    useEffect(() => { if (bndlId !== null) { setIndBundleId(bndlId) } }, [bndlId])

    useEffect(() => {
        if (chartContainerRef.current && d) {
            // Clear existing chart (if any) to avoid overlapping
            chartContainerRef.current.innerHTML = "";

            // Create and render the Sunburst chart
            try {
                Sunburst()
                    ?.data(d)
                    ?.label("name")
                    ?.size("size")
                    ?.width(chartWidth)
                    ?.height(chartWidth)
                    // .transition()
                    // ?.transitionDuration(500) // Set a duration for the transition
                    ?.color((d, parent) => (d?.color ? d.color : parent ? '#fff' : null))
                    // ?.onTransitionEnd(() => { })
                    .tooltipContent((d, node) => `Size: <i>${node.value}</i>`)(
                        chartContainerRef.current
                    );
            } catch (error) {
                console.error("Error rendering Sunburst chart:", error);
            }
        }
    }, [chartWidth, d]);

    return (
        <Box sx={{ width: '30%', height: '100%' }}>
            <ComboTxtBtn prop={{
                name: 'E-Commerce Brand Breakdown', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => {
                    if (!load) {
                        if (rawTbl !== null) {
                            downloadCSV(rawTbl, `E-Commerce Brand Breakdown ${indBundleId}`);
                            dispatch(setMessage('E-Commerce Brand Breakdown CSV Data Downloaded Successfully'));
                            dispatch(setSeverity('success'));
                            dispatch(handleClick());
                        } else {
                            dispatch(setMessage('There is no data in the E-Commerce Brand Breakdown, so unable to download csv.'));
                            dispatch(setSeverity('error'));
                            dispatch(handleClick());
                        }
                    } else {
                        dispatch(setMessage('Loading...'));
                        dispatch(setSeverity('warning'));
                        dispatch(handleClick());
                    }
                }
            }} />
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', }}>
                <Box sx={{ border: '1px solid #EAECF0', borderRadius: '8px', p: 2 }}>
                    {d ?
                        <Box sx={{ width: '100%', height: '100%' }} ref={chartContainerRef}>
                            {/* <div id="sunburst" style={{ width: chartWidth, minWidth: chartWidth, maxWidth: chartWidth, height: chartWidth, maxHeight: chartWidth }}></div> */}
                        </Box>
                        :
                        <Box sx={{ height: '215px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #EAECF0', borderRadius: '8px', }}>
                            <Typography>{load ? 'Loading...' : 'Data Not Available'}</Typography>
                        </Box>
                    }
                </Box>
                <Box>
                    {load && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                            <Box sx={BtnLoad} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};
