

export const dateClnse = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'long' });
    const year = dateObj.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
}


export const dateClnse2 = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
}


export const dateClnse3 = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    console.log('raw dat', dateObj)
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
        return 'Invalid date'; // or some default value
    }

    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}



export const RailwayTimeToNormalTime = (time) => {
    // Split the time string into components
    const [hours, minutes, seconds] = time.split(':').map(Number);

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    const normalHours = hours % 12 || 12; // Convert 0 to 12 for midnight

    // Format the normal time string
    const NormalTime = `${String(normalHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${period}`;

    return NormalTime;
}




export const RawDate = (date) => {
    if (!date) return ''; // or some default value

    const dateObj = new Date(date);
    return dateObj;
}

export const timeAgo = (isoString) => {
    const date = new Date(isoString);
    if (isNaN(date)) {
        return 'Invalid date';
    }

    const now = new Date();
    const diffInMs = now - date;
    if (diffInMs < 0) { return 'It is a Future Date'; }

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (seconds > 0) {
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    } else {
        return 'Just now';
    }
};
export const formatDate = (dateString) => { if (!dateString) return null; const date = new Date(dateString); return date.toISOString().split('T')[0]; };