import { Box, Typography } from "@mui/material";
import { BulletPointLandingPage } from "../../../../../utils/icon/LandingPage/Icon";

const SecondPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 1,
        pb: 6,
        mb: 10,
        px: { xs: 2, md: 0 },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "32px", md: "40px" },
          fontFamily: "Inter Variable",
          textAlign: "center",
          userSelect: "none",
          py: "60px",
          fontWeight: "700",
        }}
      >
        Why
        <Typography
          component="span"
          sx={{
            background: "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            ml: "10px",
            fontSize: "inherit",
          }}
        >
          Brand Aura?
        </Typography>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          gap: { xs: 2, md: 0 },
          width: "100%",
          pb: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "30%" },
            mb: { xs: 4, md: 0 },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 4 }}>
            <BulletPointLandingPage />
            <Typography
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "600",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Ecommerce Analytics
            </Typography>
          </Box>

          <Typography
            sx={{
              color: "#fff",
              fontWeight: "500",
              fontSize: { xs: "14px", md: "17px" },
              mb: 2,
              display: "block",
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none"
            }}
          >
            Ecommerce Product Analysis helps identify top-performing products and areas for improvement, driving efficient product strategies. Through targeted Data Crawling Services, brands can gather valuable market data and competitor intelligence, boosting their competitive edge.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "30%" },
            mb: { xs: 4, md: 0 },
            // px: { xs: 0, md: 1 },
            mx: { xs: 0, md: 3 },
            borderTop: "1px solid #FFFFFF1A",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 4 }}>
            <BulletPointLandingPage />
            <Typography
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "600",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Strategic Insights
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#fff",
              fontWeight: "500",
              fontSize: { xs: "14px", md: "17px" },
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none"
            }}
          >
            Analytics for Ecommerce Websites offers a clear understanding of site traffic, user interactions, and conversion rates, enhancing the user experience and maximizing ROI. Meanwhile, Price Monitoring on Ecommerce ensures your products are priced competitively, aligning with market demands and boosting profitability.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "30%" },
            borderTop: "1px solid #FFFFFF1A",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 4 }}>
            <BulletPointLandingPage />
            <Typography
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "600",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Market Navigation
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#fff",
              fontWeight: "500",
              fontSize: { xs: "14px", md: "17px" },
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none"
            }}
          >
            Understanding Brand Sentiment on Ecommerce allows businesses to track online perception, helping to refine marketing strategies. Effective Competitor Analysis provides a snapshot of market dynamics, helping Ecommerce Sellers stay ahead.
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "32px", md: "40px" },
          fontWeight: "700",
          textAlign: "center",
          userSelect: "none",
          py: "10px",
        }}
      >
        Your Brand. Your Data.
      </Typography>
      <Typography
        variant="h2"
        sx={{
          background: "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          ml: "10px",
          fontWeight: "700",
          fontSize: { xs: "32px", md: "40px" },
          textAlign: "center",
          userSelect: "none",
          mb: 0,
        }}
      >
        Always in Sync.
      </Typography>
    </Box>
  );
};
export default SecondPage;
