import { createSlice } from '@reduxjs/toolkit';

export const creditSlice = createSlice({
    name: 'credit',
    initialState: {
        credit: { available_credit: 0, used_credit: 0 },
    },
    reducers: {
        updateCredit: (state, action) => {
            // console.log('Redux updateBundle action:', state, action);
            state.credit = { available_credit: action.payload.available_credit, used_credit: action.payload.used_credit };
        },
        clearBundle: (state) => {
            state.bundle = { available_credit: 0, used_credit: 0 };
        },
    },
});

// Export the actions
export const { updateCredit, clearBundle } = creditSlice.actions;

// Export the reducer
export default creditSlice.reducer;
