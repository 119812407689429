import { Box, Button, FormLabel, TextField, Typography } from '@mui/material';
import DateRangePicker from '../../../../../../components/Date-Picker/DatePicker';

export const Frequency = ({ prop }) => {
  return (
    <Box
      sx={{
        minHeight: '420px',
        width: '100%',
        border: '0.8px solid #797F8F80',
        borderRadius: '4px',
        p: 3,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '100%', md: '35%' },
          // width: "350px",
          my: '5px',
          mb: 5,
        }}>
        <FormLabel
          sx={{ fontSize: '12px', fontWeight: '600', color: '#000000' }}>
          Bundle Name
        </FormLabel>
        <TextField
          placeholder='Enter Bundle Name'
          type='text'
          value={prop.name}
          onChange={(e) => prop.setName(e.target.value)}
          required
          sx={{ color: '#AFAFAF', fontSize: '10px', fontWeight: '600' }}
          size='small'
          InputProps={{
            sx: {
              height: '40px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#47474799',
              },
            },
          }}
        />
      </Box>
      <Box sx={{ width: '100%', my: '12px' }}>
        {/* <Box sx={{ width: "750px", my: "12px" }}> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
          }}>
          {/* <Box>
                <Controller
                  name={"start_date"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <DateField
                      {...field}
                      id={"start_date"}
                      name={"start_date"}
                      label={"Start Date"}
                      error={!!errors["start_date"]}
                      value={dateRange[0]}
                      helperText={errors["start_date"] ? "* Required" : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleDateChange(e.target.value, 0);
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ ml: 2 }}>
                <Controller
                  name={"end_date"}
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <DateField
                      {...field}
                      id={"end_date"}
                      name={"end_date"}
                      label={"End Date"}
                      value={dateRange[1]}
                      error={!!errors["end_date"]}
                      helperText={errors["end_date"] ? "* Required" : ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleDateChange(e.target.value, 1);
                      }}
                    />
                  )}
                />
              </Box> */}
          <Box>
            <DateRangePicker
              dateRange={prop.dateRange}
              setDateRange={(newDateRange) => {
                prop.setDateRange(newDateRange);
                prop.setCal(false);
                prop.setEsti({
                  rows: 0,
                  columns: 0,
                  estimated_credit: 0,
                });
              }}
              // allowFutureDates={true}
              highlightColor='#8A18D0'
            />
          </Box>
          <Button
            sx={{
              background: '#8A18D0',
              color: '#fff',
              px: 4,
              mx: 1,
              marginTop: '25px', // Adjust this value as needed
              marginLeft: '20px',
              height: 'min-content',
              textTransform: 'capitalize',
              '&:hover': { background: '#B37FFC' },
            }}
            onClick={() => prop.EstimatedCredits()}>
            Calculate
          </Button>
        </Box>
        {/* <CustomDatePicker
              dateRange={dateRange}
              handleDateChange={handleDateChange}
            /> */}
        {/* <DateRange
              editableDateInputs={true}
              onChange={item => setD([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
            /> */}
      </Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #EAECF0',
        }}>
        <Box
          sx={{
            background: '#F3E7FA',
            p: 2,
            borderBottom: '1px solid #EAECF0',
            borderRadius: '8px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}>
          <Typography
            sx={{ fontWeight: '600', fontSize: '14px', color: '#000000' }}>
            Estimated Credits
          </Typography>
        </Box>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            Total Number of Line Items Fetched
          </Typography>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {prop.Esti.rows ? prop.Esti.rows : 0}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottom: '1.13px solid #E7E9EB',
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            Total Number of Data Points Fetched
          </Typography>
          {/* <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {prop.Esti.rows * prop.Esti.columns ? prop.Esti.rows * prop.Esti.columns : 0}
          </Typography> */}
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {prop.Esti.columns ? prop.Esti.columns : 0}
          </Typography>
        </Box>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{ fontWeight: '600', fontSize: '12px', color: '#2B2B2B' }}>
            Credits needed to run the bundle
          </Typography>
          <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {prop.Esti.estimated_credit ? prop.Esti.estimated_credit : 0}
          </Typography>
          {/* <Typography
            sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B' }}>
            {prop.Esti.rows * prop.Esti.columns ? prop.Esti.rows * prop.Esti.columns : 0}
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};
