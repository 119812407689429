import axios from 'axios';
import { Tk2 } from '../components/@extended/ReturnToken';
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

const localUrl = 'http://localhost:8000';
const localUrl_2 = 'http://localhost:3232';
const localUrl_3 = 'http://localhost:5000';
const baseUrl = 'https://brandauragatewayservice-bqf5hdduakhghkf0.southindia-01.azurewebsites.net';
const liveBaseUrl = 'https://brandaura-live-gateway-service-chaydag7ftfsgnhd.southindia-01.azurewebsites.net';
const stagingBaseUrl = 'https://brandaura-dev-gateway-service-acaxgsandtchfwgq.southindia-01.azurewebsites.net';

// const token = useSelector((state) => state.token.token);

const axiosInstance = axios.create({
  // baseURL: localUrl,
  baseURL: liveBaseUrl,
  // baseURL: stagingBaseUrl,
  headers: {
    'Content-type': 'application/json',
    // Authorization: token,
    Authorization: Tk2(),
    // Authorization: localStorage.getItem("token"),
  },
});

// const navigate = useNavigate();
// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
  }
);

export const setToken = (token) => {
  axiosInstance.defaults.headers.Authorization = token;
};

export default axiosInstance;

export const regex = {
  onlyNumbers: /^[0-9]+$/,
  onlyAlphabets: /^[A-Za-z]+$/,
  alphanumeric: /^[A-Za-z0-9]+$/,
  validEmail: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  phoneNumber: /^\d{10}$|^\d{3}[-\s]?\d{3}[-\s]?\d{4}$/,
  date: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
  sixDigitPincode: /^\d{6}$/,
  roleId: /^\d{1,6}$/,
  indianGST: /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1})$/,
  gstNumber: /^[0-9]{11}$/,
  time: /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
};
