import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { OfficeSvg } from "../../../../../utils/icon/IndividualPages/Icon";

export const CreateBundle = () => {
  return (
    <Box sx={{ p: 3, height: "100%", width: "100%" }}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          border: "1px solid #BCBFC7",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { md: "70%", lg: "35%" },
            px: { xs: 1, md: 0 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <OfficeSvg />
          </Box>
          <Typography
            sx={{ fontWeight: "600", fontSize: "20px", color: "#000", my: 1 }}
          >
            Create Bundle
          </Typography>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              textWrap: "wrap",
              textAlign: "justify",
              color: "#000",
            }}
          >
            Do you want to create your own bundle?
          </Typography>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              textWrap: "wrap",
              textAlign: "justify",
              my: { xs: 2, md: 0 },
              color: "#000",
            }}
          >
            If not, our team will create you a custom bundle.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              my: 3,
              gap: 1,
            }}
          >
            <Link
              to={"/admin/custom-bundle"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                variant="outlined"
                sx={{
                  height: "44px",
                  width: "180px",
                  borderRadius: "4px",
                  color: "#8A18D0",
                  borderColor: "#8A18D0",
                  textTransform: "capitalize",
                }}
              >
                Custom Bundle
              </Button>
            </Link>
            <Link
              to={"/admin/data-selection"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                sx={{
                  height: "44px",
                  width: "180px",
                  borderRadius: "4px",
                  background: "#8A18D0",
                  color: "#fff",
                  textTransform: "capitalize",
                  "&:hover": { background: "#B37FFC" },
                }}
              >
                Data Selection
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
