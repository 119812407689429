import { Box, Button, Typography, Grid, Snackbar, Alert } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import http from '../../../../../utils/http-common';
import { renderIcon } from '../../../../../utils/icon/LandingPage/Icon';
import { CP_Icon } from '../../../../../utils/icon/AdminPage/Icon';
import { BtnLoad } from '../../../../../utils/data/LandingPage/Data';

const OurPlans = ({ Plans, id = null, need = true }) => {
  const seq = localStorage.getItem('seq');
  const [currPlan, setCurrPlan] = useState(
    window.location.pathname === '/pricing' ? -1 : Number(seq ? seq : -1)
  );
  const [clickedPlan, setClickedPlan] = useState(
    window.location.pathname === '/pricing' ? 2 : Number(seq ? seq : 2)
  );
  console.log('curr', currPlan, seq);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(null);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleCardClick = (index) => {
    setClickedPlan(index);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (route) => {
    navigate(route);
    handleMenuClose();
  };
  const navi = useNavigate();
  const userId = (id !== null && id !== undefined) ? id : localStorage.getItem('user_id');
  const SelectPlan = async ({ title }) => {
    if (title === 'Custom')
      navi(
        window.location.pathname === '/pricing'
          ? '/Contact'
          : '/admin/custom-plan'
      );
    if (!userId && !id) {
      setMessage(
        'There is some issue with Credentials, Please Try Again'
      );
      setSeverity('error');
      handleClick();
      setLoad(null)
      return;
    }
    console.log('userId', userId, id);
    try {
      const response = await http.post(
        '/master_service/select_plan',
        JSON.stringify({ user_id: userId, plan_name: title })
      );
      setLoad(null)
      console.log(response.data);
      localStorage.setItem('admin_id', response.data.admin_id);

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();

      setTimeout(() => {
        navi(window.location.pathname === '/pricing' ? '/login' : '/admin');
      }, 4000);
    } catch (error) {
      setLoad(null)
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  return (
    <Box
      sx={{
        background: '#fff',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pt: 8,
        pb: 0,
      }}>
      <Box sx={{ width: { xs: '100%', md: need ? '80%' : '100%' } }}>
        <Box sx={{ textAlign: 'center', mb: 5 }}>
          <Typography
            sx={{
              color: '#000',
              fontSize: { xs: '30px', md: '40px' },
              fontWeight: '600',
              pr: '10px',
              display: 'inline-block',
            }}>
            Our
          </Typography>
          <Typography
            sx={{
              color: '#8A18D0',
              fontSize: { xs: '30px', md: '40px' },
              fontWeight: '600',
              display: 'inline-block',
            }}>
            Plans
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'space-between' }, // Ensure center alignment on smaller screens
          }}>
          {Plans.map((plan, index) => (
            <Grid
              item
              xs={11}
              sm={6}
              md={3}
              key={index}>
              <Box
                onClick={() => handleCardClick(index)}
                sx={{
                  border: '1px solid #E4E4E4',
                  color: clickedPlan === index ? '#FFFFFF' : '#000000',
                  background: clickedPlan === index ? '#000000' : '',
                  p: 2,
                  borderRadius: '8px',
                  minWidth: '200px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'hidden',
                  height: 'auto',
                  boxSizing: 'border-box',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  transform: clickedPlan === index ? 'scale(1.05)' : 'scale(1)',
                  boxShadow:
                    clickedPlan === index
                      ? '0px 10px 20px rgba(0,0,0,0.2)'
                      : 'none',
                  cursor: 'pointer',
                  perspective: '1000px',
                  transformStyle: 'preserve-3d',
                }}>
                <Box>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '24px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        mb: 2,
                      }}>
                      {plan.title}
                    </Typography>
                    {currPlan === index && (
                      // <Box>
                      //   <Typography>Current Plan</Typography>
                      // </Box>
                      <Box sx={{ mr: -2.2 }}>
                        <CP_Icon />
                      </Box>
                    )}
                  </Box>

                  {plan.text && (
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '15px',
                        color: '#727272',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        mb: 3,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}>
                      {plan.text}
                    </Typography>
                  )}

                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
                    {plan.price && (
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '32px',
                          pr: '5px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                        }}>
                        {plan.price}
                      </Typography>
                    )}
                    {plan.discount && (
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '12px',
                          background:
                            clickedPlan === index ? '#4B4B4B' : '#F2F2F2',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal',
                        }}>
                        {plan.discount}
                      </Typography>
                    )}
                  </Box>
                  {plan.subText && (
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '12px',
                        color: clickedPlan === index ? '#AFAFAF' : '',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        mb: 2,
                      }}>
                      {plan.subText}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '14px',
                      color: clickedPlan === index ? '#D8D8D8' : '#000',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                    }}>
                    {plan.mainSubText}
                  </Typography>
                  <Box>
                    {plan.subTextData.map((item, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          py: '10px',
                        }}>
                        {renderIcon(clickedPlan === index)}
                        <Typography
                          sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            pl: '7px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                          }}>
                          {item.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Button
                  sx={{
                    border:
                      clickedPlan !== index &&
                      index > currPlan &&
                      '1px solid #8A18D0',
                    textTransform: 'capitalize',
                    background:
                      index <= currPlan
                        ? '#474747'
                        : clickedPlan === index
                          ? '#8A18D0'
                          : 'transparent',
                    '&:hover': {
                      background:
                        index <= currPlan
                          ? '#47474799'
                          : clickedPlan === index && '#8a18d099',
                    },
                    // color: '#fff',
                    color: index <= currPlan
                      || clickedPlan === index ? '#fff' : '#8A18D0',
                    width: '100%',
                    mt: 2,
                    height: '40px',
                    '&.Mui-disabled': {
                      color: '#ffffff',
                      border: 'none'
                    },

                  }}
                  onClick={() => {
                    if (index === currPlan) {
                      console.log('Button is disabled');
                      setMessage('This is Currently Activated Plan, This cant be purchased again.');
                      setSeverity('warning');
                      handleClick();
                    } else if (index <= currPlan) {
                      console.log('Button is disabled');
                      setMessage('Plan downgrade not possible.');
                      setSeverity('warning');
                      handleClick();
                    } else {
                      setLoad(index); SelectPlan({ title: plan.title })
                    }
                  }}
                  component={RouterLink}
                // disabled={index <= currPlan}
                // to={plan.btn_Link && plan.btn_Link}
                >
                  {plan.btn}
                  <Box>
                    {load === index && (
                      <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                        <Box sx={BtnLoad} />
                      </Box>
                    )}
                  </Box>
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            width: { xs: "calc(100% - 16px)", sm: "100%" },
            maxWidth: "100%",
            height: { xs: "100px", sm: "60px" },
            my: "32px",
            mx: { xs: "8px", sm: "0" },
            backgroundColor: "#F3E8FA",
            borderRadius: "8px",
            px: 2,
            textAlign: "center",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontWeight: "200",
              mb: { xs: 1, sm: 0 },
            }}
          >
            Are you an individual user? We have custom plans for you as well.
          </Typography>
          <RouterLink to='/reg' style={{ textDecoration: "none" }}>
            <Typography
              onClick={handleMenuClose}
              sx={{
                color: "#8A18D0",
                display: "block",
                textAlign: "center",
                px: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Explore
            </Typography>
          </RouterLink>
        </Box> */}
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ position: 'absolute', mt: '38px' }}>
          <Alert
            onClose={handleClose}
            severity={severity}
            variant='filled'
            sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default OurPlans;
