import PropTypes from 'prop-types';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Avatar, Rating, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { Link } from 'react-router-dom';
import { useContext } from 'react';
import CountryContext from '../../context/CountryContext';
import { brandIconMap, sentimentIconMap } from '../../icons';


const Help = ({ data, item }) => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, separateProduct, setSeparateProduct, DashboardTableData } = useContext(CountryContext)
    // const icon = data.Brand_Name === 'Bosch' ? 'BoschNav'
    return (
        <Accordion sx={{ my: 1, borderRadius: '14px', boxShadow: 3, width: '100%', px: 0, "&:hover": { boxShadow: 10 } }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                    <Link to={'/dashboard/brands'} style={{ display: 'flex', alignItems: 'center', width: '100%' }} onClick={() => { setBrand(item); setSeparateProduct({ display: false, subBrand: 0 }) }}>
                        <Avatar src={brandIconMap[data.brand + 'Nav']} alt={data.Brand_Name} sx={{ mr: 2, width: '40px', height: 'auto' }} />
                        <Box>
                            <Typography variant="h6">{data.brand}</Typography>
                            <Typography variant="body2" color="textSecondary">{data.Brand_Url}</Typography>
                        </Box>
                    </Link>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ width: '100%', px: 0 }}>
                <Table aria-label="brand table">
                    <TableHead sx={{ background: '#1DA1F21A' }}>
                        <TableRow>
                            <TableCell>E-Commerce</TableCell>
                            <TableCell>Minimum Price</TableCell>
                            <TableCell>Maximum Price</TableCell>
                            <TableCell>Sentiment</TableCell>
                            <TableCell align="center">Average Rating</TableCell>
                            <TableCell align="center">No of Sellers</TableCell>
                            <TableCell align="center">No of Products</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.subBrandDetails.map((row, i) => (
                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={brandIconMap[row.Brand]} alt={row.Brand} sx={{ mr: 1, width: 'auto', height: '20px', borderRadius: '5px' }} />
                                        {row.Brand}
                                    </Box>
                                </TableCell>
                                <TableCell>{row.min_p}</TableCell>
                                <TableCell>{row.max_p}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={sentimentIconMap[row.sentiment]} alt={row.sentiment} sx={{ mr: 1, width: 'auto', height: '20px', borderRadius: '5px' }} />
                                        {row.sentiment}
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Rating
                                            name="rating"
                                            value={parseFloat(row.ratings)}
                                            precision={0.5}
                                            readOnly
                                            emptyIcon={<StarBorderIcon fontSize="small" style={{ color: 'gray' }} />}
                                            icon={<StarIcon fontSize="small" style={{ color: '#fdd835' }} />}
                                        />
                                        &nbsp;{row.ratings}
                                    </Box>
                                </TableCell>
                                <TableCell align="center">{row.Sellers}</TableCell>
                                <TableCell align="center">{row.Products}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </AccordionDetails>
        </Accordion>
    );
};


export const Brand_Card = ({ data }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {data.map((e, i) => (
                <Help key={i} data={e} item={i} />
            ))}
        </Box>
    );
}

Help.propTypes = {
    data: PropTypes.shape({
        icon: PropTypes.string,
        Brand_Name: PropTypes.string.isRequired,
        Brand_Url: PropTypes.string.isRequired,
        table: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.string,
            name: PropTypes.string.isRequired,
            min_p: PropTypes.string.isRequired,
            max_p: PropTypes.string.isRequired,
            sentiment: PropTypes.string.isRequired,
            ratings: PropTypes.string.isRequired,
            sellers: PropTypes.string.isRequired,
            products: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
};

Brand_Card.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        Brand_Name: PropTypes.string.isRequired,
        Brand_Url: PropTypes.string.isRequired,
        table: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.string,
            name: PropTypes.string.isRequired,
            min_p: PropTypes.string.isRequired,
            max_p: PropTypes.string.isRequired,
            sentiment: PropTypes.string.isRequired,
            ratings: PropTypes.string.isRequired,
            sellers: PropTypes.string.isRequired,
            products: PropTypes.string.isRequired,
        })).isRequired,
    })).isRequired,
};

