import { createSlice } from '@reduxjs/toolkit';

export const idsSlice = createSlice({
    name: 'ids',
    initialState: {
        ids: { ind_bundle_id: null }, // Initial state as an object
    },
    reducers: {
        setBundleId: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.ids.ind_bundle_id = action.payload; // Store the new bundle data
        },
        updateIds: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.ids = action.payload; // Store the new bundle data
        },
        clearBundle: (state) => {
            state.ids = {}; // Clear the bundle data
        },
    },
});

// Export the actions
export const { setBundleId, updateBundle, clearBundle } = idsSlice.actions;

// Export the reducer
export default idsSlice.reducer;
