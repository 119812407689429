import {
  Box,
  Button,
  FormLabel,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import TopNav from "../home/private-components/nav/TopNav";
import Content from "../home/private-components/Content";
import { Element } from "react-scroll";
import ContactImg from "../../../assets/Contact-form.png";
// import Textarea from '@mui/joy/Textarea'
import { useState } from "react";
import http from "../../../utils/http-common";
// import { Footer } from "../home";
import { Textarea } from "../../../components/@extended/Textarea";
import Footer from "../home/private-components/screens/Footer";
import PhoneInput from "react-phone-input-2";
import { Analytics } from "../../../Analytics";
import { BtnLoad } from "../../../utils/data/LandingPage/Data";
import { Helmet } from "react-helmet";
import parsePhoneNumberFromString, { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
export const Contact = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#000000",
        color: "#fff",
        minHeight: "100vh",
        fontFamily: "Inter",
      }}
    >
      <Box
        sx={{
          width: "100%",
          pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "85%" },
            position: "sticky",
            top: "0",
            zIndex: "999",
          }}
        >
          <Box
            sx={{ display: { xs: "flex" }, justifyContent: { xs: "center" } }}
          >
            <Box sx={{ width: { xs: "80%", md: "100%" } }}>
              <TopNav />
              {/* <Helmet>
                <title>Contact Us to Grow Your Digital Business | Brand Aura</title>
              </Helmet> */}
              <Analytics />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "100%" } }}>
          <Element name="main">
            <FirstPage />
          </Element>
          {/* <Element name="why-brand-aura">
                        <SecondPage />
                    </Element>
                    <Element name="features">
                        <section className="self-stretch flex flex-row items-start justify-start py-0 px-20 box-border max-w-full mq750:pl-10 mq750:pr-10 mq750:box-border">
                            <div className="flex-1 flex flex-col items-start justify-start rounded-[10px] gap-[96px] bg-[#000] max-w-full mq750:gap-[48px] mq450:gap-[24px]">
                                <ThirdPage />
                            </div>
                        </section>
                    </Element> */}
        </Box>
        {/* <Box sx={{ width: '100%', background: '#fff', py: 6 }}>
                    <Element name="atLast">
                        <section className="self-stretch flex flex-row items-start justify-start py-0 px-20 box-border max-w-full mq750:pl-10 mq750:pr-10 mq750:box-border">
                            <div className="flex-1 flex flex-col items-start justify-start rounded-[10px] gap-[96px] bg-[#000] max-w-full mq750:gap-[48px] mq450:gap-[24px]">
                                <Content />
                            </div>
                        </section>
                    </Element>
                </Box> */}
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          <Element name="about-us">
            <Footer />
          </Element>
        </Box>
      </Box>
    </Box>
  );
};

const FirstPage = () => {
  const [phnChk, setPhnChk] = useState(-1)
  const [phnValid, setPhnValid] = useState(false)
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Name validation
    if (!name) {
      tempErrors.name = "Name is required.";
      isValid = false;
    }

    // Phone number validation
    if (!ph||phnValid || Number(phnChk) === 0) {
      tempErrors.ph = "A valid phone number is required.";
      isValid = false;
    }

    // Email validation
    if (!email) {
      tempErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email address is invalid.";
      isValid = false;
    }
    // Description validation
    if (!description) {
      tempErrors.description = "Description is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      contact();
    }
    // } else {
    //   setMessage("Please correct the highlighted errors.");
    //   setSeverity("error");
    //   setOpen(true);
    // }
  };

  const [name, setName] = useState("");
  const [load, setLoad] = useState("");
  const [email, setEmail] = useState("");
  const [ph, setPh] = useState("");
  const [description, setdescription] = useState("");
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [country, setCountry] = useState({ name: "", code: "us" });
  const contact = async () => {
    setLoad(true)
    try {
      const response = await http.post(
        "master_service/contact-us",
        JSON.stringify({
          user_name: name,
          user_email: email,
          user_phone_number: ph,
          user_description: description,
        })
      );
      setLoad(false)
      // console.log("res", response);
      if (response.status === 201) {
        setName(""),
          setEmail(""),
          setPh(""),
          setdescription(""),
          setMessage("We have successfully received your request");
        setSeverity("success");
        handleClick();
      }
    } catch (error) {
      setLoad(false)
      console.error("Error creating contact us entry", error);
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Please provide allrequired fields."
      // );
      setSeverity("error");
      handleClick();
    }
  };
  return (
    <Box
      sx={{
        background: "#fff",
        color: "#000",
        height: "100%",
        mt: "-75px",
        pt: "100px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ display: "flex" }} >
          <Typography
            component="h1"
            sx={{ display: "flex", fontWeight: "600", fontSize: "48px" }}
          >
            <span style={{ color: "#000" }}>Contact</span>
            <span style={{ paddingLeft: "5px", color: "#8A18D0" }}>Us</span>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          mt: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            minHeight: "600px",
            width: { xs: "95%", md: "65%" },
            border: "1px solid #E4E4E4",
            mb: "75px",
            borderRadius: "12px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: { xs: "", md: "50%" },
              height: "auto",
            }}
          >
            <img
              alt=""
              style={{
                width: "100%",
                height: "100%",
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
              className="w-[auto] h-[100%]"
              src={ContactImg}
            />
          </Box>
          <Box
            sx={{
              py: 4,
              display: "flex",
              flexDirection: "column",
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
              overflow: "hidden",
              px: 3,
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Typography sx={{ fontWeight: "600", fontSize: "28px" }}>
              Let's level up your brand,
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "28px",
                color: "#8A18D0",
                mb: 2,
              }}
            >
              Together
            </Typography>

            <Box
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", mb: "20px" }}
              >
                <FormLabel>Name*</FormLabel>
                <TextField
                  placeholder="Enter your name"
                  type="text"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  sx={{ color: "#AFAFAF", height: "43px" }}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", mb: "20px" }}
              >
                <FormLabel>Email*</FormLabel>
                <TextField
                  placeholder="you@company.com"
                  type="email"
                  value={email}
                  size="small"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  sx={{ color: "#AFAFAF", height: "43px" }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", mb: "20px" }}
              >
                <FormLabel>Phone Number*</FormLabel>
                <PhoneInput
                  country={country.code}
                  countryCodeEditable={false}
                  value={ph}
                  onChange={(value, data, event, formattedValue) => {
                    if (typeof event?.target?.value !== 'string') { return; }
                    const f = parsePhoneNumberFromString(String(event?.target?.value));
                    const ogphn = value.toString().substring(formattedValue.split(' ')[0].length - 1);
                    const requiredLength = getExampleNumber(f?.country, examples)?.nationalNumber?.length;
                    const currentLength = ogphn?.length;
                    // console.log('vl', value, data, event, formattedValue)
                    setPh(value)
                    setPhnChk(value.toString().substring(formattedValue.split(' ')[0].length - 1));
                    setPhnValid(requiredLength !== currentLength)
                  }}
                  containerStyle={{ width: "100%" }}
                  inputStyle={{ width: "100%", height: "40px", color: "#000", border: `1px solid ${!errors.ph ? '#D0D5DD' : '#d32f2f'}`, background: '#fff' }}
                  buttonStyle={{ border: "none", background: "transparent" }}
                  isValid={!errors.ph}
                  inputProps={{ required: true }}
                />
                {errors.ph && (
                  <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: '12px' }}>
                    {errors.ph}
                  </Typography>
                )}
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", mb: "20px" }}
              >
                <FormLabel>How can we help?<span style={{ position: "relative", top: "-3px", }}>*</span></FormLabel>
                <Textarea
                  Placeholder="Leave your thoughts here..."
                  Value={description}
                  onChange={(e) => setdescription(e.target.value)}
                  Style={{
                    width: "100%",
                    height: "150px",
                    borderRadius: "8px",
                    color: "#000000",
                    borderColor: errors.description ? "#d32f2f" : "#D0D5DD",
                    borderWidth: "0.7px",
                    borderStyle: "solid",
                  }}
                />
                {errors.description && (
                  <Typography sx={{ mt: 1, fontSize: '12px', color: '#d32f2f' }}>
                    {errors.description}
                  </Typography>
                )}
              </Box>

              <Button
                onClick={handleSubmit}
                disabled={load}
                sx={{
                  mt: "20px",
                  textAlign: "center",
                  background: "#8A18D0",
                  color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff',
                  width: "100%",
                  textTransform: "capitalize",
                  "&:hover": { background: "#8a18d099" },
                }}
              >
                Submit
                <Box>
                  {load && (
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                      <Box sx={BtnLoad} />
                    </Box>
                  )}
                </Box>
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <Alert
                  onClose={handleClose}
                  severity={severity}
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
