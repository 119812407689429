import {
  Box,
  Button,
  FormLabel,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { I_Icon } from "../../../../../../utils/icon/IndividualPages/Icon";
import { Model_1 } from "../../../../../../components/@extended/Model";
import http from "../../../../../../utils/http-common";
import { useDispatch } from "react-redux";
import { notSubscribed, subscribed } from "../../../../../../slice/subscribed";
import { useNavigate } from "react-router-dom";
import { setNav } from "../../../../../../slice/sideNav";
import { dateClnse } from "../../../../../../components/Public/DateClnse";

export const Plan = ({ setCp }) => {
  const [open, setOpen] = useState(false);
  const seq = localStorage.getItem("seq");
  const [plan, setPlan] = useState([]);
  const [endsIn, setEndsIn] = useState(0);
  const [nextP, setNextP] = useState(0);
  const [bar, setBar] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [modelData, setModelData] = useState({
    title: "Change Plan",
    emoji: "",
    txt: ["Are you sure, you want to change the plan?"],
    cl: "",
    btn_1: "Back",
    btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Change Plan",
    btn_2_cancel: true,
    // btn_2_Link: "/admin",
    btn_2_fn: () => setCp(true),
  });
  const Cancel = async () => {
    try {
      // const response = await http.post(`/master_service/billing_history/${3}`);
      const response = await http.delete(
        `/master_service/cancel_plan/${userId}`
      );
      console.log("cancel", response);
      // await Get();
      navigate("/admin");
      dispatch(setNav(-1));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const Get = async () => {
    try {
      const response = await http.get(
        `/master_service/subscription_details/${userId}`
      );
      console.log("resi", response.data);
      setPlan(response.data.planDetails);
      setNextP(response.data.paymentDetails[0].next_payment_date);
      localStorage.setItem("seq", response.data.planDetails.sequence - 1);
      dispatch(subscribed());
      const fd = new Date(response.data.paymentDetails[0].payment_date);
      const td = new Date();
      const nd = new Date(response.data.paymentDetails[0].next_payment_date);
      const diffInMs = nd.getTime() - td.getTime();
      // const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24));
      const diffInDays = Math.max(0, Math.floor((diffInMs / (1000 * 3600 * 24) + 1)));
      setEndsIn(diffInDays);
      const totalDuration = nd.getTime() - fd.getTime();
      const remainingDuration = nd.getTime() - td.getTime();
      const percentageRemaining = (remainingDuration / totalDuration) * 100;
      const perValue = Math.max(0, 100 - percentageRemaining);
      console.log("per", percentageRemaining, perValue);
      setBar(perValue);

      // await tD();
      // setMessage(response.data.message);
      // setSeverity('success');
      // handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.data.error === "Plan data not found") {
        console.log("yes not found");
        localStorage.removeItem("seq");
        dispatch(notSubscribed());
      }
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : 'Unable to Fetch the table data.'
      // );
      // setSeverity('error');
      // handleClick();
    }
  };
  useEffect(() => {
    Get();
  }, []);

  const cancel = {
    title: "Cancel Plan",
    emoji: "",
    txt: ["Are you sure, you want to cancel the plan?"],
    cl: "",
    btn_1: "Back",
    btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Cancel Plan",
    btn_2_cancel: true,
    // btn_2_Link: "/admin",
    btn_2_fn: Cancel,
  };
  const Change = {
    title: "Change Plan",
    emoji: "",
    txt: ["Are you sure, you want to change the plan?"],
    cl: "",
    btn_1: "Back",
    btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Change Plan",
    btn_2_cancel: true,
    // btn_2_Link: "/admin",
    btn_2_fn: () => setCp(true),
  };

  return (
    plan && (
      <Box
        sx={{
          border: "1px solid #797F8F80",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Model_1 open={open} setOpen={setOpen} data={modelData} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "20px",
              color: "#000",
            }}
          >
            {seq ? plan.title : "Purchase Plan"}
          </Typography>
          {/* <Button
            disableRipple
            sx={{ minWidth: 'max-content' }}>
            <I_Icon />
          </Button> */}
        </Box>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#000",
          }}
        >
          {seq && plan.price}
        </Typography>
        <Box>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "12px",
              py: 1,
              color: "#000",
            }}
          >
            {seq && `Ends in ${endsIn} Days`}
          </Typography>
          {seq && (
            <LinearProgress
              variant="determinate"
              sx={{
                height: "8px",
                borderRadius: "5px",
                backgroundColor: "#E4E4E480",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#8A18D0",
                },
              }}
              value={seq ? bar : 100}
            // value={seq ? 40 : 100}
            />
          )}
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              py: 1,
              color: "#000",
            }}
          >
            {seq && `Next Payment : ${dateClnse(nextP)}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            sx={{
              height: "32px",
              width: "49%",
              borderRadius: "4px",
              color: "#8A18D0",
              borderColor: "#8A18D0",
              textTransform: "capitalize",
              textWrap: "nowrap",
            }}
            disabled={!seq}
            onClick={() => {
              setModelData(cancel);
              setOpen(true);
            }}
          >
            Cancel Plan
          </Button>
          <Button
            sx={{
              height: "32px",
              width: "49%",
              borderRadius: "4px",
              background: "#8A18D0",
              color: "#fff",
              textTransform: "capitalize",
              textWrap: "nowrap",
              "&:hover": { background: "#B37FFC" },
            }}
            onClick={() => {
              setModelData(Change);
              setOpen(true);
            }}
          >
            Change Plan
          </Button>
        </Box>
      </Box>
    )
  );
};

export const Pay = ({ setMessage, setSeverity, handleClick }) => {
  const [price, setPrice] = useState("");
  const [credits, setCredits] = useState("");
  const userId = localStorage.getItem("user_id");
  const MakePayment = async () => {
    try {
      const response = await http.post(
        "/master_service/corporate_payment",
        JSON.stringify({
          user_id: userId,
          request_amt: price,
          applicable_credit: credits,
        })
      );

      console.log(response.data);
      // await Fetch_TableData();
      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Unable to Fetch the table data."
      // );
      setMessage('Enter the price in dollars');
      setSeverity("error");
      handleClick();
    }
  };
  return (
    <Box
      sx={{
        border: "1px solid #797F8F80",
        borderRadius: "8px",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          my: "15px",
        }}
      >
        <FormLabel
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            color: "#000000",
          }}
        >
          Price
          <span style={{ color: "#797F8F" }}>(in dollars)</span>
        </FormLabel>
        <TextField
          placeholder="Enter Price"
          type="number"
          value={price}
          onChange={(e) => {
            const newPrice = e.target.value;
            if (newPrice >= 0) {
              setPrice(newPrice);
              setCredits(newPrice * 10);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          required
          sx={{
            color: "#AFAFAF",
            fontWeight: "500",
            fontSize: "10px",
          }}
          InputProps={{
            sx: {
              height: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#797F8F",
              },
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "100%" },
          my: "15px",
        }}
      >
        <FormLabel
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            color: "#000000",
          }}
        >
          Estimated Credits
        </FormLabel>
        <TextField
          type="number"
          value={credits}
          onChange={(e) => {
            const newCredits = e.target.value;
            if (newCredits >= 0) {
              setCredits(newCredits);
              setPrice(newCredits / 10);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          required
          sx={{
            color: "#AFAFAF",
          }}
          InputProps={{
            sx: {
              height: "30px",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#797F8F",
              },
            },
          }}
        />
      </Box>
      <Button
        sx={{
          height: "15",
          background: "#8A18D0",
          color: "#fff",
          // px: 4,
          // ml: 2,
          mt: 2,
          width: "100%",
          fontSize: "12px",
          fontWeight: "600",
          textTransform: "capitalize",
          "&:hover": { background: "#B37FFC" },
        }}
        onClick={MakePayment}
      >
        Make Payment
      </Button>
    </Box>
  );
};
