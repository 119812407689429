import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { HeaderContents } from "./utils/data/Analytics/data";

export const Analytics = () => {
    const location = useLocation();
    const defDescrip = 'Price monitoring on ecommerce platforms and portfolio assessment services. Optimize website performance with advanced data analysis and actionable insights.'
    const [hel, setHel] = useState(null);
    ReactGA.initialize('G-WGPGZK2ZCT');
    // console.log('enteredddddd analy')
    const canonicalUrl = `${window.location.origin}${location.pathname}`;

    useEffect(() => {
        // console.log('cookies', 'working', hel, location, canonicalUrl, window.location, location.pathname === HeaderContents[2].path, HeaderContents?.filter((e) => e.path === location.pathname)?.[0]);
        setHel(HeaderContents?.filter((e) => e.path === location.pathname)?.[0]);
        ReactGA.send({ hitType: "pageview", page: location.pathname });
        // ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [location]);

    return (
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            {/* <link rel="canonical" href={window.location.href} /> */}
            {hel?.title ? <title>{hel?.title}</title> : <title>Brand Aura</title>}
            {hel?.description ? <meta name="description" content={hel?.description} /> : <meta name="description" content={defDescrip} />}
        </Helmet>
    );
}
