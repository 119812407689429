import { createSlice } from '@reduxjs/toolkit';

export const bundleSlice = createSlice({
    name: 'allbundle',
    initialState: {
        allbundle: null, // Initial state as an object
    },
    reducers: {
        updateBundles: (state, action) => {
            // console.log('Redux updateBundle action:', state, state.payload, action);
            state.allbundle = action.payload; // Store the new bundle data
        },
        clearBundles: (state) => {
            state.allbundle = []; // Clear the bundle data
        },
    },
});

// Export the actions
export const { updateBundles, clearBundles } = bundleSlice.actions;

// Export the reducer
export default bundleSlice.reducer;
