import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../slice/Test'
import bundle from '../slice/bundle'
import allbundle from '../slice/allBundles'
import credits from '../slice/credits'
import Token from '../slice/Token'
import sideNav from '../slice/sideNav'
import subscribed from '../slice/subscribed'
import ProfilePic from '../slice/ProfilePic'
import SnackPop from '../slice/SnackPop'
import reportFilterSlice from '../slice/reportFilter'
import ids from '../slice/ids'

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        token: Token,
        profile: ProfilePic,
        credit: credits,
        navs: sideNav,
        subscribe: subscribed,
        bundles: bundle,
        allbundles: allbundle,
        snackPop: SnackPop,
        reportFilter: reportFilterSlice,
        ids: ids,
    },

})
