import { Alert, Snackbar } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { handleClose } from "../../slice/SnackPop"



export const SnackNotification = ({ children }) => {
    const { open, severity, message } = useSelector((s) => s?.snackPop?.snackPop)
    // console.log('noti', open, severity, message)
    const dispatch = useDispatch()
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(handleClose());
    };
    return (
        <>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={severity} variant='filled' sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}