import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Configuration } from './Private/Configuration';
import { ConfigBar } from './Private/ConfigBar';
import { TopDesign } from './Private';

export const AdminDataSelection = () => {
  const initialData = {
    category: '',
    country: '',
    ecommerceSites: [],
    brand: [],
  };

  const [selectedData, setSelectedData] = useState(initialData);
  const [state, setState] = useState(0);
  const [minimize, setMinimize] = useState(false);

  const addElement = (data, arrayName) => {
    if (arrayName === 'category' || arrayName === 'country') {
      if (arrayName === 'category') {
        setSelectedData({ ...initialData, [arrayName]: data });
      } else if (arrayName === 'country') {
        setSelectedData({
          ...selectedData,
          ecommerceSites: [],
          brand: [],
          [arrayName]: data
        });
      }
    } else if (arrayName === 'ecommerceSites') {
      if (!selectedData[arrayName].includes(data)) {
        setSelectedData({
          ...selectedData,
          [arrayName]: [...selectedData[arrayName], data],
        });
      }
    } else if (arrayName === 'brand') {
      if (!selectedData[arrayName].includes(data)) {
        setSelectedData({
          ...selectedData,
          [arrayName]: [...selectedData[arrayName], data],
        });
      }
    }
  };

  const removeElement = (dataToRemove, arrayName) => {
    if (arrayName === 'category' || arrayName === 'country') {
      if (arrayName === 'category') {
        setSelectedData({ ...initialData, [arrayName]: '' });
      } else if (arrayName === 'country') {
        setSelectedData({
          ...selectedData,
          ecommerceSites: [],
          brand: [],
          [arrayName]: ''
        });
      }
    } else if (arrayName === 'ecommerceSites') {
      setSelectedData({
        ...selectedData,
        brand: [],
        [arrayName]: selectedData[arrayName].filter(
          (item) => item !== dataToRemove
        ),
      });
    } else if (arrayName === 'brand') {
      setSelectedData({
        ...selectedData,
        [arrayName]: selectedData[arrayName].filter(
          (item) => item !== dataToRemove
        ),
      });
    }
  };

  const clearElement = (arrayName) => {
    if (arrayName === 'category' || arrayName === 'country') {
      setSelectedData({ ...selectedData, [arrayName]: '' });
    } else {
      setSelectedData({ ...selectedData, [arrayName]: [] });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        width: '100%',
      }}>
      <Box sx={{ p: 3 }}>
        <TopDesign data={{ state: state }} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            p: 4,
            py: 1,
            width: { xs: '100%', md: '70%' },
            maxWidth: { xs: '100%', md: '72%' },
            height: '100%',
          }}>
          <Configuration
            selectedData={selectedData}
            minimize={minimize}
            setMinimize={setMinimize}
            setSelectedData={setSelectedData}
            state={state}
            setState={setState}
            addElement={addElement}
            removeElement={removeElement}
            clearElement={clearElement}
          />
        </Box>
        {minimize && (
          <Box
            sx={{
              width: { xs: '100%', md: '30%' },
              borderTopLeftRadius: { xs: '15px', md: 0 },
              borderTopRightRadius: { xs: '15px', md: 0 },
              zIndex: 4,
              top: '20.5%',
              display: { xs: 'flex', md: 'none' },
              border: '1px solid black',
              bottom: '100px',
              right: 0,
              position: { xs: 'fixed', md: 'static' },
              height: '100%',
            }}>
            <ConfigBar
              selectedData={selectedData}
              minimize={minimize}
              setMinimize={setMinimize}
            />
          </Box>
        )}
        <Box
          sx={{
            width: '30%',
            display: { xs: 'none', md: 'flex' },
            height: '100%',
          }}>
          <ConfigBar
            selectedData={selectedData}
            minimize={minimize}
            setMinimize={setMinimize}
          />
        </Box>
      </Box>
    </Box>
  );
};
