import { Box, Button, Grid, Typography } from "@mui/material";
import { scroller, ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { icons, navItems } from "../../../../../utils/data/LandingPage/Data";
import { Logo } from "../../../../../assets/logo";
import React from "react";

const links = ["https://www.instagram.com/_brand_aura_/", "https://www.linkedin.com/company/brand-aura-25/?viewAsMember=true", "https://x.com/brandaura_24"]

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        px: { xs: 2, md: 0 },
        py: 5,
        backgroundColor: "#000",
        color: "#fff",
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{ borderBottom: "1px solid #E2E8F0", justifyContent: 'space-between', flexWrap: 'nowrap', pb: 3 }}
      >
        {/* <Box sx={{}}> */}
        <Grid
          item
          // xs={12}
          // md={3}
          sx={{ textAlign: { xs: "center", md: "left" }, width: 'max-content' }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <RouterLink to={"/"}
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo('main', { smooth: true, duration: 1500 });
                }, 50);
              }} style={{}}>
              <Logo />
            </RouterLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              mt: 2,
            }}
          >
            {icons.map((e, i) => (
              <Link to={links[i]} key={i} style={{ textDecoration: "none" }}>
                <Box
                  key={i}
                  sx={{
                    background: "#F2F2F2",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    width: "30px",
                    borderRadius: "50%",
                    mx: 0.5,
                  }}
                >
                  {e}
                </Box>
              </Link>
            ))}
          </Box>
        </Grid>
        <Grid item
          // xs={12}
          // md={6}
          sx={{ width: 'max-content' }}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Grid
              item
              // xs={12}
              // md={12}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", md: "center" },
              }}
            >
              {navItems.map((item, i) => (
                <Link
                  key={i}
                  onClick={() => {
                    setTimeout(() => {
                      scroller.scrollTo(item.to, { smooth: true, duration: 1500 });
                    }, 50);
                  }}
                  to={item.route}
                  // to={item.route !== '/' ? item.route + '/#' + item.to : item.route + '#' + item.to}
                  // style={{ textDecoration: "none" }}
                  css={{
                    cursor: "pointer",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <Button
                    disableTouchRipple
                    sx={{
                      fontWeight: "500",
                      fontSize: "16px",
                      textTransform: "capitalize",
                      color: "#fff",
                      mx: 1,
                      mt: 2
                    }}
                  >
                    {item.label}
                  </Button>
                </Link>
              )
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          // xs={12}
          // md={3}
          sx={{ textAlign: { xs: "center", md: "right" }, width: 'max-content' }}
        >
          <Link
            onClick={() => {
              setTimeout(() => {
                scroller.scrollTo('main', { smooth: true, duration: 1500 });
              }, 50);
            }} to={"/contact"}>
            <Button
              sx={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#fff",
                backgroundColor: "#8A18D0",
                px: 2,
                textTransform: "capitalize",
                py: 1,
                height: '40px',
                textWrap: 'nowrap',
                "&:hover": { background: "#8a18d099" },
              }}
            >Request a Demo
            </Button>
          </Link>
        </Grid>
        {/* </Box> */}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
        <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
          © Copyright 2025, All Rights Reserved by Brand Aura
        </Typography>
        <Link to={"/terms-and-condition"} target="_blank" rel="noopener noreferrer">
          <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
            Terms & Conditions
          </Typography>
        </Link>
      </Box>

    </Box>
  );
};

const Footer2 = () => {
  // const location = useLocation();
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        px: { xs: 2, md: 5 },
        py: 5,
        backgroundColor: "#000",
        color: "#fff",
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ borderBottom: "1px solid #E2E8F0", pb: 3 }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            {"/" === window.location.pathname ? (
              <ScrollLink
                to={"main"}
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
                style={{
                  cursor: "pointer",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <Logo />
              </ScrollLink>
            ) : (
              <RouterLink to={"/"} style={{}}>
                <Logo />
              </RouterLink>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              mt: 2,
            }}
          >
            {icons.map((e, i) => (
              <Box
                key={i}
                sx={{
                  background: "#F2F2F2",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  width: "30px",
                  borderRadius: "50%",
                  mx: 0.5,
                }}
              >
                {e}
              </Box>
            ))}
          </Box>
          {/* <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, mt: 2 }}>
                        {icons.map((e, i) => (
                            <Button key={i} variant="square" disableTouchRipple sx={{ background: '#F2F2F2', height: '30px', textTransform: 'capitalize', width: '30px', borderRadius: '50%', mx: 0.5 }}>
                                {e}
                            </Button>
                        ))}
                    </Box> */}
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              {navItems.map((item, i) =>
                // (
                //   <React.Fragment key={i}>
                //     {window.location.pathname === item.route ? (
                //       <ScrollLink
                //         to={item.to}
                //         spy={true}
                //         smooth={true}
                //         offset={-70}
                //         duration={900}
                //         style={{
                //           cursor: 'pointer',
                //           color: 'inherit',
                //           textDecoration: 'none',
                //         }}
                //       >
                //         <Button
                //           disableTouchRipple
                //           sx={{
                //             fontWeight: '500',
                //             fontSize: '16px',
                //             textTransform: 'capitalize',
                //             color: '#fff',
                //             mx: 1,
                //           }}
                //         >
                //           {item.label}
                //         </Button>
                //       </ScrollLink>
                //     ) : (
                //       <RouterLink to={item.route} style={{ textDecoration: 'none' }}>
                //         <ScrollLink
                //           to={item.to}
                //           spy={true}
                //           smooth={true}
                //           offset={-70}
                //           duration={900}
                //           isDynamic={true}
                //           onClick={() => {
                //             window.scrollTo(0, 0); // Scroll to top before navigation
                //           }}
                //           style={{
                //             cursor: 'pointer',
                //             color: 'inherit',
                //             textDecoration: 'none',
                //           }}
                //         >
                //           <Button
                //             disableTouchRipple
                //             sx={{
                //               fontWeight: '500',
                //               fontSize: '16px',
                //               textTransform: 'capitalize',
                //               color: '#fff',
                //               mx: 1,
                //             }}
                //           >
                //             {item.label}
                //           </Button>
                //         </ScrollLink>
                //       </RouterLink>
                //     )}
                //   </React.Fragment>
                // )
                item.route === window.location.pathname ? (
                  <ScrollLink
                    key={i}
                    to={item.to}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={900}
                    style={{
                      cursor: "pointer",
                      color: "inherit",
                      // textDecoration: "none",
                    }}
                  >
                    {console.log('foot', item.route, window.location.pathname)}
                    <Button
                      disableTouchRipple
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        color: "#fff",
                        mx: 1,
                      }}
                    >
                      {item.label}
                    </Button>
                  </ScrollLink>
                ) : (
                  <Link
                    key={i}
                    onClick={() => {
                      setTimeout(() => {
                        scroller.scrollTo(item.to, { smooth: true, duration: 1500 });
                      }, 750);
                    }}
                    to={item.route}
                    // to={item.route !== '/' ? item.route + '/#' + item.to : item.route + '#' + item.to}
                    // style={{ textDecoration: "none" }}
                    css={{
                      cursor: "pointer",
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    <Button
                      disableTouchRipple
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        color: "#fff",
                        mx: 1,
                      }}
                    >
                      {item.label}
                    </Button>
                  </Link>
                )
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ textAlign: { xs: "center", md: "right" } }}
            >
              <Button
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#fff",
                  backgroundColor: "#8A18D0",
                  px: 2,
                  textTransform: "capitalize",
                  py: 1,
                  "&:hover": { background: "#8a18d099" },
                }}
              >
                <Link to={"/contact"}>Request a Demo</Link>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        sx={{ textAlign: "center", fontSize: "14px", fontWeight: "400", mt: 3 }}
      >
        © Copyright 2024, All Rights Reserved by Brand Aura
      </Typography>
    </Box>
  );
};

export default Footer;
