import { Autocomplete, Box, TextField } from "@mui/material";
import { countries } from "../../utils/data/LandingPage/Data";
import { useState } from "react";


export const Country = ({ country, setCountry, onChange, required = false, sx = {}, error, helperText }) => {
    // console.log('Selected country:', country);
    const [inp, setInp] = useState(country.label || '');

    return (
        <Autocomplete
            // id="country-select"
            value={country} // Set the entire country object as the value
            onChange={(event, newValue) => {
                // console.log('Selected country2:', newValue, event);
                if (newValue !== null) {
                    setCountry(newValue); // Set the selected country object
                } else if (newValue === null) {
                    setCountry({ label: '', code: '', phone: '' });
                }
                if (onChange) {
                    onChange(event)
                }
            }}
            required={required}
            options={countries}
            inputValue={inp}
            onInputChange={(event, newValue) => {
                // Allow only letters and spaces
                if (/^[A-Za-z ]*$/.test(newValue)) {
                    setInp(newValue);
                }
            }}
            autoHighlight
            getOptionLabel={(option) => option.label} // Display the country label
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                >
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                </Box>
            )}
            renderInput={(params) => {
                // console.log('parms', params)
                // if (/^[A-Za-z ]*$/.test(value)) {
                //     params.onChange(event);
                // }
                return (
                    <TextField
                        {...params}
                        // label="Choose a country"
                        // sx={{ ...(sx ? sx : null) }}
                        placeholder="Enter country"
                        InputProps={{ ...params.InputProps, sx: { ...sx } }}
                        // onChange={(event) => {
                        //     const value = event.target.value;
                        //     // Allow only letters and spaces
                        //     if (/^[A-Za-z ]*$/.test(value)) {
                        //         params?.inputProps?.onChange(event); // Call the original onChange if valid
                        //         // setInp(value);
                        //         // params?.inputProps?.value = value;
                        //     }
                        // }}
                        required={required}
                        error={error}
                    // helperText={helperText}
                    // autoComplete="new-password"
                    />)
            }
            }
        />
    );
};

