export const HeaderContents = [
  {
    page: 'home',
    path: '/',
    title: 'Portfolio Assessment and Ecommerce Data Analysis | Brand Aura',
    description: null,
  },
  {
    page: 'contact',
    path: '/contact',
    title: 'Contact Us to Grow Your Digital Business | Brand Aura',
    description:
      "Contact us to empower your business in the digital marketplace. Explore tailored strategies to enhance your brand's online presence and achieve growth.",
  },
  {
    page: 'about',
    path: '/about',
    title: 'Empowering Businesses in the Digital Marketplace | Brand Aura',
    description:
      'About empowering businesses in the digital marketplace with innovative strategies and tools to boost online presence, growth, and long-term success.',
  },
  {
    page: 'faq',
    path: '/faq',
    title: 'Data Analytics Platform Frequently Asked Questions | Brand Aura',
    description: null,
  },
  {
    page: 'data-info',
    path: '/data-info',
    title: 'Customize Your Data Experience Online | Brand Aura',
    description: null,
  },
];
