import { createSlice } from '@reduxjs/toolkit';

export const subscribeSlice = createSlice({
    name: 'subscribe',
    initialState: {
        subscribe: localStorage.getItem('seq') ? true : false,
    },
    reducers: {
        subscribed: (state, action) => {
            // console.log('Redux updateBundle action:', state, action);
            state.subscribe = true;
        },
        notSubscribed: (state) => {
            state.subscribe = false;
        },
    },
});

// Export the actions
export const { subscribed, notSubscribed } = subscribeSlice.actions;

// Export the reducer
export default subscribeSlice.reducer;
