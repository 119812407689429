import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../../../utils/http-common';
import { dateClnse3, RawDate } from '../../../../components/Public/DateClnse';
import { downloadCSV } from '../../../../components/Public/DownloadCSV';
import { Bundle } from '../../../../components/@extended/Bundle';
import { updateBundle } from '../../../../slice/bundle';
import { updateCredit } from '../../../../slice/credits';
import { Card_3 } from '../../../../components/card/Bundle-Card';
import { Model_1 } from '../../../../components/@extended/Model';
import { modifyDataIDP } from '../../../../components/@extended/CustomFunctions';
import {
  BundleCreatedIcon,
  BundleRunsIcon,
  CreditsLeftIcon,
  CreditsSpentIcon,
  DeleteSvg,
  DuplicateSvg,
  EditSvg,
  NextSvg,
  PlaySvg3,
  SearchIcon,
} from '../../../../utils/icon/IndividualPages/Icon';

export const Bundle_List = () => {
  const [respData, setRespData] = useState([]);
  const [notMt, setNotMt] = useState(true);
  const [bundleRuns, setBundleRuns] = useState(0);
  const [BundleCreated, setBundleCreated] = useState(0);
  const [modelData, setModelData] = useState({
    title: 'Welcome to Brand Aura',
    emoji: '🎉',
    txt: [
      'Thank you for registering into our product.',
      'If you have any questions, feel free to reach out to our support team.',
      'Lets get started!!!',
    ],
    cl: '',
    btn_1: 'Add Credits',
    btn_1_Link: '/dashboard/individual-dashboard/recharge',
    btn_2: 'Select Data',
    btn_2_Link: '/dashboard/individual-dashboard/user-wizard',
  });
  // const [cred, setCred] = useState([0, 0])

  const [open, setOpen] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const credi = useSelector((state) => state.credit.credit);

  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenS(false);
  };

  const Data2 = [
    {
      icon: CreditsLeftIcon,
      title: 'Credits Left',
      value: credi.available_credit,
      // value: 0
    },
    {
      icon: CreditsSpentIcon,
      title: 'Over all credits spent',
      value: credi.used_credit,
      // value: 0
    },
    {
      icon: BundleRunsIcon,
      title: 'Total Bundle Runs',
      value: bundleRuns,
    },
    {
      icon: BundleCreatedIcon,
      title: 'Total Bundles Created',
      value: BundleCreated,
    },
  ];

  // const ModelData = {
  //     title: 'Run Bundle',
  //     emoji: '',
  //     txt: ['Running the bundle costs credits.', 'You need 20 Credits to run this bundle.'],
  //     cl: '40',
  //     btn_1: 'Cancel',
  //     btn_1_cancel: true,
  //     // btn_1_Link: '',
  //     btn_2: 'Proceed',
  //     btn_2_cancel: 'true',
  //     // btn_2_Link: '/dashboard/individual-dashboard',
  //     // btn_2_fn: Bundle_Creation
  // }
  const filterBundles = respData.filter((bundle) =>
    bundle.ind_bundle_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dispatch = useDispatch();
  const navi = useNavigate();
  const bundle = useSelector((state) => state.bundles.bundle);
  console.log('bundle data', bundle);

  const EditBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      // const dr = { startDate: selectedData2.ind_bundle_start_date, endDate: selectedData2.ind_bundle_start_date }
      const dr = {
        startDate: RawDate(selectedData2.ind_bundle_start_date),
        endDate: RawDate(selectedData2.ind_bundle_end_date),
      };
      console.log('respData', dr, respData, filteredData, filteredData[0]);
      const set = {
        SelectData: {
          category: selectedData2.table.Category,
          category_id: selectedData2.ind_bundle_category_id,
          country: selectedData2.table.Country,
          country_id: selectedData2.ind_bundle_country_id,
          ecommerceSites: selectedData2.table['E-Commerce Site'],
          ecommerceSites_id: selectedData2.ind_bundle_e_commerce_id,
          brand: selectedData2.table.Brand,
          brand_id: selectedData2.ind_bundle_brand_id,
        },
        bundleName: selectedData2.ind_bundle_name,
        ind_bundle_id: selectedData2.ind_bundle_id,
        daterange: dr,
        state: 0,
      };
      console.log('set', set);
      await dispatch(updateBundle(set));
      console.log('bundle data', bundle);
      navi('/dashboard/individual-dashboard/user-wizard');
      // localStorage.setItem('DataSelectionEdit', JSON.stringify(set))
      // localStorage.removeItem('bundle_id')
      // window.location.href = '/dashboard/individual-dashboard/user-wizard'
    } catch (error) {
      console.log('Edit bundle error', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Set the Edit Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const DeleteBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const response = await http.delete(
        `/master_service/bundle/delete/${localStorage.getItem(
          'user_id'
        )}/${bundle_id}`
      );
      await FetchDatas();
      console.log('respon', response);

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
      setModelData({
        title: 'Welcome to Brand Aura',
        emoji: '🎉',
        txt: [
          'Thank you for registering into our product.',
          'If you have any questions, feel free to reach out to our support team.',
          'Lets get started!!!',
        ],
        cl: '',
        btn_1: 'Add Credits',
        btn_1_Link: '/dashboard/individual-dashboard/recharge',
        btn_2: 'Select Data',
        btn_2_Link: '/dashboard/individual-dashboard/user-wizard',
      });
      localStorage.removeItem('bundle_id');
    } catch (error) {
      console.log('Delete bundle error', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Delete Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const CopyBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const response = await http.post(
        `/master_service/duplicate_bundle`,
        JSON.stringify({
          user_id: localStorage.getItem('user_id'),
          ind_bundle_id: bundle_id,
        })
      );
      await FetchDatas();
      console.log('respon', response);
      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
      localStorage.removeItem('bundle_id');
    } catch (error) {
      console.log('Delete bundle error', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to copy Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const DownloadBundle = async (bundle_id) => {
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem('user_id'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      const response = await http.post(
        'master_service/download_bundle',
        payload
      );
      console.log('resp Dnw', response);
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      const time = new Date().toLocaleTimeString();
      console.log('tm', time);
      downloadCSV(
        response.data.data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(new Date())}_${time}`
      );
      await FetchDatas();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error with RunBundle:', error);
      setMessage(
        error.response ? error.response.data.error : 'Unable to Run the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };
  const Sample_Data = async () => {
    try {
      // const f = dateRange[0];
      // const f = dateClnse2(dateRange.startDate);
      // const f = dateClnse2(dateRange[0]);
      // const t = dateRange[1];
      // const t = dateClnse2(dateRange.endDate);
      // const t = dateClnse2(dateRange[1]);
      // console.log("ft", f, t);
      const bd = filterBundles.filter(
        (data) =>
          data.ind_bundle_id === (localStorage.getItem('bundle_id') || 0)
      );
      const bundleData = { data: bd[0] };
      if (bundleData.data.ind_bundle_start_date === null || bundleData.data.ind_bundle_end_date === null) {
        setMessage('There is some error running the Sample data, Edit this bundle and select from and to date to get the sample data');
        setSeverity('error');
        handleClick();
        return;
      }
      const payload = {
        category_id: bundleData.data.ind_bundle_category_id,
        site_id: bundleData.data.ind_bundle_e_commerce_id,
        brand_id: bundleData.data.ind_bundle_brand_id,
        country_id: bundleData.data.ind_bundle_country_id,
        from_date: bundleData.data.ind_bundle_start_date,
        to_date: bundleData.data.ind_bundle_end_date,
      };
      const response = await http.post(
        'master_service/sample/bundle/data',
        payload
      );
      downloadCSV(
        response.data.data,
        bundleData.data.ind_bundle_name + '_Sample_Data'
      );
      // setBundleData(response.data.data);
      // setTbl({
      //   Category: response.data.payload.ind_bundle_category,
      //   Country: response.data.payload.ind_bundle_country,
      //   "E-Commerce Site": response.data.payload.ind_bundle_e_commerce,
      //   Brand: response.data.payload.ind_bundle_brand,
      //   "Configured Date": response.data.payload.ind_bundle_config_date,
      // });
      console.log(
        'res smpl data',
        response,
        // response.data.payload.ind_bundle_category
      );
      setMessage('The Sample data is Downloaded Successfully');
      setSeverity('success');
      handleClick();
      // localStorage.setItem('sample_datas', JSON.stringify(response.data))
      // window.location.href = '/dashboard/individual-dashboard/sample-data'
    } catch (error) {
      console.error('Error fetching sample data:', error);
      setMessage('There is some error running the Sample data');
      setSeverity('error');
      handleClick();
    }
  };

  const ChangeModelData = (itm, rb) => {
    console.log('clk');
    if (itm === 0) {
      if (!rb) {
        // EditBundle();
        Sample_Data();
        // console.log('Not yet implemented');
        // setMessage(
        //   'The Feature is not yet implemented, will be Launching Soon'
        // );
        // setSeverity('success');
        // handleClick();
      } else {
        Sample_Data();
      }
    } else if (itm === 1) {
      if (!rb) {
        EditBundle();
      } else {
        // CopyBundle();
        setModelData({
          title: 'Copy Bundle',
          // emoji: '🎉',
          txt: ['Are you sure you want to copy the bundle??'],
          cl: '',
          btn_1: 'Cancel',
          btn_1_cancel: true,
          // btn_1_Link: '/dashboard/individual-dashboard/recharge',
          btn_2: 'Copy',
          btn_2_fn: CopyBundle,
          btn_2_cancel: true,
          // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
        });
        setOpen(true);
      }
    } else if (itm === 2) {
      if (!rb) {
        setModelData({
          title: 'Delete Bundle',
          // emoji: '🎉',
          txt: ['Are you sure you want to delete the bundle??'],
          cl: '',
          btn_1: 'Cancel',
          btn_1_cancel: true,
          // btn_1_Link: '/dashboard/individual-dashboard/recharge',
          btn_2: 'Delete',
          btn_2_fn: DeleteBundle,
          btn_2_cancel: true,
          // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
        });
        setOpen(true);
      } else {
        setModelData({
          title: 'Delete Bundle',
          // emoji: '🎉',
          txt: ['Are you sure you want to delete the bundle??'],
          cl: '',
          btn_1: 'Cancel',
          btn_1_cancel: true,
          // btn_1_Link: '/dashboard/individual-dashboard/recharge',
          btn_2: 'Delete',
          btn_2_fn: DeleteBundle,
          btn_2_cancel: true,
          // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
        });
        setOpen(true);
      }
    }
  };

  const settingsForRb = [
    { title: 'Sample Data', fn: ChangeModelData, link: '' },
    { title: 'Edit Bundle', fn: ChangeModelData, link: '' },
    { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
  ];
  const settingsForNot = [
    { title: 'Sample Data', fn: ChangeModelData, link: '' },
    { title: 'Copy Bundle', fn: ChangeModelData, cb: true, link: '' },
    { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
  ];
  const settingsForcopy = [
    { title: 'Edit Bundle', fn: () => ChangeModelData(1, false), link: '' },
    { title: 'Delete Bundle', fn: () => ChangeModelData(2, true), link: '' }
];

  const FetchDatas = async () => {
    try {
      const response = await http.post(
        '/master_service/bundle/log/list',
        JSON.stringify({ user_id: localStorage.getItem('user_id') })
      );
      if (response.data.data.length === 0) {
        setNotMt(false);
        // setOpen(true);
      } else {
        setNotMt(true);
        const modifiedData = modifyDataIDP(response.data.data);
        setRespData(modifiedData);
        setBundleCreated(response.data.Bundle_Created);
        setBundleRuns(response.data.Bundle_Runs);
        await Credit();
        console.log('respon', response, response.data.data, modifiedData);
      }

      //   setMessage(response.data.message);
      //   setSeverity("success");
      //   handleClick();
    } catch (error) {
      console.log('fetching error', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Credit = async () => {
    try {
      const res = await http.post(
        '/master_service/credit',
        JSON.stringify({ user_id: localStorage.getItem('user_id') })
      );
      console.log('resp', res, res.data);
      // setCred([res.data.data.used_credit, res.data.data.balance_credit])
      dispatch(
        updateCredit({
          available_credit: res.data.data.available_credit,
          used_credit: res.data.data.used_credit,
        })
      );
    } catch (error) {
      console.log('credits err', error);
    }
  };

  const RunBundle = async (bundle_id) => {
    const filteredData = respData.filter(
      (data) => data.ind_bundle_id === bundle_id
    );
    const selectedData2 = filteredData[0];
    console.log('filteredData', filteredData, selectedData2);
    if (selectedData2?.ind_bundle_start_date === null || selectedData2?.ind_bundle_end_date === null) {
      setMessage('Edit the bundle to get the data.');
      setSeverity('error');
      handleClick();
      return;
    }
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem('user_id'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      const response = await http.post('master_service/run/bundle', payload);
      const time = new Date().toLocaleTimeString();
      console.log('tm', time);
      downloadCSV(
        response.data.data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(new Date())}_${time}`
      );
      await FetchDatas();

      setMessage('Bundle run sucessfully');
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error with RunBundle:', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Run the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  useEffect(() => {
    FetchDatas();
  }, []);
  const runbndl = {
    title: 'Run Bundle',
    // emoji: "🎉",
    cl: credi.available_credit,
    btn_1: 'Cancel',
    btn_1_cancel: true,
    // btn_1_Link: "/dashboard/individual-dashboard/recharge',
    btn_2: 'Proceed',
    btn_2_cancel: true,
    // btn_2_Link: "/dashboard/individual-dashboard/user-wizard",
    btn_2_fn: RunBundle,
    Credits: true,
    CreditsLnk: '/dashboard/individual-dashboard/recharge',
    // btn_2_fn_props: {},
  };
  const dnbndl = {
    title: 'Download Bundle',
    // emoji: "🎉",
    txt: [
      // 'You have used the free downloads.',
      [
        { text: 'You need ' },
        { text: `${250} Credits`, isBold: true },
        { text: ' to download this bundle.' },
      ],
      // "You need 2 Credits to download this bundle.",
    ],
    cl: credi.available_credit,
    btn_1: 'Cancel',
    btn_1_cancel: true,
    // btn_1_Link: "/dashboard/individual-dashboard/recharge',
    btn_2: 'Proceed',
    btn_2_cancel: true,
    Credits: true,
    CreditsLnk: '/dashboard/individual-dashboard/recharge',
    // btn_2_Link: "/dashboard/individual-dashboard/user-wizard",
    btn_2_fn: DownloadBundle,
  };
  const BndlePops = ({ x, ec, id }) => {
    console.log('BndlePops', x, id);
    if (x === 0) {
      console.log('ent', x, id);
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === id
      );
      const selectedData2 = filteredData[0];
      console.log('filteredData', filteredData, selectedData2);
      if (selectedData2.ind_bundle_start_date === null || selectedData2.ind_bundle_end_date === null) {
        setMessage('Edit the bundle to get the data.');
        setSeverity('error');
        handleClick();
        return;
      }
      setModelData({
        ...runbndl,
        txt: [
          'Running the bundle costs credits.',
          [
            { text: 'You need ' },
            { text: `${ec} Credits`, isBold: true },
            { text: ' to run this bundle.' },
          ],
          // `You need ${ec} Credits to run this bundle.`,
        ],
        btn_2_fn_props: id,
      });
      setOpen(true);
    } else if (x === 1) {
      setModelData({ ...dnbndl, btn_2_fn_props: id });
      setOpen(true);
    } else if (x === 2) {
      DownloadBundle(id);
    }
  };
  return (
    <Box sx={{ height: '100%', width: '100%', overflowY: 'auto' }}>
      <Model_1
        open={open}
        setOpen={setOpen}
        data={modelData}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          borderRight: '0.8px solid #797F8F80',
          display: 'flex',
          flexDirection: 'column',
          py: { xs: 1, md: 3 },
          px: { xs: 1, md: 5 },
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            py: { xs: 2, md: 0 },
          }}>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '20px',
              py: { xs: 1, md: 3 },
              background:
                'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            Bundle Summary
          </Typography>
          <Link to={'/dashboard/individual-dashboard/user-wizard'}>
            <Button
              sx={{
                background: '#8A18D0',
                px: 2,
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': { background: '#B37FFC' },
              }}>
              <Typography sx={{ fontWeight: '600', fontSize: '10px' }}>
                New Bundle
              </Typography>
            </Button>
          </Link>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}>
          {Data2.map((f, j) => (
            <Card_3
              key={j}
              data={{
                icon: f.icon,
                title: f.title,
                value: f.value,
                wd: `${100 / 4 - 0.5}%`,
                bg: '#F3E7FA',
                bd: '',
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
          }}>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '16px',
              py: 3,
              color: '#000000',
            }}>
            My Bundles
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #797F8F',
              borderRadius: '4px',
              padding: '0.5rem 1rem',
              // backgroundColor: '#f9f9f9',
              width: '100%',
              height: '35px',
              maxWidth: '250px',
            }}>
            <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
            <TextField
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                    height: '35px',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
              placeholder='Search bundle...'
              variant='outlined'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
        </Box>
        <>
          {notMt ? (
            <Box>
                    {filterBundles.map((e, i) => (
            <Bundle
                key={i}
                e={e}
                ChangeModelData={ChangeModelData}
                BndlePops={BndlePops}
                BtnDatas={
                    !e.ind_bundle_start_date
                        ? settingsForcopy
                        : e.ind_bundle_run
                            ? settingsForNot
                            : settingsForRb
                }
            />
        ))}
            </Box>
          )
            :
            (
              <Box
                sx={{
                  border: 'dashed 1px #BCBFC7',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  py: 15,
                }}>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: '600', color: '#8A18D0' }}>
                  No configurations found!!!
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: '400', color: '#000' }}>
                  Please configure to view the bundles
                </Typography>
              </Box>
            )}
        </>
      </Box>
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ position: 'absolute', mt: '38px' }}>
        <Alert
          onClose={handleClose}
          severity={severity}
          variant='filled'
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export const Bundle_Log_2 = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '20px',
              pb: 3,
              background:
                'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            My Bundles
          </Typography>
        </Box>
      </Box>
      <Box>
        {Data.map((e, i) => (
          <Card
            e={e}
            key={i}
          />
        ))}
      </Box>
    </Box>
  );
};

export const SmallCard = ({ e }) => {
  return (
    <Box
      sx={{
        border: '1px solid #EAECF0',
        background: e.bg,
        mx: 2,
        borderRadius: '2px',
        height: '52px',
        width: '80px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box>
        <Typography
          sx={{ fontWeight: '600', fontSize: '10px', color: '#000000' }}>
          {e.title}
        </Typography>
        <Typography
          sx={{ fontWeight: '500', fontSize: '12px', color: '#474747' }}>
          {e.text}
        </Typography>
      </Box>
    </Box>
  );
};

export const Card = ({ e }) => {
  return (
    <Box
      sx={{
        borderRadius: '6px',
        border: '1px solid #BCBFC7',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        my: 2,
      }}>
      <Box
        sx={{
          height: '52px',
          width: '57px',
          color: '#fff',
          background: '#000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '600',
          mx: 2,
          fontSize: '24px',
        }}>
        {e.bundle_id}
      </Box>
      <Box sx={{ mx: 2 }}>
        <Typography>{e.title}</Typography>
        <Box sx={{ display: 'flex', mt: 1 }}>
          <Typography
            sx={{
              p: 1,
              border: '0.8px solid #474747CC',
              borderRadius: '20px',
              color: '#474747CC',
              mr: 1,
            }}>
            Data From : {e.date_from}
          </Typography>
          <Typography
            sx={{
              p: 1,
              border: '0.8px solid #474747CC',
              color: '#474747CC',
              borderRadius: '20px',
              ml: 1,
            }}>
            Data Till : {e.date_till}
          </Typography>
        </Box>
      </Box>
      <SmallCard
        e={{ bg: '#1DA1F21A', title: 'Categories', text: e.Categories }}
      />
      <SmallCard e={{ bg: '#FFE2E5', title: 'Countries', text: e.Countries }} />
      <SmallCard e={{ bg: '#DCFCE7', title: 'Platforms', text: e.Platforms }} />
      <SmallCard e={{ bg: '#FFF4DE', title: 'Brands', text: e.Brands }} />
      <SmallCard
        e={{ bg: '#F3E7FA', title: 'Bundle Cost', text: e.Bundle_Cost }}
      />
      <Button disableRipple>
        <EditSvg />
      </Button>
      <Button disableRipple>
        <DeleteSvg />
      </Button>
      <Button disableRipple>
        <DuplicateSvg />
      </Button>
      <Button disableRipple>
        <PlaySvg3 />
      </Button>
      <Button disableRipple>
        <NextSvg />
      </Button>
    </Box>
  );
};
