import {
  Box, Typography, Button, Snackbar, Alert,
} from '@mui/material';
import { TableComp6 } from '../../../../../components/table/type - 6/index.jsx';
import { ConfigStyleBar } from '../../../../../utils/data/IndividualDashboard/Data.js';
import { CorporateNormalStyleBar2, Data } from '../../../../../utils/data/CorporateNormalUserDatas/Data.js';
import { modifyUserLandingTable, parseProperly } from '../../../../../components/@extended/CustomFunctions.jsx';
import { useState, useEffect } from 'react';
import http from "../../../../../utils/http-common.js"
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BtnLoad } from '../../../../../utils/data/LandingPage/Data.js';

export const CorporateNormalDashboard = () => {
  // const [tblData, setTblData] = useState([]);
  // const dd = localStorage.getItem('DashboardData')
  // console.log('dd !== null && dd !== undefined ? dd : []', dd !== null && dd !== undefined ? dd : [], dd ? dd : [], parseProperly(dd))
  const [tblData, setTblData] = useState(parseProperly(localStorage.getItem('DashboardData')))
  // const [tblData, setTblData] = useState(() => {
  //   const storedData = localStorage.getItem('DashboardData');
  //   if (storedData) {
  //       try {
  //           return JSON.parse(storedData);
  //       } catch (error) {
  //           console.error('Error parsing DashboardData from localStorage:', error);
  //           return []; // Return an empty array if parsing fails
  //       }
  //   }
  //   return [];  // Return parsed data or an empty array
  // });
  const nav = useSelector((state) => state.navs.sideNav.nav);
  const [bundle, setBundle] = useState(0)
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const fetchDatas = async () => {
    try {
      // console.log('tblss', tblData, tblData.length === 0, tblData === null)
      if (tblData.length === 0) {
        setLoad(true)
      }
      const response = await http.post(
        '/master_service/dashboard/page',
        JSON.stringify({ user_id: localStorage.getItem('user_id') })
      );
      // console.log('response dashboard', response?.data, response?.data?.bundles);
      setTblData(response?.data?.bundles || []);
      localStorage.setItem('DashboardData', JSON.stringify(response?.data?.bundles || []))
      if (response.status === 200) {
        setMessage(response.data.message);
        setSeverity("success");
        handleClick();
      }
      setLoad(false)
    }
    catch (error) {
      console.error('Error fetching data:', error?.response?.data?.message);
      setLoad(false)
      setMessage(
        error?.response
          ? error?.response?.data?.message
          : 'Unable to Fetch the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  }
  useEffect(() => {
    fetchDatas();
  }, []);
  useEffect(() => {
    // console.log('nav', nav)
    if (nav !== -1 && nav !== 0) {
      setBundle(nav - 1)
    } else if (nav === -1) {
      setBundle(0)
    }
  }, [nav]);
  return (
    <Box sx={{ display: 'flex', position: 'relative', flexDirection: 'column', width: '100%', height: '100%', p: '25px', pt: 2, }}>
      {tblData.length !== 0 &&
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#2B2B2B' }}>{tblData[bundle]?.ind_bundle_name}</Typography>
            <Link to={`/user/report?ind_bundle_id=${encodeURIComponent(tblData[bundle]?.ind_bundle_id)}`}>
              <Button sx={{ mt: '20px', textAlign: 'center', background: '#8A18D0', color: '#fff', width: '80px', fontSize: '12px', textTransform: 'capitalize', '&:hover': { background: '#B37FFC' }, }}>
                Report
              </Button>
            </Link>
          </Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#2B2B2B' }}>Brands</Typography>
          <Box sx={{}}>
            {tblData[bundle]?.brands.map((data, index) => (
              <Box key={index} sx={{ my: 2, borderRadius: '8px', borderTop: '1px solid #DADADA', borderRight: '1px solid #DADADA', borderLeft: '1px solid #DADADA', }}>
                <Box sx={{ p: 1, pl: 4 }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#2B2B2B' }}>{data.brand_name}</Typography>
                  <a href={data.brand_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2B2B2BCC', cursor: 'pointer' }}>
                      {data.brand_url}
                    </Typography>
                  </a>
                </Box>
                <TableComp6 Data={modifyUserLandingTable({ d: data?.ecommerce || [], ee: tblData[bundle], data: data || [] })} Style={{ ...CorporateNormalStyleBar2, isThreeDot: false }} />
              </Box>
            ))}
          </Box>
        </Box>
      }
      {/* <Box>
        {load && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
            <Box sx={BtnLoad} />
          </Box>
        )}
      </Box> */}
      {/* {tblData?.map((bundle, i) => (
        <Box key={i}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#2B2B2B' }}>{bundle.category.category_name}</Typography>
            <Link to={'/user/report'}>
              <Button
                sx={{
                  mt: '20px',
                  textAlign: 'center',
                  background: '#8A18D0',
                  color: '#fff',
                  width: '100px',
                  textTransform: 'capitalize',
                  '&:hover': { background: '#B37FFC' },
                }}
              >
                Report
              </Button>
            </Link>
          </Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#2B2B2B' }}>Brands</Typography>
          <Box sx={{}}>
            {bundle.brands.map((data, index) => (
              <Box key={index} sx={{ my: 2, borderRadius: '8px', borderTop: '1px solid #DADADA', borderRight: '1px solid #DADADA', borderLeft: '1px solid #DADADA', }}>
                <Box sx={{ p: 1, pl: 4 }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#2B2B2B' }}>{data.brand_name}</Typography>
                  <a href={data.brand_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2B2B2BCC', cursor: 'pointer' }}>
                      {data.brand_url}
                    </Typography>
                  </a>
                </Box>
                <TableComp6 Data={modifyUserLandingTable({ d: data?.ecommerce || [], ee: bundle, data: data || [] })} Style={{ ...CorporateNormalStyleBar2, isThreeDot: false }} />
              </Box>
            ))}
          </Box>
        </Box>
      ))} */}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
