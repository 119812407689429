import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { BundleOffersIcon, NewProductIcon, NewSellerIcon, OthersIcon, PriceDropIcon, PriceUpIcon } from "../../../../../../utils/icon/CorporateNormalUserDatas/Icon"
import { NotifiCategoryCard, NotifyCard } from "../../../../../../components/@extended/Card"
import { useEffect, useState } from "react"
import { CategoryD, NotifiD } from "../../../../../../utils/data/CorporateNormalUserDatas/Data"
import { SearchIcon } from "../../../../../../utils/icon/IndividualPages/Icon"
import { CalendarIcon, RefreshIcon } from "../../../../../../utils/icon/CorporateNormalUserDatas/Icon"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation } from "react-router-dom"
import http from '../../../../../../utils/http-common'
import { NotificationFilter } from "../../../../../../components/@extended/CustomFunctions"
import { formatDate } from "../../../../../../components/Public/DateClnse"

export const Template = () => {
    return (
        <Box>
        </Box>
    )
}

export const NotifiMain = () => {
    const [cat, setCat] = useState(-1)
    const [date, setDate] = useState(-1)
    const [selectedNotifi, setSelectedNotifi] = useState('Brand');
    const [searchTerm, setSearchTerm] = useState('');
    const [res, setRes] = useState(null);
    const rowsPerPage = 10;
    const [page, setPage] = useState(1);
    const Get = async () => {
        try {
            const response = await http.post(`master_service/notification_alter/page_product`, JSON.stringify({
                // const response = await http.get(`master_service/product_page_info/${product_id}`, JSON.stringify({
                user_id: localStorage.getItem('user_id'),
            }))
            setRes(NotificationFilter(response?.data?.data))
            console.log("response noti:", response?.data?.data, res);
            // setTblDataP(responseP?.data.bundle?.brands[0]?.ecommerce || [])
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => { Get() }, [])

    const handleRefresh = () => {
        setCat(-1);
        setDate(-1);
        setSearchTerm('');
        Get();

    };

    // const filteredNotifications = res?.filter((notification) => {
    //     const matchesCategory = cat === -1 || notification.categoryFilter === cat;
    //     const matchesSearch = notification.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification?.product_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification?.site_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         // notification?.Seller?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification?.current_price?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification?.time?.toLowerCase().includes(searchTerm.toLowerCase());
    //     return matchesCategory && matchesSearch;
    // });

    const filteredNotifications = res?.filter((notification) => {
        const matchesCategory = cat === -1 || notification.categoryFilter === cat;
        // const matchesDate = notification?.created_at === date || notification?.updated_at === date;
        // const matchesDate =
        //     date === null ||
        //     date === undefined ||
        //     (notification?.created_at === date ||
        //         notification?.updated_at === date);
        const formattedDate = formatDate(date);
        const createdAtDate = formatDate(notification?.created_at);
        const updatedAtDate = formatDate(notification?.updated_at);
        const matchesDate = date === undefined || date === null || date === -1 || (formattedDate === updatedAtDate);
        const matchesSearch = notification.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.prodName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.EcommName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.Seller?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.prodPrice?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.time?.toLowerCase().includes(searchTerm.toLowerCase());
        // return matchesCategory && matchesSearch;
        // console.log('matchesCategory && matchesDate && matchesSearch', matchesCategory, matchesDate, matchesSearch, matchesCategory && matchesSearch,matchesCategory && matchesDate && matchesSearch)

        return matchesCategory && matchesDate && matchesSearch;
    });
    // const filteredNotifications = NotifiD.filter((notification) => {
    //     const matchesCategory = cat === -1 || notification.category === cat;
    //     const matchesSearch = notification.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification.prodName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification.EcommName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification.Seller?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification.prodPrice?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         notification.time?.toLowerCase().includes(searchTerm.toLowerCase());
    //     return matchesCategory && matchesSearch;
    // });
    console.log('filteredNotifications', filteredNotifications)
    const totalRows = filteredNotifications?.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
    const paginatedData = filteredNotifications?.slice(startIndex, endIndex);
    // console.log('datt', { date: date });
    // console.log('page', page, totalPages, startIndex, endIndex, paginatedData)

    const menuItems = [];
    for (let i = 1; i <= totalPages; i++) {
        menuItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    useEffect(() => { if (totalPages <= 0) { setPage(1) } }, [totalPages])


    return (
        <Box sx={{ border: '0.8px solid #BCBFC7', borderRadius: '8px', display: 'flex', width: '100%' }}>
            <Box sx={{ borderRight: '0.8px solid #BCBFC7', p: 2, minWidth: 'max-content', width: '20%' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000' }}>Category</Typography>
                <Category prop={{ cat, setCat, res }} />
            </Box>
            <Box sx={{ width: '80%', p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600', textWrap: 'nowrap', color: '#000' }}>{cat !== -1 ? `${CategoryD[cat].rawText} Notification` : 'All Notification'}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '350px', }}>
                            <SearchIcon
                                sx={{ color: '#757575', marginRight: '0.5rem' }}
                            />
                            <TextField
                                sx={{
                                    flex: 1,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                            height: '35px',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                placeholder='Search ...'
                                variant='outlined'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Box>
                        {/* <Button sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', width: '40%', height: '35px', maxWidth: '350px', }}>
                            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#797F8F" }}>26 Jun 2024</Typography>
                            <CalendarIcon sx={{ color: '#757575', }} />
                        </Button> */}
                        <Box>
                            <TextField
                                type="date"
                                value={date}
                                onChange={(e) => {
                                    // console.log('eee', e, e.target.value === '');
                                    setDate(e.target.value !== '' ? e.target.value : null)
                                }}
                                size="small"
                                required
                                sx={{
                                    color: "#000000",
                                    height: "32px",
                                    width: 'max-content',
                                    // width: { xs: "100%", md: "290px" },
                                    borderRadius: "3px",
                                    border: "0.8px",
                                    display: "flex",
                                }}
                            ></TextField>
                        </Box>
                        <Select
                            value={selectedNotifi}
                            onChange={(e) => setSelectedNotifi(e.target.value)}
                            displayEmpty
                            sx={{ p: 0, height: '36px', width: "150px", }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {/* <MenuItem value='All'>All</MenuItem> */}
                            <MenuItem value='Brand'>Brand</MenuItem>
                            <MenuItem value='E-Commerce'>E-Commerce</MenuItem>
                        </Select>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, pb: 2 }}>
                    <Button disableTouchRipple disableFocusRipple disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', textTransform: 'capitalize', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', height: '35px', }}
                        onClick={handleRefresh}  >
                        <RefreshIcon />
                        <Typography sx={{ color: "#474747", fontSize: "12px", pl: 1, fontWeight: "500" }}>Refresh</Typography>
                    </Button>
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "flex-end", gap: 2 }}>
                        <Typography sx={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>Navigate to</Typography>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', width: '5%', height: '30px', maxWidth: '350px', }}>
                            <Typography>01</Typography>
                        </Box> */}

                        <Select
                            labelId="select-TicketType"
                            value={page}
                            onChange={(e) => { setPage(e.target.value) }}
                            displayEmpty
                            sx={{
                                width: 'max-content',
                                // width: { xs: "100%", md: "601px" },
                                height: "35px",
                            }}
                        >
                            {menuItems}
                            {/* <MenuItem value={1}> we should be looping this menu item till totalpages variable(eg, 20)
                                1
                            </MenuItem> */}
                        </Select>
                        <Typography sx={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>Page</Typography>
                        <Button disableElevation disableFocusRipple disableRipple sx={{ all: 'unset', cursor: 'pointer' }} onClick={() => {
                            if (page > 1) {
                                setPage(page - 1);
                            }
                        }} disabled={page === 1}><ArrowBackIosNewIcon /></Button>
                        <Button disableElevation disableFocusRipple disableRipple sx={{ all: 'unset', cursor: 'pointer' }} onClick={() => setPage((x) => x + 1)} disabled={page >= totalPages}><ArrowForwardIosIcon /></Button>
                    </Box>
                </Box>
                <Notification prop={{ cat, swap: selectedNotifi, notifications: paginatedData }} />
            </Box>
        </Box >
    )
}

export const Category = ({ prop }) => {
    const { cat, setCat, res } = prop;
    return (
        <Box sx={{ width: '100%', p: 2 }}>
            {CategoryD?.map((e, i) => (<NotifiCategoryCard p={{ ...e, cat, setCat, res, i }} key={i} />))}
        </Box>
    )
}
export const Notification = ({ prop }) => {
    const { notifications, swap } = prop;
    // console.log('notifications', notifications);

    return (
        <Box sx={{ width: '100%', height: '63vh', overflow: 'auto', scrollbarWidth: 'none', p: 2 }}>
            {notifications?.length !== 0 ?
                notifications?.map((e, i) => (
                    <NotifyCard p={e} swap={swap} key={i} />
                ))
                :
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%' }}>
                    <Typography>Notifications Unavailable</Typography>
                </Box>
            }
        </Box>
    )
}
