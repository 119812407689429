import { Box, Button, Rating, Typography } from '@mui/material';
import {
  BrandsIcon,
  CategoriesChoosenIcon,
  CountriesAddedIcon,
  EcommerceIcon,
  TotalCreditCostIcon,
} from '../../utils/icon/IndividualPages/Icon';
import StarIcon from '@mui/icons-material/Star';
import { dateClnse, RailwayTimeToNormalTime, timeAgo } from '../Public/DateClnse';
import { Link } from 'react-router-dom';
import { RoundedBtn } from './Button';
import { ActiveBundle } from './ApiCall';
import { ThreeDot2 } from './ThreeDots';
import { NotiIcons } from '../../utils/icon/CorporateNormalUserDatas/Icon';

export const modifyDataIDP = (data) => {
  return data.map((item, index) => {
    return {
      ...item,
      card_datas: [
        {
          icon: TotalCreditCostIcon,
          title: 'Total Credit Cost',
          value: item.estimated_credits.toString().split('.')[0],
        },
        {
          icon: CategoriesChoosenIcon,
          title: 'Categories Chosen',
          value: '1',
        },
        {
          icon: CountriesAddedIcon,
          title: 'Countries Added',
          value: '1',
        },
        {
          icon: EcommerceIcon,
          title: 'E-Commerce Sites',
          value: item.ind_bundle_e_commerce.length.toString(),
        },
        {
          icon: BrandsIcon,
          title: 'Brands Selected',
          value: item.ind_bundle_brand.length.toString(),
        },
      ],
      table: {
        Category: item.ind_bundle_category,
        Country: item.ind_bundle_country,
        'E-Commerce Site': item.ind_bundle_e_commerce,
        Brand: item.ind_bundle_brand,
        'Configured Date': dateClnse(item.ind_bundle_config_date),
      },
    };
  });
};

export const modifyDataSelectionSampleDataTable = (d) => {
  if (d.length === 0 || !d) {
    return [{
      product: '',
      category: '',
      country: '',
      'E-Commerce Site': '',
      brand: '',
      seller: '',
      product_price: '',
      discount: '',
      seller_price: '',
      review: '',
      rating: '',
      stock_status: '',
    }]
  }
  return d?.map((e, i) => {
    // const formattedDate = e.bundle_date ? dateClnse(new Date(e.bundle_date)) : '-';
    // console.log('e?.product_id', e?.product_id, e?.product_id?.length)
    return {
      product: e?.product_name ? (
        <Typography sx={{ color: 'inherit', fontSize: 'inherit', textAlign: 'inherit', fontWeight: 'inherit', minWidth: '300px' }}>{e?.product_name}</Typography>
      ) : '',
      category: e?.category_name || '',
      country: e?.country_name || '',
      'E-Commerce Site': e?.site_name || '',
      brand: e?.brand_name || '',
      seller: e?.seller_name ? (
        <Typography sx={{ color: 'inherit', fontSize: 'inherit', textAlign: 'inherit', fontWeight: 'inherit', minWidth: '100px' }}>{e?.seller_name}</Typography>
      ) : '',
      product_price: e?.product_price || '',
      discount: e?.discount || '',
      seller_price: e?.seller_price || '',
      // review: '',
      rating: e?.ratings || e?.ratings === 0 ? (
        <Rating
          name='rating'
          value={parseFloat(e?.ratings)}
          precision={0.5}
          readOnly
          emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />}
          icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />}
        />
      ) : '',
      stock_status: e?.stock_available || '',
    }
  })
}


export const modifyBundleRunLogTable = (d) => {
  if (d.length === 0) {
    return [{
      "S.No": '',
      Bundle_Name: '',
      Date: '',
      Time: '',
      Credit_Cost: ''
    }]
  }
  return d.map((e, i) => {
    const formattedDate = e.bundle_date ? dateClnse(new Date(e.bundle_date)) : '-';
    return {
      "S.No": d.length - i || '-',
      Bundle_Name: e.bundle_name || '-',
      Date: formattedDate,
      Time: RailwayTimeToNormalTime(e.bundle_time) || '-',
      Credit_Cost: e.credit_cost || '-',
    }
  })
}


export const modifyRechargeCregitTable = (d) => {
  if (d.length === 0) {
    return [{
      Paid_by: '',
      Mail_ID: '',
      "Transaction No .": '',
      Payment_Type: '',
      Payment_Date: '',
      Credits_Added: '',
      // Invoice: ''
    }]
  }
  return d.map((e, i) => {
    const formattedPaymentDate = e.payment_date ? dateClnse(new Date(e.payment_date)) : '-';
    return {
      Paid_by: e.name || '-',
      Mail_ID: e.user_email || '-',
      "Transaction No .": e.transaction_no || '-',
      Payment_Type: e.payment_type || '-',
      Payment_Date: formattedPaymentDate,
      Credits_Added: e.applicable_credit || '-',
      // Invoice: e.Invoice
    }
  })
}
export const modifyDataABLP = (data) => {
  return data.map((item, index) => {
    return {
      ...item,
      card_datas: [
        {
          icon: TotalCreditCostIcon,
          title: 'Total Credit Cost',
          value: Math.round(item.estimated_credits),
        },
        {
          icon: CategoriesChoosenIcon,
          title: 'Categories Chosen',
          value: '1',
        },
        {
          icon: CountriesAddedIcon,
          title: 'Countries Added',
          value: '1',
        },
        {
          icon: EcommerceIcon,
          title: 'E-Commerce Sites',
          value: item.ind_bundle_e_commerce.length.toString(),
        },
        {
          icon: BrandsIcon,
          title: 'Brands Selected',
          value: item.ind_bundle_brand.length.toString(),
        },
      ],
      table: {
        Category: item.ind_bundle_category,
        Country: item.ind_bundle_country,
        'E-Commerce Site': item.ind_bundle_e_commerce,
        Brand: item.ind_bundle_brand,
        'Configured Date': dateClnse(new Date(item.ind_bundle_config_date)),
        // Frequency_of_data: item.frequency,
        'Active / Inactive': <ActiveBundle i={item} />,
      },
    };
  });
};

export const modifyUserLandingTable = ({ d, ee, data }) => {
  if (d.length === 0) {
    return [{
      'E-Commerce Site': '',
      minimum_price: '',
      maximum_price: '',
      sentiment: '',
      Average_Rating: '',
      No_Of_Sellers: '',
      No_Of_Products: '',
    }];
  }

  const getSentiment = (rating) => {
    if (rating >= 4.5 && rating <= 5) return 'Excellent';
    if (rating >= 3.5 && rating < 4.5) return 'Very Good';
    if (rating >= 2.5 && rating < 3.5) return 'Good';
    if (rating >= 1.5 && rating < 2.5) return 'Bad';
    if (rating >= 1 && rating < 1.5) return 'Worst';
    return 'N/A';
  };


  return d.map((e, i) => ({
    'E-Commerce Site': e.site_name,
    minimum_price: e.min_price,
    maximum_price: e.max_price,
    sentiment: getSentiment(parseFloat(e.average_rating)),
    Average_Rating: e.average_rating &&
      (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Rating
          name='rating'
          value={parseFloat(e.average_rating)}
          precision={0.5}
          readOnly
          emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />}
          icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />}
        />
        &nbsp;({e.average_rating})
      </Box>),
    No_Of_Sellers: <Link to={`/user/brands?category_name=${encodeURIComponent(ee?.category?.category_name)}&brand_name=${encodeURIComponent(data?.brand_name)}&ind_bundle_id=${encodeURIComponent(ee?.ind_bundle_id)}&brand_id=${encodeURIComponent(data?.brand_id)}&site_id=${encodeURIComponent(e?.site_id)}&card=${encodeURIComponent(i)}&type=${encodeURIComponent(1)}`} style={{ color: '#4F46E5', textDecorationLine: 'underline' }}>{e?.seller_count}</Link>,
    No_Of_Products: <Link to={`/user/brands?category_name=${encodeURIComponent(ee?.category?.category_name)}&brand_name=${encodeURIComponent(data?.brand_name)}&ind_bundle_id=${encodeURIComponent(ee?.ind_bundle_id)}&brand_id=${encodeURIComponent(data?.brand_id)}&site_id=${encodeURIComponent(e?.site_id)}&card=${encodeURIComponent(i)}&type=${encodeURIComponent(0)}`} style={{ color: '#4F46E5', textDecorationLine: 'underline' }}>{e?.product_count}</Link>,
  }));
};


export const modifyAdminLandingTable = ({ d, btnD }) => {
  return d.length === 0 || (d.length === 1 && d[0].user_id === '') ?
    ([{
      user_id: '-',
      user_first_name: '-',
      user_email: '-',
      Name: null,
      user_role: null,
      joined_on: null,
      invite_status: null,
      user_status: null,
    }])
    :
    (d.map((e, i) => {
      const formattedJoinedOn = e.joined_on ? new Date(e.joined_on).toLocaleDateString() : '-';
      return {
        user_id: e.user_id,
        user_first_name: e.user_first_name,
        user_email: e.user_email,
        user_status_og: e.user_status,
        invite_status_og: e.invite_status,
        Name: (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
            <Box sx={{ height: "40px", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", background: "#474747", color: "#fff", fontSize: "20px", fontWeight: "bold", marginRight: "8px" }}>
              {e.user_first_name.charAt(0).toUpperCase()}
            </Box>
            <Box sx={{ textAlign: 'left' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#2B2B2B' }}>{e.user_first_name}</Typography>
              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2B2B2B', pb: 1 }}>{e.user_email}</Typography>
            </Box>
          </Box >
        ),
        user_role: e.user_role,
        joined_on: formattedJoinedOn,
        invite_status: e.invite_status === 'Pending' ? (<RoundedBtn col='#FF974C' txt={e.invite_status} />) : (<RoundedBtn col='#008000' txt={e.invite_status} />),
        user_status: e.user_status === 'Deactivate' ? (<RoundedBtn col='#FF974C' txt={e.user_status} />) : (<RoundedBtn col='#008000' txt={e.user_status} />),
        // '': <ThreeDot2 data={btnD} i={i} />
      }
    }))
}

export const modifyUserAllSellerTable = (d) => {
  if (d.length === 0) {
    return [{
      Seller_Name: '',
      No_Of_Products: '',
      'Reviews': '',
      'Ratings': '',
      '': '',
    }]
  }
  // console.log('d', d)
  return d?.map((e, i) => {
    // console.log('sell', e?.seller_ratings_count !== 0, e?.seller_ratings_count, e.seller_name)
    return {
      Seller_Name: e.seller_name,
      No_Of_Products: e.no_of_products,
      'Reviews': e.seller_reviews_count,
      'Ratings': e.seller_ratings ? (<Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Rating name='rating' value={parseFloat(e.seller_ratings)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />} icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />} />
        &nbsp;{e?.seller_ratings_count !== 0 ? `(${e?.seller_ratings_count})` : null}
      </Box>) : '0',
      '': (
        <Link to={`/user/seller?seller_id=${encodeURIComponent(e?.seller_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserAllProductTable = (d) => {
  if (d.length === 0) {
    return [{
      produce_name: '',
      No_Of_Sellers: '',
      'Max Price (₹)': '',
      'Min Price (₹)': '',
      'MRP (₹)': '',
      '': '',
    }]
  }
  return d.map((e, i) => {
    return {
      product_name: e.product_name,
      No_Of_Sellers: e.no_of_sellers,
      'Max Price (₹)': e.max_price,
      'Min Price (₹)': e.min_price,
      'MRP (₹)': e.mrp,
      '': (
        <Link to={`/user/product?product_id_seq=${encodeURIComponent(e?.product_id_seq)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserBrandProductTable = ({ d }) => {
  // console.log('fn', d)
  const btn = localStorage.getItem('user_role') === 'Admin User' ? false : true
  if (d.length === 0) {
    return [{
      produce_name: '',
      No_Of_Sellers: '',
      'Max Price (₹)': '',
      'Min Price (₹)': '',
      'MRP (₹)': '',
      ...(btn && {
        '': '',
      })
    }]
  }


  return d.map((e, i) => {
    return {
      produce_name: e.product_name,
      No_Of_Sellers: e.no_of_sellers,
      'Max Price (₹)': e.max_price,
      'Min Price (₹)': e.min_price || '-',
      'MRP (₹)': e.mrp,
      ...(btn && {
        '': (
          <Link to={`/user/product?product_id_seq=${encodeURIComponent(e?.product_id_seq)}`}>
            <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
          </Link>
        )
      }),
    }
  })
}

export const modifyUserBrandSellerTable = ({ d }) => {
  const btn = localStorage.getItem('user_role') === 'Admin User' ? false : true
  if (d.length === 0) {
    return [{
      seller_name: '',
      No_Of_Products: '',
      Reviews: '',
      Ratings: '',
      ...(btn && {
        '': '',
      })
    }]
  }

  return d.map((e, i) => {
    return {
      seller_name: e.seller_name,
      No_Of_Products: e.no_of_products,
      Reviews: e?.seller_reviews_count && e?.seller_reviews_count !== 0 ? (<Typography sx={{ color: 'inherit', fontSize: 'inherit', textAlign: 'inherit', fontWeight: 'inherit', textWrap: 'wrap', maxWidth: '500px' }}>{e?.seller_reviews_count}</Typography>) : null,
      Ratings: e?.seller_ratings ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}><Ratings prop={{ value: e.seller_ratings }} />{e?.seller_ratings_count && e?.seller_ratings_count !== 0 ? `(${e?.seller_ratings_count})` : ''}</Box>
      ) : '0',
      ...(btn && {
        '': (
          <Link to={`/user/seller?seller_id=${encodeURIComponent(e?.seller_id)}`}>
            <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
          </Link>
        ),
      }),
    }
  })
}

export const modifyUserSellerProductTable = (d) => {
  if (d.length === 0) {
    return [{
      brand: '',
      product_list: '',
      MRP: '',
      discount: '',
      final_price: '',
      Link: '',
    }]
  }

  return d.map((e, i) => {
    return {
      brand: e.brand_name,
      product_list: e.product_name,
      MRP: e.seller_price,
      discount: e.discount,
      final_price: e?.product_price,
      Link: (
        <Link to={`/user/product?product_id_seq=${encodeURIComponent(e?.product_id_seq)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserSellerEcormTable = (d) => {
  if (d.length === 0) {
    return [{
      'E-Commerce': '',
      Products: '',
      Total_Reviews: '',
      Total_Ratings: '',
    }]
  }

  return d.map((e, i) => {
    return {
      'E-Commerce': e.site_name,
      Products: e.no_of_products,
      Total_Reviews: e.total_reviews,
      Total_Ratings: e.total_rating && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}><Ratings prop={{ value: e.total_rating }} />({e?.rating_count})</Box>
      ),
    }
  })
}
export const modifyUserProductTable = (d) => {
  if (d.length === 0) {
    return [{
      Seller_Name: '',
      Price: '',
      Discount: '',
      Stock: '',
      Platform: '',
      Average_Rating: '',
    }]
  }

  return d.map((e, i) => {
    return {
      Seller_Name: e.seller_name,
      Price: e.seller_price,
      Discount: e.discount,
      Stock: e.stock,
      Platform: e.site_name,
      Average_Rating: e?.seller_ratings && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Ratings prop={{ value: e?.seller_ratings }} />
          {/* {e?.seller_ratings_count && `(${e?.seller_ratings_count})`} */}
        </Box>
      ),
      // Average_Rating: (
      //   <Box><Ratings prop={{ value: e.seller_ratings }} /></Box>
      // ),
    }
  })
}

export function base64ToBlob(base64, mime) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
}
export function extractBase64Data(base64String) {
  const base64Data = base64String.split(",")[1];
  return base64Data;
}
export const Ratings = ({ prop }) => {
  return (
    <Rating name="rating" value={parseFloat(prop?.value)} precision={0.1} readOnly emptyIcon={<StarIcon fontSize="small" style={{ color: '#00000033' }} />} icon={<StarIcon fontSize="small" style={{ color: '#F4A403' }} />} />
  );
}

export const parseProperly = (d) => {
  if (d === undefined || d === null) {
    return []
  } else {
    // return d;
    try {
      return JSON.parse(d);
    } catch (error) {
      console.error("Error parsing data:", error);
      return [];
    }

    // return JSON.parse(d)
  }
}

/**
 * Function to calculate the percentage difference between two numbers
 * @param {number} value1 - The first number
 * @param {number} value2 - The second number
 * @returns {number} - The percentage difference
 */
export const calculatePercentageDifference = (value1, value2) => {
  if (value1 === 0 && value2 === 0) {
    return 0; // No difference if both values are zero
  }

  const difference = Math.abs(value1 - value2);
  const average = (value1 + value2) / 2;
  const percentageDifference = (difference / average) * 100;

  return percentageDifference.toFixed(2);
  // return percentageDifference;
}

// export const NotificationFilter = (d) => {
//   return d?.map((e) => {
//     let cf = -1;
//     let st = ''
//     // if (e?.old_price < e?.current_price) {
//     //   cf = 0;
//     //   st = `New Product from ${e?.brand_name} !!!`
//     // } else if (e?.old_price > e?.current_price) {
//     //   cf = 1;
//     //   st = `New Product from ${e?.brand_name} !!!`
//     // } else if (e?.created_at === e?.updated_at || null === e?.updated_at) {
//     //   cf = 3;
//     //   st = `New Product from ${e?.brand_name} !!!`
//     // }
//     if (e?.price_status?.status === 'price_rocked') {
//       cf = 0;
//       st = `Price rocked by ${calculatePercentageDifference(e?.old_price, e?.current_price)}% for your subscribed product`
//     } else if (e?.price_status?.status === 'price_dropped') {
//       cf = 1
//       st = `${calculatePercentageDifference(e?.current_price, e?.old_price)}% Price droped for your subscribed product`
//       // st = `Price rocked by ${calculatePercentageDifference(e?.current_price, e?.current_price)}% for your subscribed product`
//     } else if (e?.seller_check?.new_seller) {
//       cf = 2
//       st = `New Seller Found !!!`
//     } else if (e?.new_product) {
//       cf = 2
//       st = `New Product from ${e?.brand_name} !!!`
//     }
//     console.log('cf', cf)
//     return {
//       ...e,
//       prodName: e?.product_name,
//       text: e?.brand_name,
//       subText: st,
//       // time: '30 Min Ago',
//       EcommName: e?.site_name,
//       Seller: e?.seller_name,
//       prodPrice: `₹ ${e?.price_status?.current_price}`,
//       time: timeAgo(cf === 3 ? e?.created_at : e?.updated_at),
//       categoryFilter: cf
//     }
//   });
// }

export const NotificationFilter = (notifications) => {
  return notifications?.sort((a, b) => new Date(b?.updated_at) - new Date(a?.updated_at))?.map((notification) => {
    let categoryFilter = -1;
    let notificationMessage = '';

    // Check price status and set category and message accordingly
    if (notification?.price_status?.status === 'price_rocked') {
      categoryFilter = 0;
      notificationMessage = `Price rocked by ${calculatePercentageDifference(notification?.old_price, notification?.current_price)}% for your subscribed product`;
    } else if (notification?.price_status?.status === 'price_dropped') {
      categoryFilter = 1;
      notificationMessage = `${calculatePercentageDifference(notification?.current_price, notification?.old_price)}% price dropped for your subscribed product`;
    } else if (notification?.seller_check?.new_seller) {
      categoryFilter = 2;
      notificationMessage = `New seller found!`;
    } else if (notification?.new_product) {
      categoryFilter = 2;
      notificationMessage = `New product from ${notification?.brand_name}!`;
    }

    // console.log('Category Filter:', categoryFilter);

    return {
      ...notification,
      prodName: notification?.product_name,
      text: notification?.brand_name,
      subText: notificationMessage,
      EcommName: notification?.site_name,
      Seller: notification?.seller_name,
      prodPrice: `₹ ${notification?.price_status?.current_price}`,
      time: timeAgo(categoryFilter === 3 ? notification?.created_at : notification?.updated_at),
      categoryFilter: categoryFilter
    };
  });
};
export const ProductNotification = (notifications) => {
  return notifications?.sort((a, b) => new Date(b?.updated_at) - new Date(a?.updated_at))?.map((notification) => {
    let categoryFilter = -1;
    let notificationTitle = '';
    let notificationMessage = '';
    let notificationIcon = null;

    // Check price status and set category and message accordingly
    if (notification?.price_status?.status === 'price_rocked') {
      categoryFilter = 0;
      notificationTitle = 'Price Rocketed ...🚀'
      notificationIcon = NotiIcons.PriceUp
      notificationMessage = `The price has been increased ${calculatePercentageDifference(notification?.old_price, notification?.current_price)}%.`;
    } else if (notification?.price_status?.status === 'price_dropped') {
      categoryFilter = 1;
      notificationTitle = 'Price Drop'
      notificationIcon = NotiIcons.PriceDrop
      notificationMessage = `${calculatePercentageDifference(notification?.current_price, notification?.old_price)}%  Price drop on your subscribed product. Grab it now!`;
    } else if (notification?.seller_check?.new_seller) {
      categoryFilter = 2;
      notificationTitle = 'New Seller'
      notificationIcon = NotiIcons.NewSeller
      notificationMessage = `Your subscribed product has a new seller, do check it now!!!`;
    }

    // console.log('Category Filter:', categoryFilter);

    return {
      ...notification,
      prodName: notification?.product_name,
      text: notification?.brand_name,
      subText: notificationMessage,
      EcommName: notification?.site_name,
      Seller: notification?.seller_name,
      prodPrice: `₹ ${notification?.price_status?.current_price}`,
      time: timeAgo(categoryFilter === 3 ? notification?.created_at : notification?.updated_at),
      categoryFilter: categoryFilter,
      img: notificationIcon,
      title: notificationTitle,
      description: notificationMessage,
      date: dateClnse(notification?.updated_at),
    };
  });
}

export const filterDataByTime = (d, time) => {
  const currentDate = new Date();
  const filteredData = d?.filter(entry => {
    const entryDate = new Date(entry.date);
    if (time === 0) {
      // Return all data
      return true;
    } else if (time === 1) {
      // Return data from the last month
      const lastMonth = new Date();
      lastMonth.setMonth(currentDate.getMonth() - 1);
      return entryDate >= lastMonth;
    } else if (time === 2) {
      // Return data from the last year
      const lastYear = new Date();
      lastYear.setFullYear(currentDate.getFullYear() - 1);
      return entryDate >= lastYear;
    }
  });

  return filteredData;
};

export const productWordCount = (reviews) => {
  const stopWords = ["i", "a", "am", "the", "is", "in", "on", "of", "for", "to", "and", "it", "this", "as", "so", "we", "had", "that", "with", "at", "by", "an", "be", "are", "from", "or", "not", "can", "if", "but", "your", "you", "my", "have", "was", "do", "does", "did", "all", "our", "will", "they", "them", "there", "their", "been", "being", "would", "could", "should", "because", "about", "us", "more", "very", "any", "these", "other", "some", "such", "than", "then", "just", "like"];
  const data = {};
  reviews?.forEach(review => {
    const words = review?.review?.replace(/[^\w\s]/g, '')?.split(' ')?.filter(word => !stopWords?.includes(word.toLowerCase()) && isNaN(word));
    words?.forEach((word) => {
      if (!data[word]) { data[word] = 1; } else { data[word]++; }
    });
  });

  const result = Object.keys(data).map((key) => {
    return { text: key, value: data[key] };
  });

  return result;
};
