import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Logo } from '../../../../assets/logo';
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import http from '../../../../utils/http-common';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Carousel_OG } from "../Components/Carousel";
import { PasswordModel } from "../Components/Model";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { PasswordSvg } from "../../../../utils/icon/LandingPage/Icon";
import { BtnLoad, countries } from "../../../../utils/data/LandingPage/Data";
import { Country } from "../../../../components/@extended/Country";
import { Analytics } from "../../../../Analytics";
import parsePhoneNumberFromString, { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";

export const Registeration = () => {
  const [btn, setBtn] = useState(true);
  const [load, setLoad] = useState(false);
  const btnTrue = {
    textTransform: "capitalize",
    color: "#fff",
    background: "#8A18D0",
    textWrap: "nowrap",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    borderRadius: "15px",
    width: "50%",
    "&:hover": {
      background: "#8A18D0",
    },
  };
  const btnFalse = {
    textTransform: "capitalize",
    color: "#000000",
    textWrap: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
    display: "flex",
    width: "50%",
  };
  const [fn, setFn] = useState("");
  const [ln, setLn] = useState("");
  // const [country, setCountry] = useState(countries[230] || []);
  const [country, setCountry] = useState({ label: "", code: "us" });
  const [ph, setPh] = useState("");
  const [phnChk, setPhnChk] = useState(null)
  const [phnValid, setPhnValid] = useState(null)
  const [cn, setCn] = useState("");
  const [prePop, setPrePop] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [isAgreed, setIsAgreed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch()

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Name validation
    if (!fn) {
      tempErrors.fn = "Name is required.";
      isValid = false;
    }

    // Country validation
    if (!country.label && !country.phone) {
      tempErrors.country = "Country is required.";
      isValid = false;
    }

    // Phone number validation
    if (!ph || phnValid || Number(phnChk) === 0) {
      tempErrors.ph = "A valid phone number is required.";
      isValid = false;
    }

    // Company name validation
    if (!cn && !btn) {
      tempErrors.cn = "Company name is required.";
      isValid = false;
    }

    // Email validation
    if (!email) {
      tempErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email address is invalid.";
      isValid = false;
    }

    // Password validation
    // const passwordErrors = [];
    // if (password.length < 8) {
    //   passwordErrors.push("Password must be at least 8 characters long.");
    // }
    // if (!/[A-Z]/.test(password)) {
    //   passwordErrors.push(
    //     "Password must include at least one uppercase letter."
    //   );
    // }
    // if (!/[a-z]/.test(password)) {
    //   passwordErrors.push(
    //     "Password must include at least one lowercase letter."
    //   );
    // }
    // if (!/[0-9]/.test(password)) {
    //   passwordErrors.push("Password must include at least one digit.");
    // }
    // if (!/[!@#$%^&*]/.test(password)) {
    //   passwordErrors.push(
    //     "Password must include at least one special character."
    //   );
    // }
    // if (passwordErrors.length > 0) {
    //   tempErrors.password = passwordErrors.join(" ");
    //   isValid = false;
    // }
    // Password validation
    if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      tempErrors.password = "Enter valid password";
      isValid = false;
    }
    if (
      !isAgreed
    ) {
      tempErrors.check = true;
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      Register();
    }
    //  else {
    //   setMessage("Please correct the highlighted errors.");
    //   setSeverity("error");
    //   setOpen(true);
    // }
  };

  const Register = async () => {
    try {
      setLoad(true)
      const userType = btn ? "individual" : "company";
      // const response = await http.post('/Registeration', JSON.stringify({
      const response = await http.post(
        "/auth_service/Registeration",
        JSON.stringify({
          user_first_name: fn,
          user_last_name: ln,
          user_country: country.label,
          user_phone_number: ph,
          user_company_name: cn,
          user_email: email,
          user_password: password,
          user_type: userType,
        })
      );
      setLoad(false)
      // console.log("res", response);

      if (response.status === 201) {
        localStorage.setItem("email", email);
        localStorage.setItem("otp", true);
        if (!btn) {
          localStorage.setItem("company", true);
        }
        setFn("");
        setLn("");
        setCountry("");
        setPh("");
        setCn("");
        setEmail("");
        setPassword("");
        setMessage(response.data.message);
        setSeverity("success");
        handleClick();
        // setExpiry(response.data.expires)
        // dispatch(changeExpiry(response.data.expires));
        navigate('/otp');
      }
    } catch (error) {
      setLoad(false)
      console.error("There was an error registering the user!", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Registration failed. Please try again."
      );
      setSeverity("error");
      handleClick();
    }
  };
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    if (params.get("name")) {
      setFn(params.get("name"));
    }
    if (params.get("company")) {
      setCn(params.get("company"));
      setBtn(false);
    }
    if (params.get("email")) {
      setEmail(params.get("email"));
    }
    if (params.get("name") && params.get("company") && params.get("email")) {
      setPrePop(true);
    }
    // return {
    //   firstName: params.get('firstName') || '',
    //   email: params.get('email') || '',
    //   phoneNumber: params.get('phoneNumber') || '',
    // };
  };

  useEffect(() => {
    getQueryParams(location.search);
  }, [location.search]);
  return (
    <Box sx={{ display: "flex", height: "100vh", scrollbarWidth: 'none', }}>
      <Box sx={{ width: '50%', height: '100vh', display: { xs: 'none', sm: 'flex' }, background: '#000000', borderTopRightRadius: '12px', borderBottomRightRadius: '12px', color: '#fff' }}>
        <Box sx={{ width: '100%', height: '100vh', display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', py: 4, px: 5, background: "radial-gradient(circle farthest-side, #2C2C2C, #010101)", borderTopRightRadius: 'inherit', borderBottomRightRadius: 'inherit', }}>
          <Link to={'/'}>
            <Logo />
          </Link>
          <Carousel_OG />
          <Analytics />
        </Box>
      </Box>
      <Box sx={{ width: { xs: "100%", sm: "50%" }, height: "100%", overflow: 'auto', scrollbarWidth: 'none', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", background: "#ffffff", color: "#474747", py: 2, }}>
        <FormControl sx={{ width: { xs: "100%", sm: "75%" }, my: { xs: "15px", sm: "0" }, px: { xs: 3, md: 5 }, }}>
          <Typography sx={{ fontWeight: "700", fontSize: "32px", display: "flex", my: "15px", }}>
            <Typography sx={{ fontWeight: "600", fontSize: "32px", background: "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", }}>
              Let's get you Registered{" "}
            </Typography>
            ✨
          </Typography>
          <Box sx={{ height: "34px", width: "100%", p: "4px", borderRadius: "20px", border: "1px solid #E4E4E4", background: "#fff", display: "flex", justifyContent: "space-between", }}>
            <Button disableRipple disabled={prePop} sx={btn ? btnTrue : btnFalse} onClick={() => setBtn(true)}>
              Individual User
            </Button>
            <Button disableRipple disabled={prePop} sx={!btn ? btnTrue : btnFalse} onClick={() => setBtn(false)}>
              {" "}
              Company User
            </Button>
          </Box>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, width: "100%", justifyContent: "space-between", my: { xs: "0", sm: "15px" }, }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", my: { xs: "15px", sm: "0" }, }}>
              <FormLabel>Name*</FormLabel>
              <TextField size="small" placeholder="Enter name" type="text" value={fn} disabled={prePop} required
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only alphabets and spaces
                  if (/^[a-zA-Z\s]*$/.test(value)) {
                    setFn(value);
                  }
                }}
                sx={{
                  color: "#AFAFAF", height: "35px", "& .MuiFormHelperText-root": { marginLeft: "0px", },
                }} error={!!errors.fn} helperText={errors.fn}></TextField>
            </Box>
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', sm: '46%' }, my: { xs: '15px', sm: '0' }, }}>
                              <FormLabel>Last name</FormLabel>
                              <TextField placeholder="Last Name" type="text" value={ln} onChange={(e) => setLn(e.target.value)} sx={{ color: '#AFAFAF', height: '43px' }}></TextField>
                          </Box> */}
          </Box>
          <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", my: { xs: "0", sm: "15px" }, }}>
            <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", sm: "46%" }, my: { xs: "15px", sm: "0" }, }}>
              <FormLabel>Country*</FormLabel>
              {/* <TextField size="small" placeholder="Enter country" type="text" value={country.label} required onChange={(e) => setCountry({ ...country, name: e.target.value })} sx={{ color: "#AFAFAF", height: "30px" }} error={!!errors.country} helperText={errors.country}></TextField> */}
              <Country
                country={country}
                setCountry={setCountry}
                required={true}
                sx={{
                  // color: "#AFAFAF",
                  height: "40px",
                  // border: "0.74px solid #CBD1D8",
                }}
                error={!!errors.country}
                helperText={errors.country}
              />
              {errors.country && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ mt: 1, fontSize: "12px" }}
                >
                  {errors.country}
                </Typography>
              )}

            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: { xs: "100%", sm: "46%" }, my: { xs: "20px", sm: "0" }, }}>
              <FormLabel>Phone*</FormLabel>
              {/* <TextField type="phone" value={ph} onChange={(e) => setPh(e.target.value)} sx={{ color: '#AFAFAF', height: '43px' }}></TextField> */}
              <PhoneInput country={country?.code?.toLowerCase() || 'us'} value={ph} onChange={(value, data, event, formattedValue) => {
                const ogphn = value.toString().substring(formattedValue.split(' ')[0].length - 1);
                const f = parsePhoneNumberFromString(String(event?.target?.value));
                const requiredLength = getExampleNumber(f?.country, examples)?.nationalNumber?.length;
                const currentLength = ogphn?.length;
                // console.log('phn', value, data, event, formattedValue,
                //   'split', formattedValue.split(' ')[0].length - 1,
                //   'filtered value', value.toString().substring(formattedValue.split(' ')[0].length - 1),
                //   Number(value.toString().substring(formattedValue.split(' ')[0].length - 1)) === 0,
                //   'phn check', value.toString().substring(formattedValue.split(' ')[0].length - 1).length,
                //   formattedValue);
                setPhnChk(value.toString().substring(formattedValue.split(' ')[0].length - 1));
                setPhnValid(requiredLength !== currentLength);
                // if (value.toString().substring(formattedValue.split(' ')[0].length - 1).length !== 0) {
                setPh(value)
                // }
              }} countryCodeEditable={false} containerStyle={{ height: "40px", padding: 0, borderColor: '#0000003b' }} inputStyle={{ width: "100%", height: "40px", color: "#000", border: `1px solid ${!errors.ph ? '#D0D5DD' : '#d32f2f'}`, background: '#fff' }} buttonStyle={{ background: "transparent" }} isValid={!errors.ph} inputProps={{ required: true, }} />
              {/* <PhoneInput country={country?.code?.toLowerCase() || 'us'} value={ph} onChange={(phone) => setPh(phone)} containerStyle={{ height: "40px", padding: 0, borderColor: '#0000003b' }} inputStyle={{ width: "100%", height: "40px", color: "#000", border: `1px solid ${!errors.ph ? '#D0D5DD' : '#d32f2f'}`, background: '#fff' }} buttonStyle={{ background: "transparent" }} isValid={!errors.ph} inputProps={{ required: true, }} /> */}
              {errors.ph && (
                <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: '12px' }}>
                  {errors.ph}
                </Typography>
              )}
            </Box>
          </Box>
          {/* <Box sx={{ display: "flex", flexDirection: "column", my: "5px", }}>
            <FormLabel>Company name{!btn && "*"}</FormLabel>
            <TextField size="small" placeholder="Enter company name" value={cn} onChange={(e) => setCn(e.target.value)} disabled={prePop} required type="text" sx={{ color: "#AFAFAF", height: "30px", "& .MuiFormHelperText-root": { marginLeft: "0px", } }} error={!!errors.cn} helperText={errors.cn}></TextField>
          </Box> */}
          <Box sx={{ display: "flex", flexDirection: "column", my: "5px", }}>
            <FormLabel>Company name{!btn && "*"}</FormLabel>
            <TextField
              size="small"
              placeholder="Enter company name"
              value={cn}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z0-9 ]*$/.test(value)) {
                  setCn(value);
                  setErrors(prev => ({ ...prev, cn: "" }));
                } else {
                  setErrors(prev => ({ ...prev, cn: "No special characters allowed." }));
                }
              }}
              disabled={prePop}
              required
              type="text"
              sx={{ color: "#AFAFAF", height: "30px", "& .MuiFormHelperText-root": { marginLeft: "0px", } }}
              error={!!errors.cn}
              helperText={errors.cn}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", my: "17px", mt: 3, }}>
            <FormLabel
              sx={{ fontWeight: '500', fontSize: '14px', color: '#474747' }}>
              Email ID
              <span style={{ position: "relative", top: "-3px", }}>*</span>
            </FormLabel>
            <TextField size="small" placeholder="Enter Email ID" value={email} onChange={(e) => setEmail(e.target.value)} required type="email" disabled={prePop} sx={{ color: "#AFAFAF", height: "30px", "& .MuiFormHelperText-root": { marginLeft: "0px", } }} error={!!errors.email} helperText={errors.email}></TextField>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", my: "15px" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: "5px" }}>
              <FormLabel sx={{ flexGrow: 1 }}>Password*</FormLabel>
              <Box sx={{ display: "flex", alignItems: "center", position: "relative", "&:hover .model-1": { display: "block", }, }}>
                <PasswordSvg />
                <Box sx={{ display: "none", position: "absolute", right: 0, top: "100%", zIndex: 1, }} className="model-1">
                  <PasswordModel />
                </Box>
              </Box>
            </Box>

            {/* <TextField
              size="small"
              placeholder="Enter password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ color: "#AFAFAF", height: "30px" }}
              error={!!errors.password}
              helperText={errors.password || ""}
            /> */}
            <TextField placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} error={!!errors.password} sx={{ my: 1, "& input": { height: "10px", }, }} type={showPassword1 ? "text" : "password"}
              InputProps={{
                endAdornment: (<InputAdornment position="end"><IconButton aria-label="toggle password visibility" onClick={() => setShowPassword1(!showPassword1)} edge="end">
                  {showPassword1 ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
                </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <Typography variant="caption" color="error">
                {errors.password}
              </Typography>
            )}

            {/* <OutlinedInput
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              // helperText={errors.password}
              sx={{
                my: 1,
                "& input": {
                  height: "10px",
                },
              }}
              type={showPassword1 ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword1(!showPassword1)}
                    edge="end"
                  >
                    {showPassword1 ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <Typography variant="caption" color="error">
                {errors.password}
              </Typography>
            )} */}
          </Box>
          <Box sx={{ display: "flex", mb: "5px", mt: "0px", alignItems: "center", }}>
            <input type="checkbox" style={{ marginRight: "15px", marginLeft: "5px", transform: "scale(1.5)", cursor: "pointer", marginTop: "-7px", color: errors.check && '#d32f2f', borderColor: errors.check && '#d32f2f', background: errors.check && '#d32f2f', }} checked={isAgreed} onChange={(e) => setIsAgreed(e.target.checked)} />
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              By clicking, you are confirming that you read, understood, and
              agree to the{" "}
              <Link to={"/terms-and-condition"} target="_blank" rel="noopener noreferrer" style={{ color: "#0000FF", fontSize: "12px" }}>
                Terms & Conditions
              </Link>
              *
            </Typography>
          </Box>

          <Box sx={{ position: "relative", width: "100%", display: "flex", flexDirection: "column", }}>
            <Button sx={{ color: "#fff", background: "#8A18D0", height: "40px", textTransform: "capitalize", my: "20px", "&:hover": { background: "#B37FFC" }, }}
              onClick={handleSubmit}
              disabled={!isAgreed}
            >
              Register
              <Box>
                {load && (
                  <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                    <Box sx={BtnLoad} />
                  </Box>
                )}
              </Box>
            </Button>

          </Box>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity={severity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <Typography
            sx={{
              color: "#0F1419",
              textAlign: "center",
              fontWeight: "400",
              fontSize: "12px",
            }}
          >
            Already have an account?{" "}
            <Link to={'/login'} style={{ fontWeight: "600", fontSize: "12px" }}>
              Login
            </Link>
          </Typography>
        </FormControl>
      </Box>
    </Box>
  );
};
