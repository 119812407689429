import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Graph from "../../../../../assets/Graph.gif";
import GraphBG from "../../../../../assets/grpBg.png";
import Navigate_The_World_Of_Ecommerce from "../components/Navigate_The_World_Of_Ecommerce";
import { scroller } from "react-scroll";

const FirstPage = () => {
  return (
    <Box
      sx={{
        pt: { xs: 6, md: 4 },
        // mb: { xs: 2 },
        minHeight: "100vh",
        width: "100%",
        background: "radial-gradient(circle farthest-side, #2C2C2C, #010101)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "97%", md: "40%" },
            textAlign: { xs: "center", md: "left" },
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "38px", md: "55px" },
              userSelect: "none",
              py: "10px",
              fontWeight: "700",
              whiteSpace: { xs: 'wrap', md: "nowrap" }
            }}
          >
            Craft Your
            <Typography
              component="span"
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                ml: "10px",
                fontSize: "inherit",
                fontWeight: "700",
                whiteSpace: { xs: 'wrap', md: "nowrap" }
              }}
            >
              Brand
            </Typography>
            <Typography sx={{ display: "block" }} />
            Brilliance in
            <Typography
              component="span"
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                ml: "10px",
                fontSize: "inherit",
                fontWeight: "700",
                whiteSpace: { xs: 'wrap', md: "nowrap" }
              }}
            >
              Digital
            </Typography>
            <Typography sx={{ display: "block" }} />
            World
          </Typography>
          <Typography
            sx={{
              py: "10px",
              userSelect: "none",
              fontSize: { xs: "16px", md: "18px" },
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none"

            }}
          >
            Ecommerce Data Analytics is important for optimizing your online store’s performance. Advanced E-commerce Data Analysis helps companies to have a thorough understanding of consumer behaviour, sales trends, inventory control, and customer behaviour, so guiding better decisions.
          </Typography>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "nowrap",
              py: "10px",
              justifyContent: { xs: "center", md: "" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }} to={'/reg'}>
              <Button
                sx={{
                  color: "#fff",
                  background: "#8A18D0",
                  fontSize: "14px",
                  fontWeight: "700",
                  height: "52px",
                  width: "160px",
                  mx: "10px",
                  my: "10px",
                  textTransform: "capitalize",
                  "&:hover": { background: "#8a18d099" },
                }}
              >Get Started
              </Button>
            </Link>
            <Link
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo('main', { smooth: true, duration: 1500 });
                }, 50);
              }} to={"/contact"}>
              <Button
                sx={{
                  color: "inherit",
                  fontSize: "14px",
                  fontWeight: "500",
                  height: "52px",
                  width: { xs: '160px', md: "147px" },
                  border: "1px solid #fff",
                  mx: "10px",
                  textTransform: "capitalize",
                  my: "10px",
                }}
              >Enquire more
              </Button>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{ display: { xs: "none", md: "block" }, width: { md: "50%" } }}
        >
          <Box
            sx={{
              borderRadius: "25px",
              display: "flex",
              justifyContent: "end",
              alignItems: "flex-start",
              backgroundSize: "cover",
              height: "518px",
              width: "auto",
              backgroundPosition: "center",
              backgroundImage: `url(${GraphBG})`,
            }}
          >
            <Box
              sx={{
                borderBottom: "15px solid #000000",
                borderLeft: "15px solid #000000",
                background:'#000',
                borderBottomLeftRadius: "16px",
                // borderBottomLeftRadius: "45px",
                // borderTopLeftRadius: "55px",
                // borderBottomRightRadius: "25px",
              }}
            >
              <img
                alt=""
                loading="lazy"
                className="h-[235px] w-auto"
                style={{ borderRadius: "18px" }}
                src={Graph}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          color: "#5F5F5F",
          userSelect: "none",
          fontSize: { xs: "20px", md: "24px" },
          fontWeight: "700",
          py: { xs: "2rem", md: "4rem" },
          pt: { xs: "4rem", md: "8rem" },
        }}
      >
        Navigate The World Of Ecommerce
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Navigate_The_World_Of_Ecommerce />
      </Box>
    </Box>
  );
};

export default FirstPage;
