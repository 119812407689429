import { useEffect, useState } from "react";
import http from "../../../../../utils/http-common";
import {
  Box,
  Button,
  FormLabel,
  TextField,
  Typography,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import {
  ConfigStyleBar2,
  data,
} from "../../../../../utils/data/IndividualDashboard/Data";
import {
  CustomDatePicker,
  DateField,
} from "../../../../../components/Date-Picker/CustomDatePicker";
import { Tick } from "../../../../../utils/icon/IndividualPages/Icon";
import { TableComp6 } from "../../../../../components/table/type - 6";
import { generateTableData } from ".";
import { dateClnse2, dateClnse3 } from "../../../../../components/Public/DateClnse";
import { TableComp5 } from "../../../../../components/table/type - 5";
import { DownloadCSV } from "../../../../../components/Public/DownloadCSV";
import dayjs from "dayjs";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearBundle, updateBundle } from "../../../../../slice/bundle";
import DateRangePicker from "../../../../../components/Date-Picker/DatePicker";
import { useNavigate } from "react-router-dom";
import { DataG_T_1 } from "../../../../../components/table/Data_Grid/type - 1";
import { OrdinaryTable1 } from "../../../../../components/table/Ordinary Table/type - 1";
import SettingsIcon from "@mui/icons-material/Settings";
import { Model_1 } from "../../../../../components/@extended/Model";
import { Header } from "./Config-Components/Header";
import { Selection } from "./Config-Components/Selection";
import { Frequency } from "./Config-Components/Frequency";
import { Footer } from "./Config-Components/Footer";
import { Preview } from "./Config-Components/Preview";
import { DataNotFound } from "./Config-Components/DataNotFound";
import { ProperDate2 } from "../../../../../components/@extended/TimeRemaining";

export const Configuration = ({
  selectedData,
  setSelectedData,
  minimize,
  setMinimize,
  state,
  setState,
  addElement,
  removeElement,
  clearElement,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [ec, setEC] = useState(null);
  const [cal, setCal] = useState(false);
  const [dnf, setDnf] = useState(false);
  const [country_id, setCountry_id] = useState(0);
  const [name, setName] = useState("");
  const [category_id, setCategory_id] = useState(0);
  const [ecommerceSites_id, setEcommerceSites_id] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [brand_id, setBrand_id] = useState([]);
  const [bundleId, setBundleId] = useState(null);
  const [tbl, setTbl] = useState({
    Category: "",
    Country: "",
    "E-Commerce Site": [],
    Brand: [],
    "Configured Date": "",
  });
  const [effect, setEffect] = useState({
    category: true,
    country: true,
    ecommerceSites: true,
    brand: true,
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [responseData, setResponseData] = useState({
    category: [],
    category_id: [],
    country: [],
    country_id: [],
    ecommerceSites: [],
    ecommerceSites_id: [],
    brand_id: [],
    brand: [],
  });
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenS(false);
  };

  const handleDateChange = (newValue, index) => {
    // const formattedValue = newValue.map((date) => {
    //   if (date) {
    //     return dayjs(date).toISOString();
    //   }
    //   return null;
    // });
    // setDateRange(formattedValue);
    // console.log('newV', newValue, formattedValue, dateRange)
    const updatedDateRange = [...dateRange];
    updatedDateRange[index] = newValue;
    setDateRange(updatedDateRange);
  };
  const handleButtonClick = (e, arrayName) => {
    if (selectedData[arrayName].includes(e)) {
      removeElement(e, arrayName);
    } else {
      addElement(e, arrayName);
      console.log("selectedData", selectedData);
    }
  };
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
    //setValue: setFormValues,
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const Data_Fetching_ECS = async () => {
    try {
      console.log("1");
      const send = {
        country_id: country_id,
        category_id: category_id,
      };
      const EcommerceResponse = await http.post(
        "/master_service/ecommerce/filter",
        send
      );
      console.log("2", EcommerceResponse, EcommerceResponse.data);
      const refinedData = {
        ecommerce: EcommerceResponse.data
          .sort((a, b) => a.site_name.localeCompare(b.site_name))
          .map((e) => ({ name: e.site_name, available: e.available.includes(true) })),
        ecommerce_id: EcommerceResponse.data
          .sort((a, b) => a.site_name.localeCompare(b.site_name))
          .map((e) => e.site_id),
      };
      if (EcommerceResponse.data.length === 0) {
        setDnf(true);
      }
      console.log("response", refinedData.ecommerce);
      console.log("3");
      setResponseData({
        ...responseData,
        ecommerceSites: refinedData.ecommerce,
        ecommerceSites_id: refinedData.ecommerce_id,
      });
      console.log("responseData", responseData);
      setEffect({ ...effect, ecommerceSites: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      setDnf(true);
      console.log("4");
    }
  };

  const Data_Fetching_B = async () => {
    try {
      const send = {
        country_id: country_id,
        category_id: category_id,
        site_ids: ecommerceSites_id,
      };
      const BrandResponse = await http.post(
        "/master_service/category/brand/filter",
        send
      );
      console.log("BrandResponse", BrandResponse);
      const refinedData = {
        brand: BrandResponse.data
          .sort((a, b) => a.brand_name.localeCompare(b.brand_name))
          .map((e) => ({ name: e.brand_name, available: e.available })),
        brand_id: BrandResponse.data
          .sort((a, b) => a.brand_name.localeCompare(b.brand_name))
          .map((e) => e.brand_id),
      };
      console.log("response", refinedData.brand);
      setResponseData({
        ...responseData,
        brand: refinedData.brand,
        brand_id: refinedData.brand_id,
      });
      if (BrandResponse.data.length === 0) {
        setDnf(true);
      }
      console.log("responseData", responseData);
      setEffect({ ...effect, brand: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      setDnf(true);
    }
  };

  const Data_Fetching = async () => {
    try {
      console.log("1");
      const [categoryResponse, CountryResponse] = await Promise.all([
        http.get("/master_service/category_master"),
        http.get("/master_service/country_master"),
        // http.get('/master_service/powertools_ecommerce_sites'),
        // http.get('/master_service/india_brand_master')
      ]);
      console.log("2");
      const refinedData = {
        category: categoryResponse.data.data
          .sort((a, b) => a.category_name.localeCompare(b.category_name))
          .map((e) => ({ name: e.category_name, available: e.available })),
        category_id: categoryResponse.data.data
          .sort((a, b) => a.category_name.localeCompare(b.category_name))
          .map((e) => e.category_id),
        country: CountryResponse.data.data
          .filter((e) => e.country_name !== 'WorldWide')
          .sort((a, b) => a.country_name.localeCompare(b.country_name))
          .map((e) => ({ name: e.country_name, available: e.available })),
        country_id: CountryResponse.data.data
          .filter((e) => e.country_name !== 'WorldWide')
          .sort((a, b) => a.country_name.localeCompare(b.country_name))
          .map((e) => e.country_id),
        // ecommerce: EcommerceResponse.data.data.sort((a, b) => a.site_name.localeCompare(b.site_name)).map(e => e.site_name),
        // brand: BrandResponse.data.data.sort((a, b) => a.brand_name.localeCompare(b.brand_name)).map(e => e.brand_name)
      };
      console.log(
        "response",
        refinedData.category,
        refinedData.country,
        refinedData
      );
      console.log("3");
      setResponseData({
        ...responseData,
        category: refinedData.category,
        category_id: refinedData.category_id,
        country: refinedData.country,
        country_id: refinedData.country_id,
        // ecommerceSites: refinedData.ecommerce,
        // brand: refinedData.brand
      });
      console.log("responseData", responseData);
      setEffect({ ...effect, category: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      setDnf(true);
      console.log("4");
    }
  };

  const Bundle_Creation = async () => {
    try {
      // console.log('1')
      const sec =
        "8cf441d7c6a7394c46be9a7502f5a10194406d826383054ea04b0c9a33397567";
      const payload_data = {
        ind_bundle_name: name,
        ind_bundle_country: selectedData.country,
        ind_bundle_e_commerce: selectedData.ecommerceSites,
        ind_bundle_brand: selectedData.brand,
        ind_bundle_country_id: country_id,
        ind_bundle_e_commerce_id: ecommerceSites_id,
        ind_bundle_category_id: category_id,
        ind_bundle_brand_id: brand_id,
        ind_bundle_config_date: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        ind_bundle_start_date: ProperDate2(dateRange.startDate),
        ind_bundle_end_date: ProperDate2(dateRange.endDate),
        // ind_bundle_start_date: dateRange.startDate,
        // ind_bundle_end_date: dateRange.endDate,
        // ind_bundle_start_date: dateRange[0],
        // ind_bundle_end_date: dateRange[1],
        ind_bundle_records: "2",
        ind_bundle_cost: "2000000",
        ind_bundle_status: "1",
        estimated_credits: ec,
        ind_bundle_category: selectedData.category,
        user_id: localStorage.getItem("user_id"),
      };
      const res = await http.post(
        "/master_service/individual/createbundle",
        payload_data
      );
      console.log("res", res);
      console.log("2");
      // setBundleData(res.data);
      // dispatch(updateBundle(res.data));
      // navigate("/dashboard/individual-dashboard/bundle-creation");
      // setBundleData(res.data);
      dispatch(updateBundle(res.data));
      setOpenS(true);
      setMessage(res.data.message);
      setSeverity("success");
      handleClick();

      // Navigate after showing the popup
      setTimeout(() => {
        navigate("/dashboard/individual-dashboard/bundle-creation");
        setOpenS(false); // Optionally hide the popup after navigation
      }, 500); // Delay before navigation

      // localStorage.setItem("res_datas", JSON.stringify(res.data));
      // window.location.href = "/dashboard/individual-dashboard/bundle-creation";
      // localStorage.setItem('res_datas', CryptoJS.AES.encrypt(JSON.stringify(res), sec))
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log("4");
      setOpen(false);
      setState(4);
      setMessage(
        error?.response ? error?.response?.data?.message : "Error creating bundle."
      );
      console.log("error respon", error.response, error?.response?.data?.message);
      setSeverity("error");
      handleClick();
    }
  };

  const [Esti, setEsti] = useState({
    rows: 0,
    columns: 0,
    estimated_credit: 0,
  });
  const EstimatedCredits = async () => {
    try {
      console.log("dat007", dateRange, dateRange.startDate)
      const sd = `${new Date(dateRange.startDate).getFullYear()}-${String(
          new Date(dateRange.startDate).getMonth() + 1
        ).padStart(2, "0")}-${String(new Date(dateRange.startDate).getDate()).padStart(2, "0")}`
        const ed = `${new Date(dateRange.endDate).getFullYear()}-${String(
          new Date(dateRange.endDate).getMonth() + 1
        ).padStart(2, "0")}-${String(new Date(dateRange.endDate).getDate()).padStart(2, "0")}`
      // console.log('1')
      const payload_data = {
        ind_bundle_name: name,
        ind_bundle_country: selectedData.country,
        ind_bundle_e_commerce: selectedData.ecommerceSites,
        ind_bundle_brand: selectedData.brand,
        country_id: country_id,
        site_id: ecommerceSites_id,
        category_id: category_id,
        brand_id: brand_id,
        ind_bundle_config_date: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        from_date: ProperDate2(dateRange.startDate),
        to_date: ProperDate2(dateRange.endDate),
        // from_date: sd,
        // to_date: ed,
        // from_date: dateRange.startDate,
        // to_date: dateRange.endDate,
        // from_date: dateRange[0],
        // to_date: dateRange[1],
        ind_bundle_records: "2",
        ind_bundle_cost: "2000000",
        ind_bundle_status: "1",
        ind_bundle_category: selectedData.category,
        user_id: localStorage.getItem("user_id"),
      };
      const res = await http.post(
        "/master_service/calculate_bundle",
        payload_data
      );
      console.log("res and dat", dateRange, dateRange.startDate, res, res.data);
      setEC(res.data.estimated_credit);
      console.log("22222222222222");
      setEsti(res.data);

      if (res.data.estimated_credit === 0) {
        setOpenS(true);
        setMessage('Data not found');
        // setMessage(res.data.message);
        setSeverity("warning");
      }
      else if (res.data.estimated_credit !== 0) {
        setCal(true);
      } else {
        setCal(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log("4");
      // setOpenS(false);
      // setMessage(res.data.error);
      // setSeverity("false");
    }
  };

  // const startDate = useWatch({
  //   control,
  //   name: 'start_date',
  // });

  // const endDate = useWatch({
  //   control,
  //   name: 'end_date',
  // });

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     // Trigger API request here
  //     EstimatedCredits()
  //   }
  // }, [startDate, endDate]);
  const Bundle_Updation = async () => {
    try {
      // console.log('1')
      const sec =
        "8cf441d7c6a7394c46be9a7502f5a10194406d826383054ea04b0c9a33397567";
      const payload_data = {
        ind_bundle_name: name,
        ind_bundle_country: selectedData.country,
        ind_bundle_e_commerce: selectedData.ecommerceSites,
        ind_bundle_brand: selectedData.brand,
        ind_bundle_country_id: country_id,
        ind_bundle_e_commerce_id: ecommerceSites_id,
        ind_bundle_category_id: category_id,
        ind_bundle_brand_id: brand_id,
        ind_bundle_config_date: `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        ind_bundle_start_date: dateRange.startDate,
        ind_bundle_end_date: dateRange.endDate,
        // ind_bundle_start_date: dateRange[0],
        // ind_bundle_end_date: dateRange[1],
        ind_bundle_records: "2",
        ind_bundle_cost: "2000000",
        ind_bundle_status: "1",
        ind_bundle_category: selectedData.category,
        estimated_credits: ec,
        user_id: localStorage.getItem("user_id"),
        ind_bundle_id: bundleId,
      };
      const res = await http.post("/master_service/bundle/edit", payload_data);
      console.log("res", res);
      console.log("2");
      // setBundleData(res.data);
      dispatch(updateBundle(res.data));
      setOpenS(true);
      setMessage(res.data.message);
      setSeverity("success");
      handleClick();
      setTimeout(() => {
        navigate("/dashboard/individual-dashboard/bundle-creation");
        setOpenS(false); // Optionally hide the popup after navigation
      }, 500); // Delay before navigation

      // localStorage.setItem("res_datas", JSON.stringify(res.data));
      // window.location.href = "/dashboard/individual-dashboard/bundle-creation";
      // localStorage.setItem('res_datas', CryptoJS.AES.encrypt(JSON.stringify(res), sec))
    } catch (error) {
      console.error("Error fetching data:", error);
      console.log("4");
      setOpen(false);
      setState(4);
      setMessage(
        error.response ? error.response.data.message : "Error updating bundle."
      );
      console.log("error respon", error.response, error.response.data.message);
      setSeverity("error");
      handleClick();
    }
  };

  const Sample_Data = async () => {
    try {
      // const f = dateRange[0];
      const f = dateClnse2(dateRange.startDate);
      // const f = dateClnse2(dateRange[0]);
      // const t = dateRange[1];
      const t = dateClnse2(dateRange.endDate);
      // const t = dateClnse2(dateRange[1]);
      console.log("ft", f, t);
      const payload = {
        category_id: category_id,
        site_id: ecommerceSites_id,
        brand_id: brand_id,
        country_id: country_id,
        from_date: f,
        to_date: t,
      };
      const response = await http.post(
        "master_service/sample/bundle/data",
        payload
      );
      setBundleData(response.data.data);
      if (
        response.data.data.product_id === "-" ||
        response.data.message === "No sellers found for the given criteria."
      ) {
        setDnf(true);
      }
      console.log(
        "res smpl data",
        response
        // response.data.payload.ind_bundle_category
      );
      // setTbl({
      //   Category: response.data.payload.ind_bundle_category,
      //   Country: response.data.payload.ind_bundle_country,
      //   "E-Commerce Site": response.data.payload.ind_bundle_e_commerce,
      //   Brand: response.data.payload.ind_bundle_brand,
      //   "Configured Date": response.data.payload.ind_bundle_config_date,
      // });

      // localStorage.setItem('sample_datas', JSON.stringify(response.data))
      // window.location.href = '/dashboard/individual-dashboard/sample-data'
    } catch (error) {
      console.error("Error fetching sample data:", error);
      setDnf(true);
    }
  };

  const BtnAction = (i) => {
    if (state === 0) {
      setCategory_id(responseData.category_id[i].toString());
    } else if (state === 1) {
      setCountry_id(responseData.country_id[i].toString());
    } else if (state === 2) {
      if (
        !ecommerceSites_id.includes(
          responseData.ecommerceSites_id[i].toString()
        )
      ) {
        setEcommerceSites_id((prevEcommerceSites_id) => [
          ...prevEcommerceSites_id,
          responseData.ecommerceSites_id[i].toString(),
        ]);
      } else {
        setEcommerceSites_id((prevEcommerceSites_id) =>
          prevEcommerceSites_id.filter(
            (id) => id !== responseData.ecommerceSites_id[i].toString()
          )
        );
      }
    } else if (state === 3) {
      if (!brand_id.includes(responseData.brand_id[i])) {
        setBrand_id((prevBrand_id) => [
          ...prevBrand_id,
          responseData.brand_id[i].toString(),
        ]);
      } else {
        setBrand_id((prevBrand_id) =>
          prevBrand_id.filter(
            (id) => id !== responseData.brand_id[i].toString()
          )
        );
      }
    }
  };

  const ModelData2 = {
    title: "Bundle Creation",
    emoji: "",
    txt: ["Are you sure, you want to create the bundle?"],
    cl: "",
    btn_1: "Cancel",
    btn_1_cancel: true,
    btn_1_Link: "",
    btn_2: "Create Bundle",
    btn_2_cancel: "",
    // btn_2_Link: '/dashboard/individual-dashboard/bundle-creation',
    btn_2_fn: Bundle_Creation,
  };

  const ModelData = {
    title: bundleId ? "Update Bundle" : "Bundle Creation",
    emoji: "",
    txt: bundleId
      ? ["Are you sure, you want to update the bundle?"]
      : ["Are you sure, you want to create the bundle?"],
    cl: "",
    btn_1: "Cancel",
    btn_1_cancel: true,
    btn_1_Link: "",
    btn_2: bundleId ? "Update Bundle" : "Create Bundle",
    btn_2_cancel: "",
    // btn_2_Link: '/dashboard/individual-dashboard/bundle-creation',
    btn_2_fn: bundleId ? Bundle_Updation : Bundle_Creation,
  };

  const dispatch = useDispatch();
  const bundle = useSelector((state) => state.bundles.bundle);
  // console.log('bundle', bundle)

  useEffect(() => {
    console.log("bundle ue", bundle);
    if (bundle && bundle.SelectData) {
      console.log(
        "bundle",
        bundle.SelectData.brand_id,
        bundle,
        bundle.SelectData
      );
      Data_Fetching();
      setState(bundle.state);
      setSelectedData({
        category: bundle.SelectData.category,
        country: bundle.SelectData.country,
        ecommerceSites: bundle.SelectData.ecommerceSites,
        brand: bundle.SelectData.brand,
      });
      setCountry_id(bundle.SelectData.country_id);
      setBrand_id(bundle.SelectData.brand_id);
      setCategory_id(bundle.SelectData.category_id);
      setEcommerceSites_id(bundle.SelectData.ecommerceSites_id);
      setName(bundle.bundleName);
      setDateRange(bundle.daterange);
      setBundleId(bundle.ind_bundle_id);
      // handleDateChange(bundle.daterange);

      // Clear the bundle data after using it
      dispatch(clearBundle());
    }

    if (state === 0 && effect.category) {
      Data_Fetching();
    } else if (state === 2 && effect.ecommerceSites) {
      Data_Fetching_ECS();
    } else if (state === 3 && effect.brand) {
      Data_Fetching_B();
    }
  }, [bundle, dispatch, state, effect]);
  const btnStyle = {
    background: "#8A18D0",
    color: "#fff",
    px: 4,
    textTransform: "capitalize",
    "&:hover": { background: "#B37FFC" },
  };

  const handleNext = async () => {
    if (validateCurrentStep()) {
      setState(state + 1);
      if (state === 1) Data_Fetching_ECS();
      else if (state === 2) Data_Fetching_B();
      // else if (state === 3) EstimatedCredits();
      else if (state === 4) await Sample_Data();
    }
  };

  const handleBack = () => {
    clearCurrentStepData();
    setState(state - 1);
    if (dnf) setDnf(false);
  };

  const validateCurrentStep = () => {
    if (state <= 3) {
      const currentArrayName = data[state].arrayName;
      return selectedData[currentArrayName]?.length > 0;
    } else if (state === 4) {
      return (
        name.trim() !== "" &&
        dateRange.startDate !== null &&
        dateRange.endDate !== null &&
        cal
        // name.trim() !== "" && dateRange.every((date) => date !== null) && cal
      );
    }
    return true;
  };

  const clearCurrentStepData = () => {
    if (state <= 3) {
      const currentArrayName = data[state].arrayName;
      clearElement(currentArrayName);
    } else if (state === 4) {
      setName("");
      setDateRange([null, null]);
      setEsti({ rows: 0, columns: 0, estimated_credit: 0 });
      setCal(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "590px",
        width: "100%",
        height: "90%",
        borderRadius: "8px",
        py: 3,
      }}
    >
      {/* Header Section */}
      <Header
        prop={{
          data: data,
          state: state,
          minimize: minimize,
          setMinimize: setMinimize,
          bundleData: bundleData,
          bName: name,
          btnStyle: btnStyle,
        }}
      />

      <Model_1 open={open} setOpen={setOpen} data={ModelData} />

      {/* Step-specific Sections */}

      {dnf ? (
        <DataNotFound />
      ) : (
        <Box>
          {state <= 3 && (
            <Selection
              prop={{
                data: data,
                state: state,
                responseData: responseData,
                selectedData: selectedData,
                handleButtonClick: handleButtonClick,
                BtnAction: BtnAction,
              }}
            />
          )}
          {state === 4 && (
            <Frequency
              prop={{
                name: name,
                setName: setName,
                dateRange: dateRange,
                setDateRange: setDateRange,
                setCal: setCal,
                EstimatedCredits: EstimatedCredits,
                setEsti: setEsti,
                Esti: Esti,
              }}
            />
          )}

          {state === 5 && <Preview prop={{ bundleData: bundleData }} />}
        </Box>
      )}
      <Footer
        prop={{
          state: state,
          data: data,
          setOpen: setOpen,
          handleBack: handleBack,
          handleNext: handleNext,
          validateCurrentStep: validateCurrentStep,
          bundleId: bundleId,
        }}
      />
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ position: "absolute", mt: "38px" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
