import { createSlice } from '@reduxjs/toolkit';

export const snackPopSlice = createSlice({
    name: 'snackPop',
    initialState: {
        snackPop: { open: false, severity: 'success', message: '' },
    },
    reducers: {
        handleClose: (state, action) => {
            // console.log('Redux updateBundle action:', state, action);
            state.snackPop.open = false;
        },
        handleClick: (state, action) => {
            // console.log('Redux updateBundle action:', state, action);
            state.snackPop.open = true;
        },
        setSeverity: (state, action) => {
            state.snackPop.severity = action.payload;
        },
        setMessage: (state, action) => {
            state.snackPop.message = action.payload;
        },
    },
});

// Export the actions
export const { handleClose, handleClick, setSeverity, setMessage } = snackPopSlice.actions;

// Export the reducer
export default snackPopSlice.reducer;
