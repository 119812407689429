import { Box, Typography, FormLabel, TextField, Button } from "@mui/material";
import { Textarea } from "../../../../../components/@extended/Textarea";
import { useState } from "react";
import { Model_1 } from "../../../../../components/@extended/Model";
import http from "../../../../../utils/http-common";

export const Custom_Plan = () => {
  const [users, setUsers] = useState("");
  const [credits, setCredits] = useState("");
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({
    title: "Custom Plan",
    emoji: "",
    txt: [
      "We have received your Request.",
      "Our team will review and mail you with in 48 hours.",
    ],
    cl: "",
    // btn_1: "Dashboard",
    // btn_1_cancel: true,
    // btn_1_Link: "/admin",
    btn_2: "Dashboard",
    // btn_2_cancel: true,
    btn_2_Link: "/admin",
    // btn_2_fn: () => setCp(true),
  });
  const Clear = () => {
    setUsers(""), setCredits(""), setDescription(""); setErrors({});
  };
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Ticket type validation
    // if (!users) {
    //   tempErrors.users = "Users is required.";
    //   isValid = false;
    // }

    // if (!credits) {
    //   tempErrors.credits = "Credits is required.";
    //   isValid = false;
    // }

    // Description validation
    if (!description) {
      console.log("desc", description);
      tempErrors.description = "Plan Description is required.";
      isValid = false;
    } else if (description.length < 50) {
      tempErrors.description =
        "Description must be at least 50 characters long.";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };
  const Req_Custom_Plan = async () => {
    try {
      const response = await http.post(
        "/master_service/custom_plan",
        JSON.stringify({
          user_id: localStorage.getItem("user_id"),
          request_admin_user: users,
          request_normal_user: credits,
          request_message: description,
        })
      );
      if (response.status === 201) {
        console.log("Response:", response.data);
        setOpen(true);
        Clear(); // Clear the form fields after success
      }
    } catch (error) {
      console.error("Error creating contact us entry", error);
    }
  };
  const handleSubmit = () => {
    if (validateForm()) {
      Req_Custom_Plan();
      console.log("fn call", description);
    }
  };
  return (
    <Box
      sx={{
        background: "#fff",
        color: "#797F8F",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Model_1 open={open} setOpen={setOpen} data={modelData} />
      <Box sx={{ display: "flex", justifyContent: "center", width: "90%" }}>
        <Box
          sx={{
            py: 3,
            minHeight: "500px",
            width: { xs: "100%", md: "65%" },
            border: "1px solid #E4E4E4",
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            px: 4,
            my: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", color: "#8A17D0" }}
          >
            Custom Plan
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#474747",
              my: 1,
            }}
          >
            Please enter your configurations for custom plan.
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              my: { xs: "0", sm: "15px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", sm: "46%" },
                my: { xs: "15px", sm: "0" },
              }}
            >
              <FormLabel
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#000000",
                  my: 1,
                }}
              >
                Admin Users
              </FormLabel>
              <TextField
                size="small"
                placeholder="Enter number of admin users"
                // inputMode="numeric"
                type="Number"
                value={users}
                onChange={(e) => {
                  const value = e.target.value;
                  // Check if the value is empty or a non-negative integer
                  if (value === "" || /^[0-9]*$/.test(value)) {
                    setUsers(value);
                  }
                }}
                error={!!errors.users}
                helperText={errors.users || ""}
              ></TextField>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", sm: "46%" },
                my: { xs: "20px", sm: "0" },
              }}
            >
              <FormLabel
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#000000",
                  my: 1,
                }}
              >
                Normal Users
              </FormLabel>
              <TextField
                size="small"
                placeholder="Enter number of normal users"
                // inputMode="numeric"
                type="Number"
                value={credits}
                // onChange={(e) => setCredits(e.target.value)}
                onChange={(e) => {
                  const value = e.target.value;
                  // Check if the value is empty or a non-negative integer
                  if (value === "" || /^[0-9]*$/.test(value)) {
                    setCredits(value);
                  }
                }}
                error={!!errors.credits}
                helperText={errors.credits || ""}
              ></TextField>
            </Box>
          </Box>
          <Box
            sx={{
              // height: "150px",
              width: { xs: "100%", md: "100%" },
              display: "flex",
              flexDirection: "column",
              my: "7px",
            }}
          >
            <FormLabel
              sx={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}
            >
              Plan Description*
              <span style={{ color: "#797F8F", fontSize: "15px" }}>
                (min 50 character)
              </span>
            </FormLabel>

            <Textarea
              Placeholder="Describe bundle here"
              Style={{
                width: "100%",
                height: "150px",
                borderRadius: "3px",
                // color: "#BCBFC7",
                my: "10px",
              }}
              Value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              my: 3,
            }}
          >
            <Button
              sx={{
                color: "#8A18D0",
                width: "120px",
                height: "32px",
                border: "1px solid #8A18D0",
                textTransform: "capitalize",
              }}
              onClick={Clear}
            >
              Clear
            </Button>
            <Button
              sx={{
                background: "#8A18D0",
                color: "#ffffff",
                px: 4,
                textWrap: "nowrap",
                textTransform: "capitalize",
                height: "32px",
                "&:hover": { background: "#B37FFC" },
                borderRadius: "3px",
              }}
              onClick={() => handleSubmit()}
            >
              Send Request
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
