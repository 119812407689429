import { Button } from "@mui/material";

export const DownloadCSV = ({ data, fileName, btnStyle, btnName }) => {
    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        // Extract headers
        const headers = Object.keys(array[0]);
        str += headers.map(header => `"${header}"`).join(',') + '\r\n';

        // Add data rows
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';

                let value = array[i][index] != null ? array[i][index] : '';
                if (typeof value === 'string') {
                    value = value.replace(/"/g, '""'); // Escape double quotes
                    line += `"${value}"`; // Wrap value in double quotes
                } else {
                    line += value;
                }
            }
            str += line + '\r\n';
        }
        return str;
    };

    const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button
            sx={{ ...(btnStyle ? { ...btnStyle } : {}) }}
            onClick={downloadCSV}
        >
            {btnName}
        </Button>
    );
}


export const downloadCSV = (data, fileName) => {
    // console.log('ent csv');
    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        // Extract headers
        const headers = Object.keys(array[0]);
        str += headers.map(header => `"${header}"`).join(',') + '\r\n';

        // Add data rows
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line !== '') line += ',';

                let value = array[i][index] != null ? array[i][index] : '';
                if (typeof value === 'string') {
                    value = value.replace(/"/g, '""'); // Escape double quotes
                    line += `"${value}"`; // Wrap value in double quotes
                } else {
                    line += value;
                }
            }
            str += line + '\r\n';
        }
        return str;
    };

    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
