import { Box, Button, Typography } from '@mui/material';
import { BackIcon } from '../../../../../utils/icon/AdminPage/Icon';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { CorporateNormalUserTable } from '../../../../../components/@extended/ModifiedTable';
import { modifyUserAllSellerTable } from "../../../../../components/@extended/CustomFunctions";
import http from '../../../../../utils/http-common';
import { Back } from '../../../../../components/@extended/Button';

export const All_Sellers = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(9);
    const [cn, setCn] = useState('');
    const [bnd, setBnd] = useState('');
    const [indBundleId, setIndBundleId] = useState('');
    const [brandId, setBrandId] = useState('');
    const [sellerTableData, setSellerTableData] = useState(JSON.parse(localStorage.getItem('AllSellerData')) || []);

    const location = useLocation();
    const getQueryParams = (search) => {
        console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("category_name")) {
            setCn(params.get("category_name"));
        }
        if (params.get("brand_name")) {
            setBnd(params.get("brand_name"));
        }
        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
        }

        if (params.get("brand_id")) {
            setBrandId(params.get("brand_id"));
        }

    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location.search]);

    const fetchDatas = async () => {
        try {
            const response = await http.post('master_service/all_seller/page', JSON.stringify({
                ind_bundle_id: indBundleId,
                brand_id: brandId,
            }));
            console.log("response tbl all seller:", response?.data, response?.data?.bundles[0]?.Sellers);
            setSellerTableData(response?.data?.bundles[0]?.Sellers || []);
            localStorage.setItem('AllSellerData', JSON.stringify(response?.data?.bundles[0]?.Sellers || []))
        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };

    useEffect(() => {
        if (indBundleId !== "" && brandId !== "") {
            fetchDatas();
        }

    }, [indBundleId, brandId]);

    const tbl = modifyUserAllSellerTable(sellerTableData);

    const filteredData = tbl.filter(seller =>
        seller.Seller_Name.toLowerCase().includes(searchTerm.toLowerCase())
    )?.map((e) => {
        if (!e?.Reviews) {
            const { Reviews, ...rest } = e;
            return rest;
        }
        return e;
    });

    if (filteredData.length === 0) {
        filteredData.push({
            Seller_Name: '',
            'No. of Products': '',
            'Reviews': '',
            'Ratings': '',
            '': '',
        });
    }

    const props = {
        title: 'Seller Table',
        searchTerm,
        setSearchTerm,
        filteredData,
        rowsPerPage,
        page,
        setPage
    };

    return (
        <Box sx={{ p: 2, px: 3 }}>
            <Back prop={{ txt: 'Back' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                <Typography sx={{ fontWeight: '600', fontSize: '20px', color: '#000' }}>
                    {cn} - {bnd} Sellers
                </Typography>
            </Box>

            <CorporateNormalUserTable prop={props} />
        </Box>
    );
};
