import { Box, NoSsr, Paper, Popper, Stack, Tooltip, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { ComboTxtBtn } from "../../../../../../components/@extended/Button";
import { generateVirtualElement, useMouseTracker } from "@mui/x-charts/ChartsTooltip/utils";
import { ChartsItemTooltipContent } from "@mui/x-charts/ChartsTooltip";
import { useEffect, useRef, useState } from "react";




export const HorizontalBar = ({ prop }) => {
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(400); // Default width in pixels

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = chartContainerRef.current.offsetWidth - 3;
                // Convert percentage to pixels (e.g., 100% of container width)
                setChartWidth(containerWidth); // Set the width to the container's width
            }
        };

        // Initial width calculation
        updateChartWidth();

        // Update width on window resize
        window.addEventListener('resize', updateChartWidth);
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, []);
    let tempD = prop?.data?.every((item) => {
        // console.log('Number(item.values) === 0', Number(item.value), Number(item.values) === 0);
        return Number(item.value) === 0
    })
        ? [1, 2, 3, 4, 5]
        : undefined;

    // console.log('temp', tempD, prop?.data, prop?.data?.every((item) => item.values === 0), prop?.data[0]?.value === 0)

    return (
        <Box sx={{ width: '100%' }} ref={chartContainerRef}>
            <ComboTxtBtn prop={prop} />
            <Box sx={{ border: '1px solid #EAECF0', borderRadius: '8px' }}>
                {prop?.data ?
                    <Box sx={{}}>
                        <BarChart
                            dataset={prop?.data}
                            yAxis={[{
                                scaleType: 'band', dataKey: 'brand', colorMap: {
                                    type: 'ordinal', colors: prop?.data?.map((e) => e.color)
                                }
                            }]}
                            xAxis={[{ data: tempD, }]}
                            // xAxis={[{ data: undefined, }]}
                            series={[{ dataKey: 'value', }]}
                            layout="horizontal"
                            margin={{ left: 100 }}
                            // grid={{ horizontal: true }}
                            width={chartWidth}
                            height={215}
                            // tooltip={CustomItemTooltip}
                            cx={98}
                            cy={100}
                        >
                            <Tooltip trigger='item'
                                slots={{
                                    itemContent: CustomItemTooltip
                                }}
                            />
                        </BarChart>
                    </Box>
                    :
                    <Box sx={{ height: '215px', display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', border: '1px solid #EAECF0', borderRadius: '8px', }}>
                        <Typography>{prop?.load ? 'Loading...' : 'Data Not Available'}</Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export const CustomItemTooltip = () => {
    const tooltipData = ChartsItemTooltipContent();
    const mousePosition = useMouseTracker(); // Track the mouse position on chart.

    if (!tooltipData || !mousePosition) {
        // No data to display
        return null;
    }

    // The pointer type can be used to have different behavior based on pointer type.
    const isMousePointer = mousePosition?.pointerType === 'mouse';
    // Adapt the tooltip offset to the size of the pointer.
    const yOffset = isMousePointer ? 0 : 40 - mousePosition.height;

    return (
        <NoSsr>
            <Popper
                sx={{
                    pointerEvents: 'none',
                    zIndex: (theme) => theme.zIndex.modal,
                }}
                open
                placement={isMousePointer ? 'top-end' : 'top'}
                anchorEl={generateVirtualElement(mousePosition)}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, yOffset],
                        },
                    },
                ]}
            >
                <Paper
                    elevation={0}
                    sx={{
                        m: 1,
                        p: 1.5,
                        border: 'solid',
                        borderWidth: 2,
                        borderColor: 'divider',
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        <div
                            style={{
                                width: 11,
                                height: 11,
                                borderRadius: '50%',
                                backgroundColor: tooltipData.color,
                            }}
                        />
                        <Typography sx={{ ml: 2 }} fontWeight="light">
                            {tooltipData.label}
                        </Typography>
                        <Typography sx={{ ml: 2 }}>{22}</Typography>
                    </Stack>
                </Paper>
            </Popper>
        </NoSsr>
    );
}
