import { Box, Typography, Button } from '@mui/material';
import TopNav from '../home/private-components/nav/TopNav';
import Footer from '../home/private-components/screens/Footer';
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import Bg_Img from "../../../assets/bg.png";
import { NotFoundIcon } from "../../../utils/icon/LandingPage/Icon";
import { Link as RouterLink } from "react-router-dom";
import { Analytics } from '../../../Analytics';

export const NotFound = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#000000",
                color: "#fff",
                minHeight: "100vh",
                fontFamily: "Inter",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    pb: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: { xs: "100%", md: "85%" },
                        position: "sticky",
                        top: "0",
                        zIndex: "999",
                    }}
                >
                    <Box
                        sx={{ display: { xs: "flex" }, justifyContent: { xs: "center" } }}
                    >
                        <Box sx={{ width: { xs: "80%", md: "100%" } }}>
                            <TopNav />
                            <Analytics />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                    <Element name="NotFound">
                        <FirstPage />
                    </Element>

                </Box>
                <Box sx={{ width: { xs: "100%", md: "80%" } }}>
                    <Element name="about-us">
                        <Footer />
                    </Element>
                </Box>
            </Box>
        </Box>
    );
}
export const FirstPage = () => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "85vh",
                display: "flex",
                // justifyContent: "center",
                position: "relative",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: `url(${Bg_Img})`,
                    backgroundSize: "cover",
                    backgroundPositionY: "bottom",
                    backgroundPositionX: "center",
                    transform: "scaleY(-1)",
                }}
            />
            <Box
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    px: { xs: 1, md: 8 },
                    py: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        width: { xs: "100%", md: "90%" },
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: "600",
                            fontSize: "58px",
                            color: "#8A17D0",
                            mt: 2,
                            mb: 2,
                        }}
                    >
                        404
                        {" "}
                        <span
                            style={{
                                color: "#ffffff",
                            }}
                        >
                            - Page not  found
                        </span>

                    </Typography>
                    <NotFoundIcon />
                    <Typography sx={{ fontWeight: "500", fontSize: "24px", color: "#ffffff" }}>Oops! This page doesn’t exist.</Typography>
                    <Typography sx={{ fontWeight: "500", fontSize: "24px", color: "#ffffff" }}>Let’s take you back to the home page.</Typography>
                    <RouterLink to="/" style={{ textDecoration: "none" }}>
                        <Button
                            sx={{
                                color: "#fff",
                                background: "#8A18D0",
                                mx: "10px",
                                minWidth: { xs: 'min-content', md: '108px' },
                                textWrap: 'nowrap',
                                textTransform: "capitalize",
                                "&:hover": { background: "#8a18d099" },
                                mt: 2,
                            }}
                        >
                            Home
                        </Button>
                    </RouterLink>
                </Box>
            </Box>
        </Box>
    );
};