import { Box, Button, Pagination, TextField, Typography } from "@mui/material"
import { SearchIcon } from "../../utils/icon/IndividualPages/Icon"
import { TableComp6 } from "../table/type - 6"
import { CorporateNormalStyleBar } from "../../utils/data/CorporateNormalUserDatas/Data";
import EastIcon from '@mui/icons-material/East';
import { ComponentTopBtn } from "./Button";

export const TablePagination = ({ prop }) => {
    const { filteredData, rowsPerPage, page, setPage, handlePreviousPage, handleChangePage, totalPages } = prop
    return (
        <Box sx={{}}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 0, mb: 0, p: '10px', borderBottom: '1px solid #DADADA', borderRight: '1px solid #DADADA', borderLeft: '1px solid #DADADA', borderBottomRightRadius: '15px', borderBottomLeftRadius: '15px', }}>
                <Button variant='outlined' onClick={handlePreviousPage} disabled={page === 1} sx={{ height: '30px', color: '#474747', display: { xs: 'none', sm: 'flex' }, px: 4, alignItems: 'center', borderColor: '#DADADA', justifyContent: 'space-evenly', textTransform: 'capitalize', '&:hover': { borderColor: '#DADADA' }, mr: 2, }}>
                    <EastIcon sx={{ transform: 'rotate(180deg)' }} />
                    Previous
                </Button>

                <Pagination count={Math.ceil(filteredData.length / rowsPerPage)} page={page} hidePrevButton hideNextButton shape="rounded" onChange={handleChangePage} />
                <Button variant='outlined' onClick={() => setPage((x) => x + 1)} disabled={page >= totalPages} sx={{ height: '30px', color: '#474747', display: { xs: 'none', sm: 'flex' }, px: 4, alignItems: 'center', borderColor: '#DADADA', justifyContent: 'space-evenly', textTransform: 'capitalize', '&:hover': { borderColor: '#DADADA' }, ml: 2, }}>
                    Next
                    <EastIcon sx={{ ml: 1 }} />
                </Button>
            </Box>
        </Box>
    )
}

// Corporate Normal User Table Template - It is the table template for the Corporate Normal User. It can be used for any Corporate Normal User Pages.
export const CorporateNormalUserTable = ({ prop }) => {
    const { Btns, border, tab, setTab, searchTerm, setSearchTerm, title, filteredData, rowsPerPage, page, setPage } = prop;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const totalRows = filteredData.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
    const paginatedData = filteredData.slice(startIndex, endIndex);
    // console.log('tbls', totalRows, totalPages, startIndex, endIndex, paginatedData, filteredData)

    return (
        <Box>
            {Btns &&
                <Box>
                    {Btns?.map((e, i) => (
                        <ComponentTopBtn btn={{ ...e, i: i, tab: tab, setTab: setTab, first: i === 0, last: i === Btns.length - 1 }} key={i} />
                    ))}
                </Box>
            }
            {totalRows !== 0 &&
                <Box sx={{ border: border && '0.8px solid #797F8F80', px: title && searchTerm !== undefined && setSearchTerm && 2, pb: 2, borderRadius: '8px', borderTopLeftRadius: { xs: '', md: 0 } }}>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' }, }}>
                        {title && <Typography sx={{ fontWeight: '600', fontSize: '16px', py: 3, color: '#000000' }}>{title}</Typography>}
                        {searchTerm !== undefined && setSearchTerm && (
                            <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #797F8F', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '350px', }}>
                                <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
                                <TextField sx={{ flex: 1, '& .MuiOutlinedInput-root': { '& fieldset': { border: 'none', height: '35px' }, '&:hover fieldset': { border: 'none' }, '&.Mui-focused fieldset': { border: 'none' }, }, }} placeholder='Search...' variant='outlined' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </Box>
                        )}
                    </Box>
                    <TableComp6 Data={paginatedData} Style={{ ...CorporateNormalStyleBar, isThreeDot: false }} />
                    {totalRows > rowsPerPage && <TablePagination prop={{ filteredData, rowsPerPage, page, setPage, handlePreviousPage: handlePreviousPage, handleChangePage: handleChangePage, totalPages: totalPages }} />}
                </Box>
            }
        </Box>
    )
}