import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export const Timer = ({ props }) => {
  const { initialMinute = 0, initialSeconds = 0, setDisableRt } = props;

  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const intervalRef = useRef(null);

  // Sync the timer state with the props when the parent updates them
  useEffect(() => {
    // Reset the timer when initialMinute or initialSeconds change
    setMinutes(initialMinute);
    setSeconds(initialSeconds);

    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear any existing interval
    }

    // Start a new countdown
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) return prevSeconds - 1;

        if (prevSeconds === 0 && minutes > 0) {
          setMinutes((prevMinutes) => prevMinutes - 1);
          return 59;
        }

        if (prevSeconds === 0 && minutes === 0) {
          clearInterval(intervalRef.current); // Stop the timer
          setDisableRt(false); // Enable the Resend OTP button
          return 0;
        }

        return prevSeconds;
      });
    }, 1000);

    // Clean up interval when component unmounts or when props change
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [initialMinute, initialSeconds, minutes, setDisableRt]);

  return (
    <Typography sx={{}}>
      Time Remaining {minutes < 10 ? `0${minutes}` : minutes} :{' '}
      {seconds < 10 ? `0${seconds}` : seconds}{' '}
    </Typography>
  );
};

export const ProperDate = (d) => {
  return `${String(new Date(d).getDate()).padStart(2, "0")}-${String(
    new Date(d).getMonth() + 1
  ).padStart(2, "0")}-${new Date(d).getFullYear()}`
}
export const ProperDate2 = (d) => {
  return `${new Date(d).getFullYear()}-${String(
    new Date(d).getMonth() + 1
  ).padStart(2, "0")}-${String(new Date(d).getDate()).padStart(2, "0")}`
}