import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import http from '../../../../../utils/http-common';
import {
  BundleCreatedIcon,
  BundleRunsIcon,
  CreditsLeftIcon,
  CreditsSpentIcon,
  DeleteSvg,
  DuplicateSvg,
  EditSvg,
  NextSvg,
  PlaySvg3,
  SearchIcon,
} from '../../../../../utils/icon/IndividualPages/Icon';
import { dateClnse3, RawDate } from '../../../../../components/Public/DateClnse';
import { downloadCSV } from '../../../../../components/Public/DownloadCSV';
import { Bundle } from '../../../../../components/@extended/Bundle';
import { updateBundle } from '../../../../../slice/bundle';
import { updateCredit } from '../../../../../slice/credits';
import { Card_3 } from '../../../../../components/card/Bundle-Card';
import { Model_1 } from '../../../../../components/@extended/Model';
import {
  modifyDataABLP,
  modifyDataIDP,
} from '../../../../../components/@extended/CustomFunctions';
import { SimpleCard } from '../../../../../components/card/Plan';
import { AdminBundleListData } from '../../../../../utils/data/AdminPage/Data';
import { updateBundles } from '../../../../../slice/allBundles';

export const BundleList = () => {
  const [respData, setRespData] = useState([]);
  const [bundleRuns, setBundleRuns] = useState(0);
  const [BundleCreated, setBundleCreated] = useState(0);
  const [allBundleData, setAllBundleData] = useState([]);
  const [activeBundleData, setActiveBundleData] = useState([]);
  const [inactiveBundleData, setInactiveBundleData] = useState(null);
  const [modelData, setModelData] = useState({
    title: 'Welcome to Brand Aura',
    emoji: '🎉',
    txt: [
      'Thank you for registering into our product.',
      'If you have any questions, feel free to reach out to our support team.',
      'Lets get started!!!',
    ],
    cl: '',
    btn_1: 'Add Credits',
    btn_1_Link: '/dashboard/individual-dashboard/recharge',
    btn_2: 'Select Data',
    btn_2_Link: '/dashboard/individual-dashboard/user-wizard',
  });
  // const [cred, setCred] = useState([0, 0])

  const [activeBundle, setActiveBundle] = useState(0);
  const [inactiveBundle, setInactiveBundle] = useState(0);
  const [open, setOpen] = useState(false);
  const [notMt, setNotMt] = useState(true);
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const credi = useSelector((state) => state.credit.credit);

  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenS(false);
  };

  const Data2 = [
    {
      title: 'Credits Left',
      value: credi.available_credit,
      // value: 0
    },
    {
      title: 'Credits Spent',
      value: credi.used_credit,
      // value: 0
    },
    {
      title: 'Bundles Created',
      value: BundleCreated,
    },
    {
      title: 'Active Bundles',
      value: activeBundle,
    },
    {
      title: 'Inactive Bundles',
      value: inactiveBundle,
    },
  ];
  const filterBundles = respData.filter((bundle) =>
    bundle.ind_bundle_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dispatch = useDispatch();
  const navi = useNavigate();
  const bundle = useSelector((state) => state.bundles.bundle);
  const allbundle = useSelector((state) => state.allbundles.allbundle);
  console.log('bundle data', bundle);

  const EditBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      // const dr = { startDate: selectedData2.ind_bundle_start_date, endDate: selectedData2.ind_bundle_start_date }
      const dr = {
        startDate: RawDate(selectedData2.ind_bundle_start_date),
        endDate: RawDate(selectedData2.ind_bundle_end_date),
      };
      console.log('respData', dr, respData, filteredData, filteredData[0]);
      const set = {
        SelectData: {
          category: selectedData2.table.Category,
          category_id: selectedData2.ind_bundle_category_id,
          country: selectedData2.table.Country,
          country_id: selectedData2.ind_bundle_country_id,
          ecommerceSites: selectedData2.table['E-Commerce Site'],
          ecommerceSites_id: selectedData2.ind_bundle_e_commerce_id,
          brand: selectedData2.table.Brand,
          brand_id: selectedData2.ind_bundle_brand_id,
        },
        bundleName: selectedData2.ind_bundle_name,
        ind_bundle_id: selectedData2.ind_bundle_id,
        daterange: dr,
        state: 0,
      };
      console.log('set', set);
      await dispatch(updateBundle(set));
      console.log('bundle data', bundle);
      navi('/dashboard/individual-dashboard/user-wizard');
      // localStorage.setItem('DataSelectionEdit', JSON.stringify(set))
      // localStorage.removeItem('bundle_id')
      // window.location.href = '/dashboard/individual-dashboard/user-wizard'
    } catch (error) {
      console.log('Edit bundle error', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Set the Edit Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const DeleteBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const response = await http.delete(
        `/master_service/bundle/delete/${localStorage.getItem(
          'user_id'
        )}/${bundle_id}`
      );
      await FetchDatas();
      console.log('respon', response);

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
      setModelData({
        title: 'Welcome to Brand Aura',
        emoji: '🎉',
        txt: [
          'Thank you for registering into our product.',
          'If you have any questions, feel free to reach out to our support team.',
          'Lets get started!!!',
        ],
        cl: '',
        btn_1: 'Add Credits',
        btn_1_Link: '/dashboard/individual-dashboard/recharge',
        btn_2: 'Select Data',
        btn_2_Link: '/dashboard/individual-dashboard/user-wizard',
      });
      localStorage.removeItem('bundle_id');
    } catch (error) {
      console.log('Delete bundle error', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Delete Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const DownloadSampleData = async (bundle_id) => {
    try {
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem('user_id'),
        category_id: selectedData2.ind_bundle_category_id,
        site_id: selectedData2.ind_bundle_e_commerce_id,
        brand_id: selectedData2.ind_bundle_brand_id,
        country_id: selectedData2.ind_bundle_country_id,
        frequency: selectedData2.frequency,
      };
      const response = await http.post(
        'master_service/corporate_sample_data',
        payload
      );
      console.log('resp Dnw', response);
      const time = new Date().toLocaleTimeString();
      console.log('tm', time);
      downloadCSV(
        response.data.top5Data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(new Date())}_${time}`
      );
      // await FetchDatas();

      setMessage('Sample data downloaded successfully!');
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error with RunBundle:', error);
      setMessage('Failed to fetch sample data.');
      setSeverity('error');
      handleClick();
    }
  };
  const ChangeModelData = (itm, rb) => {
    console.log('clk');
    if (itm === 0) {
      // if (!rb) {
      //   EditBundle();
      // } else {
      //   CopyBundle();
      // }
      setModelData({
        title: 'Delete Bundle',
        // emoji: '🎉',
        txt: ['Are you sure you want to delete the bundle??'],
        cl: '',
        btn_1: 'Cancel',
        btn_1_cancel: true,
        // btn_1_Link: '/dashboard/individual-dashboard/recharge',
        btn_2: 'Delete',
        btn_2_fn: DeleteBundle,
        btn_2_cancel: true,
        // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
      });
      setOpen(true);
    }
    else if (itm === 1) {
      const bundle_id = localStorage.getItem('bundle_id');
      navi(`/admin/report?ind_bundle_id=${encodeURIComponent(bundle_id)}`)
      //   setModelData({
      //     title: "Delete Bundle",
      //     // emoji: '🎉',
      //     txt: ["Are you sure you want to delete the bundle??"],
      //     cl: "",
      //     btn_1: "Cancel",
      //     btn_1_cancel: true,
      //     // btn_1_Link: '/dashboard/individual-dashboard/recharge',
      //     btn_2: "Delete",
      //     btn_2_fn: DeleteBundle,
      //     btn_2_cancel: true,
      //     // btn_2_Link: '/dashboard/individual-dashboard/user-wizard'
      //   });
      //   setOpen(true);
    }
  };

  const settingsForAdm = [
    // { title: 'Edit Bundle', fn: ChangeModelData, link: '' },
    { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
    { title: 'View Data', fn: ChangeModelData, link: '' },
  ];

  const FetchDatas = async () => {
    try {
      const response = await http.post(
        '/master_service/corporate_bundle_list',
        JSON.stringify({ user_id: localStorage.getItem('user_id'), admin_id: localStorage.getItem('admin_id') })
      );
      if (response.data.data.length === 0) {
        setNotMt(false);
        setOpen(true);
      } else {
        setNotMt(true);
        const modifiedData = modifyDataABLP(response.data.data);
        setRespData(modifiedData);
        dispatch(
          updateBundles(modifiedData)
        );
        setAllBundleData(modifiedData);
        const ab = modifiedData.filter((e) => e.bundle_status === 'Active');
        const iab = modifiedData.filter((e) => e.bundle_status === 'Inactive');
        setActiveBundleData(ab);
        setInactiveBundleData(iab);
        setBundleCreated(response.data.Bundle_Created);
        setActiveBundle(response.data.Active_Bundles);
        setInactiveBundle(response.data.Inactive_Bundles);
        setBundleRuns(response.data.Bundle_Runs);
        dispatch(
          updateCredit({
            available_credit: response.data.available_credits,
            used_credit: response.data.used_credits,
          })
        );
        console.log(
          'respon',
          response,
          response.data.data,
          modifiedData,
          ab,
          iab
        );
        // setMessage(response.data.message);
        // setSeverity("success");
        // handleClick();
      }
      //   const modifiedData = modifyDataIDP(response.data.data);
      //   setRespData(modifiedData);
      //   setBundleCreated(response.data.Bundle_Created);
      //   setBundleRuns(response.data.Bundle_Runs);
      //   await Credit();
      //   console.log("respon", response, response.data.data);

      //   setMessage(response.data.message);
      //   setSeverity("success");
      //   handleClick();
    } catch (error) {
      console.log('fetching error', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  //   const Credit = async () => {
  //     try {
  //       const res = await http.post(
  //         "/master_service/credit",
  //         JSON.stringify({ user_id: localStorage.getItem("user_id") })
  //       );
  //       console.log("resp", res, res.data);
  //       // setCred([res.data.data.used_credit, res.data.data.balance_credit])
  //       dispatch(
  //         updateCredit({
  //           available_credit: res.data.data.available_credit,
  //           used_credit: res.data.data.used_credit,
  //         })
  //       );
  //     } catch (error) {
  //       console.log("credits err", error);
  //     }
  //   };

  const RunBundle = async (bundle_id) => {
    try {
      const payload = {
        ind_bundle_id: bundle_id,
        user_id: localStorage.getItem('user_id'),
      };
      const response = await http.post('master_service/run/bundle', payload);
      const filteredData = respData.filter(
        (data) => data.ind_bundle_id === bundle_id
      );
      const selectedData2 = filteredData[0];
      const time = new Date().toLocaleTimeString();
      console.log('tm', time);
      downloadCSV(
        response.data.data,
        `${selectedData2.ind_bundle_name}_${dateClnse3(new Date())}_${time}`
      );
      await FetchDatas();

      setMessage('Bundle run sucessfully');
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error with RunBundle:', error);
      setMessage(
        error.response ? error.response.data.message : 'Unable to Run the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  useEffect(() => {
    if (allbundle !== null) {
      setRespData(allbundle)
      const ab = allbundle.filter((e) => e.bundle_status === 'Active');
      const iab = allbundle.filter((e) => e.bundle_status === 'Inactive');
      console.log('reflect', ab, iab, allBundleData)
      setActiveBundleData(ab);
      setInactiveBundleData(iab);
      setAllBundleData(allbundle)
    }
  }, [allbundle]);
  useEffect(() => {
    FetchDatas();
  }, []);
  const dnbndl = {
    title: 'Sample Data Download Bundle',
    // emoji: "🎉",
    txt: [
      'You have used the free downloads.',
      [
        { text: 'You need ' },
        { text: `${2} Credits`, isBold: true },
        { text: ' to download this bundle.' },
      ],
      // "You need 2 Credits to download this bundle.",
    ],
    cl: credi.available_credit,
    btn_1: 'Cancel',
    btn_1_cancel: true,
    // btn_1_Link: "/dashboard/individual-dashboard/recharge',
    btn_2: 'Proceed',
    btn_2_cancel: true,
    Credits: true,
    CreditsLnk: '/dashboard/individual-dashboard/recharge',
    // btn_2_Link: "/dashboard/individual-dashboard/user-wizard",
    btn_2_fn: DownloadSampleData,
  };
  const BndlePops = ({ x, ec, id }) => {
    console.log('BndlePops', x, id);
    if (x === 0) {
      // setModelData({
      //   ...runbndl,
      //   txt: [
      //     'Running the bundle costs credits.',
      //     [
      //       { text: 'You need ' },
      //       { text: `${ec} Credits`, isBold: true },
      //       { text: ' to run this bundle.' },
      //     ],
      //     // `You need ${ec} Credits to run this bundle.`,
      //   ],
      //   btn_2_fn_props: id,
      // });
      // setOpen(true);
      DownloadSampleData(id)
    }
  };
  return (
    <Box sx={{ height: '100%', width: '100%', overflowY: 'auto', scrollbarWidth: 'none' }}>
      {notMt && (
        <>
          <Model_1
            open={open}
            setOpen={setOpen}
            data={modelData}
          />

          <Box
            sx={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              borderRight: '0.8px solid #797F8F80',
              display: 'flex',
              flexDirection: 'column',
              py: { xs: 1, md: 3 },
              px: { xs: 1, md: 5 },
            }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                py: { xs: 2, md: 0 },
              }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '20px',
                  py: { xs: 1, md: 3 },
                  background:
                    'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}>
                Bundle Summary
              </Typography>
              <Link to={'/admin/create-bundle'}>
                <Button
                  sx={{
                    background: '#8A18D0',
                    px: 2,
                    color: '#fff',
                    textTransform: 'capitalize',
                    '&:hover': { background: '#B37FFC' },
                  }}>
                  <Typography sx={{ fontWeight: '600', fontSize: '10px' }}>
                    New Bundle
                  </Typography>
                </Button>
              </Link>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}>
              {Data2.map((f, j) => (
                <SimpleCard
                  key={j}
                  x={f}
                  wd={`${100 / Data2.length - 0.5}%`}
                />
              ))}
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
              }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  py: 3,
                  color: '#000000',
                }}>
                My Bundles
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #797F8F',
                    borderRadius: '4px',
                    padding: '0.5rem 1rem',
                    // backgroundColor: '#f9f9f9',
                    width: '100%',
                    maxHeight: '35px',
                    maxWidth: '250px',
                  }}>
                  <SearchIcon
                    sx={{ color: '#757575', marginRight: '0.5rem' }}
                  />
                  <TextField
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          height: '35px',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                    }}
                    placeholder='Search bundle...'
                    variant='outlined'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Box>
                <Select
                  // value={allBundle}
                  defaultValue={'All Bundles'}
                  onChange={(e) => {
                    console.log('log', e.target.value);
                    // setAllBundle(e.target.value);
                    if (e.target.value === 'All Bundles') {
                      setRespData(allBundleData);
                    } else if (e.target.value === 'Active Bundles') {
                      console.log('ent', activeBundleData);
                      setRespData(activeBundleData);
                    } else if (e.target.value === 'Inactive Bundles') {
                      setRespData(inactiveBundleData);
                    }
                  }}
                  // displayEmpty
                  sx={{
                    p: 0,
                    maxHeight: '36px',
                    minWidth: '160px',
                    marginLeft: 2,
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value='All Bundles'>All Bundles</MenuItem>
                  <MenuItem value={'Active Bundles'}>Active Bundles</MenuItem>
                  <MenuItem value={'Inactive Bundles'}>
                    Inactive Bundles
                  </MenuItem>
                </Select>
              </Box>
            </Box>
            <Box>
              {filterBundles.map((e, i) => (
                <Bundle
                  key={i}
                  e={e}
                  ChangeModelData={ChangeModelData}
                  BndlePops={BndlePops}
                  BtnDatas={settingsForAdm}
                />
              ))}
            </Box>
          </Box>
        </>
      )}
      {!notMt && (
        <Box
          sx={{
            width: '100%',
            borderRight: '0.8px solid #797F8F80',
            display: 'flex',
            flexDirection: 'column',
            py: 3,
            px: 5,
          }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
              py: { xs: 2, md: 0 },
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '20px',
                py: { xs: 1, md: 3 },
                background:
                  'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
              Bundle Summary
            </Typography>
            <Link to={'/admin/create-bundle'}>
              <Button
                sx={{
                  background: '#8A18D0',
                  px: 2,
                  color: '#fff',
                  textTransform: 'capitalize',
                  '&:hover': { background: '#B37FFC' },
                }}>
                <Typography sx={{ fontWeight: '600', fontSize: '10px' }}>
                  New Bundle
                </Typography>
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}>
            {Data2.map((f, j) => (
              <SimpleCard
                key={j}
                x={f}
                wd={`${100 / Data2.length - 0.5}%`}
              />
            ))}
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', md: 'row' },
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '16px',
                py: 3,
                color: '#000000',
              }}>
              My Bundles
            </Typography>
          </Box>
          <Box
            sx={{
              border: 'dashed 1px #BCBFC7',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              py: 15,
            }}>
            <Typography
              sx={{ fontSize: '16px', fontWeight: '600', color: '#8A18D0' }}>
              No configurations found!!!
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: '400', color: '#000' }}>
              Please configure to view the bundles
            </Typography>
          </Box>
        </Box>
      )}
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ position: 'absolute', mt: '38px' }}>
        <Alert
          onClose={handleClose}
          severity={severity}
          variant='filled'
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
