import { Box, Button, Typography } from "@mui/material"
import { ForwardIcon } from "../../utils/icon/AdminPage/Icon"
import { useState } from "react"
import './NotifiCard.css'


export const SellerReviewCard = () => {
    return (
        <Box></Box>
    )
}
export const NotifiCategoryCard = ({ p }) => {
    const { cat, setCat, i, res } = p;
    const len = res?.filter((e) => e?.categoryFilter === i)
    // console.log('ii', i, len?.length)
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', p: 2, my: 1, borderRadius: '4px', background: cat === i ? '#8A18D0' : '#FAFAFA', color: cat === i ? '#fff' : '#000', border: '0.8px solid #E1E2E6' }} onClick={() => setCat(i)}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <p.ikn />
                <Box sx={{ pl: 2 }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', pb: 1, }}>{p?.text}</Typography>
                    <Typography sx={{ fontSize: '8px', fontWeight: '400', }}>{len?.length} Notification</Typography>
                </Box>
            </Box>
            <Button disableFocusRipple disableRipple disableTouchRipple sx={{ minWidth: 'max-content', width: 'max-content' }}><ForwardIcon /></Button>
        </Box>
    )
}
export const NotifyCard = ({ p, swap }) => {
    const [showDetails, setShowDetails] = useState(false);
    // console.log('p', p)

    return (
        <Box
            sx={{
                borderRadius: '8px',
                my: 1,
                background: '#FAFAFA',
                border: '0.8px solid #BCBFC766',
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    p: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* {p?.ikn} */}
                    <Box
                        sx={{
                            height: "40px",
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            background: "#000",
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: "bold",
                        }}
                    >
                        {swap === 'Brand' ? p?.text?.charAt?.(0)?.toUpperCase() : p?.EcommName?.charAt?.(0)?.toUpperCase()}
                    </Box>
                    <Box sx={{ pl: 2 }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000', pb: 1 }}>{swap === 'Brand' ? p?.text : p?.EcommName}</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{p?.subText}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>{p?.time}</Typography>
                    <Button disableTouchRipple disableFocusRipple disableGutters
                        sx={{
                            fontSize: '10px',
                            fontWeight: '400',
                            color: '#000',
                            textTransform: 'capitalize',
                            padding: 0,
                            '&:hover': { background: 'transparent' },
                            // minWidth: 'auto'
                        }}
                        onClick={() => setShowDetails(!showDetails)}
                    >
                        {!showDetails ? 'More' : 'Less'}
                    </Button>
                </Box>
            </Box>
            {/* {showDetails && ( */}
            <Box
                className={`details ${showDetails ? 'show' : 'hide'}`}
                sx={{
                    borderTop: '2px solid #E1E2E6',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // p: 2,
                }}
            >
                <Box>
                    <Box sx={{ display: 'flex', mb: 1 }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1, minWidth: { xs: 'min-content', md: '130px' } }}>Product Name :</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000', maxWidth: '70%', textWrap: 'wrap' }}>{p?.prodName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1, minWidth: { xs: 'min-content', md: '130px' } }}>{swap === 'Brand' ? 'Brand' : 'E-Commerce Name'} :</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{swap === 'Brand' ? p?.text : p?.EcommName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1, minWidth: { xs: 'min-content', md: '130px' } }}>Seller :</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{p?.Seller}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', textWrap: 'nowrap' }}>Product Price</Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#000',
                            p: 1,
                            background: '#e3c9f2',
                            textAlign: 'center',
                            borderRadius: '3px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {p?.prodPrice}
                    </Typography>
                </Box>
            </Box>
            {/* )} */}
        </Box>
    );
};

