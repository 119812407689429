import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        token: localStorage.getItem('token')
            ? localStorage.getItem('token')
            : Cookies.get('token')
                ? Cookies.get('token')
                : null,
        // token: JSON.parse(localStorage.getItem('token')) || Cookies.get('token'),
    },
    reducers: {
        updateToken: (state, action) => {
            // console.log('Redux updateBundle action:', state, action);
            state.token = action.payload;
        },
        clearToken: (state) => {
            state.token = '';
        },
    },
});

// Export the actions
export const { updateToken, clearToken } = tokenSlice.actions;

// Export the reducer
export default tokenSlice.reducer;
