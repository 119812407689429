import { createSlice } from '@reduxjs/toolkit';

export const reportFilterSlice = createSlice({
    name: 'reportFilter',
    initialState: {
        reportFilter: { month: null, year: null, },
    },
    reducers: {
        setMonth: (state, action) => {
            state.reportFilter.month = action.payload;
        },
        setYear: (state, action) => {
            state.reportFilter.year = action.payload;
        },
    },
});

// Export the actions
export const { setMonth, setYear } = reportFilterSlice.actions;

// Export the reducer
export default reportFilterSlice.reducer;
