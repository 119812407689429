import { Box, Button, MenuItem, Select, Typography } from "@mui/material"
import { Back, ComboTxtBtn } from "../../../../../components/@extended/Button"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import { DownloadIcn } from "../../../../../utils/icon/IndividualPages/Icon"
import { PlatformDistribution, ProductListTable, ProductPerBrand, ProductRating, SellerDistribution, SellerListTable, SellerPerBrand } from "./components"
import { Sunburst, Sunbursts } from "./components/Sunburst"
import { Sankey } from "./components/Sankey"
import { ErrorBoundary } from "../../../../../utils/ErrorBoundary"
import { Months } from "../../../../../utils/data/CorporateNormalUserDatas/Data"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import http from '../../../../../utils/http-common'
import { setMonth, setYear } from "../../../../../slice/reportFilter"


export const BundleReport = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const m = useSelector((s) => s?.reportFilter?.reportFilter)
    const [indBundleId, setIndBundleId] = useState(null);
    const [monthYear, setMonthYear] = useState(null)
    const [month, setMonths] = useState(null)
    const [category, setCategory] = useState(null)
    const [monthTemp, setMonthTemp] = useState(null)
    const [year, setYears] = useState(null)
    const [errors, setErrors] = useState({});
    const bndlLnk = localStorage.getItem('user_role') === 'Admin User' ? '/admin/bundle-list' : '/user'
    const user = localStorage.getItem('user_role') === 'Admin User' ? false : true

    const Fetch = async () => {
        try {
            const response = await http.post(
                `/master_service/reports/month_year`,
                JSON.stringify({ ind_bundle_id: indBundleId })
            );
            console.log('Month Year', response?.data, m);
            setMonthYear(response?.data);
            // setRawTbl(response?.data?.brands)
            // if (response.status === 200) {
            //   setMessage("data fetched successfully");
            //   setSeverity("success");
            //   handleClick();
            // }
        }
        catch (error) {
            console.error('Error fetching data:', error?.response?.data?.message);
            // setMessage(
            //     error?.response
            //         ? error?.response?.data?.message
            //         : 'Unable to Fetch the Data.'
            // );
            // setSeverity('error');
            // handleClick();
        }
    }
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
        if (params.get("category")) {
            setCategory(params.get("category"));
            // console.log('ind_bundle_id', indBundleId, params.get("ind_bundle_id"))
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    useEffect(() => {
        if (indBundleId !== null) {
            Fetch();
        }
    }, [indBundleId]);

    // console.log('Month Year', m);
    return (
        <Box sx={{ p: 2, width: '100%' }}>
            {!user && <Back prop={{ txt: 'Back' }} />}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000000' }}>{category}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '60px', width: "201px", mr: 1, }}>
                        <Select
                            displayEmpty
                            sx={{
                                width: "201px",
                                height: "35px",
                            }}
                            value={month}
                            onChange={(e) => {
                                setMonths(e.target.value)
                                dispatch(setMonth(e.target.value))
                            }}
                            onClick={() => {
                                if (year === null) {
                                    setErrors((p) => ({ ...p, month: 'Please select a year before selecting a month.' }));
                                } else {
                                    setErrors({});
                                }
                                console.log('yr', year);
                            }}
                            error={!!errors.month}
                            helperText={errors.month}
                        >
                            <MenuItem value={null}>
                                Select Month
                            </MenuItem>
                            {(year || year === 0) && Months?.map((e, i) => {
                                const d = monthTemp?.months?.map((e) => (e.trim()))?.includes(e.trim())
                                // console.log('month', e, e.trim(), monthTemp, d)
                                return (
                                    <MenuItem value={e} disabled={!d}>{e}</MenuItem>
                                )
                            })}
                        </Select>
                        {errors.month && (
                            <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: '12px' }}>
                                {errors.month}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ height: '60px', width: "201px", mr: 1, }}>
                        <Select
                            displayEmpty
                            sx={{
                                width: "201px",
                                mr: 1,
                                height: "35px",
                            }}
                            value={year}
                            onChange={(e) => {
                                setYears(e.target.value);
                                dispatch(setYear(monthYear?.[e.target.value]?.year))
                                dispatch(setMonth(null))
                                setErrors({});
                                setMonths(null)
                                setMonthTemp(monthYear?.[e.target.value])
                                console.log('yrr', e.target.value, monthYear?.[e.target.value]?.year)
                            }
                            }
                        >
                            <MenuItem value={null}>
                                Select Year
                            </MenuItem>
                            {/* <MenuItem value={2024}>
                            2024
                        </MenuItem>
                        <MenuItem value={2023}>
                            2023
                        </MenuItem> */}
                            {monthYear?.map((e, i) => {
                                // monthYear.includes()
                                return (
                                    <MenuItem value={i}>{e?.year}</MenuItem>
                                )
                            })}
                        </Select>
                        {errors.year && (
                            <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: '12px' }}>
                                {errors.year}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ height: '60px' }}>
                        {user &&
                            <Link to={bndlLnk}>
                                <Button disableTouchRipple disableFocusRipple disableGutters sx={{ background: '#8A18D0', px: 2, color: '#fff', textTransform: 'capitalize', width: '80px', minHeight: '35px', '&:hover': { background: '#B37FFC' }, }}>
                                    <Typography sx={{ ml: 0, fontWeight: '600', fontSize: '10px', fontSize: '12px' }}>Bundle</Typography>
                                </Button>
                            </Link>
                        }
                    </Box>
                </Box>
            </Box>
            {/* <ComboTxtBtn prop={{ name: 'Power Tools', txtStyl: { fontSize: '20px' }, btnIkn: null, btnTxt: 'Bundle', btnStyl: { width: '80px' }, btnTxtStyl: { fontSize: '12px' }, btnLnk: '/user', fn: () => { } }} /> */}
            <ComboTxtBtn prop={{ name: 'Reports ', txtStyl: {}, btnIkn: null, btnTxt: '', fn: () => { } }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <PlatformDistribution />
                <SellerDistribution />
                <ProductRating />
            </Box>
            <Box>
                <ProductListTable />
            </Box>
            {/* <ComboTxtBtn prop={{ name: 'Products Per Brand ', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => { } }} /> */}
            <ProductPerBrand />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Sunbursts />
                <Sankey />
            </Box>
            {/* <ComboTxtBtn prop={{ name: 'Sellers List', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => { } }} /> */}
            <SellerListTable />
            {/* <ComboTxtBtn prop={{ name: 'Sellers Per Brand', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => { } }} /> */}
            <SellerPerBrand />
            {/* <Sunbursts /> */}
        </Box>
    )
}