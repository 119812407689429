import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Snackbar,
  TextField,
  Typography,
  Checkbox,
  List,
  ListItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { useEffect, useState } from 'react';
import { CheckBox, Label } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
import http from '../../../../utils/http-common';
import { Carousel_OG } from '../Components/Carousel';
import { useDispatch } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Logo } from '../../../../assets/logo';
import { updateToken } from '../../../../slice/Token';
import { BtnLoad } from '../../../../utils/data/LandingPage/Data';
import Cookies from 'js-cookie';
import { Analytics } from '../../../../Analytics';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [load, setLoad] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    console.log('check', event.target.checked)
    setChecked(event.target.checked);
  };
  const dispatch = useDispatch();

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Email validation
    if (!email) {
      tempErrors.email = 'Email is required.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'Email address is invalid.';
      isValid = false;
    }

    // Password validation
    if (
      password.length < 8 ||
      !/[A-Z]/.test(password) ||
      !/[a-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      tempErrors.password = 'Enter valid password';
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm() && !load) {
      Login();
    }
    //  else {
    //   setMessage("Please correct the highlighted errors.");
    //   setSeverity("error");
    //   setOpen(true);
    // }
  };

  const Login = async () => {
    setLoad(true)
    localStorage.clear()
    try {
      const response = await http.post(
        '/auth_service/Login',
        JSON.stringify({
          user_email: email,
          user_password: password,
        })
      );

      console.log('res', response);

      if (response.status === 200) {
        setLoad(false)
        setEmail('');
        setPassword('');
        setMessage(response?.data?.message);
        if (checked) {
          localStorage.setItem('token', response?.data?.token);
        } else {
          Cookies.set('token', response?.data?.token, { session: true })
        }
        localStorage.setItem('user_type', response?.data?.user.user_type);
        localStorage.setItem('user_role', response?.data?.user.user_role);
        localStorage.setItem('user_id', response?.data?.user.user_id);
        localStorage.setItem('admin_id', response?.data?.user.admin_id);
        localStorage.setItem('user_name', response?.data?.user.user_first_name);
        localStorage.setItem('email', response?.data?.user.user_email);
        dispatch(updateToken(response?.data?.token));
        setSeverity('success');
        handleClick();
        // setTimeout(() => {
        //   navigate(
        //     response?.data?.user.user_type === 'company'
        //       ? (response?.data?.user.user_role === 'Admin User' ? '/admin' : '/user')
        //       : '/dashboard/individual-dashboard'
        //   );
        // }, 2000);
        // window.location.reload();
        if (response?.data?.user.user_type === 'company') {
          if (response?.data?.user.user_role === 'Admin User') {
            window.location.href = '/admin';
          } else if (response?.data?.user.user_role === 'Normal User') {
            window.location.href = '/user';
          }
        } else {
          window.location.href = '/dashboard/individual-dashboard';
        }
      } else if (response?.data?.loginStatus === 4) {
        setLoad(false)
        const t = true;
        localStorage.setItem('email', email);
        localStorage.setItem('otp', t);
        console.log('entered 400');
        setEmail('');
        setPassword('');
        setMessage(response?.data?.message);
        setSeverity('error');
        handleClick();
        navigate('/otp');
      }
      setLoad(false)
    } catch (error) {
      setLoad(false)
      const errorMessage = error?.response
        ? error?.response?.data?.message
        : 'Registration failed. Please try again.';
      if (error?.response?.data?.loginStatus === 4) {
        localStorage.setItem('email', email);
        console.log('entered 400');
        setEmail('');
        setPassword('');
        setMessage(error?.response?.data?.message);
        setSeverity('error');
        handleClick();
        navigate('/otp');
      } else if (error?.response?.data?.loginStatus === 6) {
        localStorage.setItem('email', email);
        localStorage.setItem('user_id', error?.response?.data?.user.user_id);
        localStorage.setItem('admin_id', error?.response?.data?.user.admin_id);
        console.log('entered 400');
        setEmail('');
        setPassword('');
        setMessage(error?.response?.data?.message);
        setSeverity('error');
        handleClick();
        navigate('/pricing');
      } else if (error?.response?.data?.loginStatus === 9) {
        console.log('entered 400');
        setEmail('');
        setPassword('');
        setMessage(error?.response?.data?.message);
        setSeverity('error');
        handleClick();
      } else {
        console.error('There was an error registering the user!', error);
        setMessage(errorMessage);
        setSeverity('error');
        handleClick();
      }
    }
  };


  useEffect(() => {
    const checkAuth = async () => {
      const tk = localStorage.getItem('token');
      const tp = localStorage.getItem('user_type');
      const rl = localStorage.getItem('user_role');
      if (tk && tp) {
        if (tp === 'company') {
          if (rl === 'Admin User') {
            // await RedirectRoute('/admin', navigate);
            navigate('/admin')
          } else if (rl === 'Normal User') {
            // await RedirectRoute('/user', navigate);
            navigate('/user')
          }
        } else {
          // await RedirectRoute('/dashboard/individual-dashboard', navigate);
          navigate('/dashboard/individual-dashboard')
        }
      }
    };

    checkAuth();
  }, []);


  // useEffect(() => {
  // const tk = localStorage.getItem("token");
  // const tp = localStorage.getItem("user_type");
  // if (tk && tp) {
  //   if (tp === "company") {
  //     navigate("/admin");
  //   } else {
  //     navigate("/dashboard/individual-dashboard");
  //   }
  // }
  // }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: '50%', height: '100vh', display: { xs: 'none', sm: 'flex' }, background: '#000000', borderTopRightRadius: '12px', borderBottomRightRadius: '12px', color: '#fff' }}>
        <Box sx={{ width: '100%', height: '100vh', display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', py: 4, px: 5, background: "radial-gradient(circle farthest-side, #2C2C2C, #010101)", borderTopRightRadius: 'inherit', borderBottomRightRadius: 'inherit', }}>
          <Link to={'/'}>
            <Logo />
          </Link>
          <Carousel_OG />
          <Analytics />
        </Box>
      </Box>
      <Box sx={{ width: { xs: '100%', sm: '50%' }, height: '90vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: '#ffffff', color: '#474747', py: 10, px: 5, }}>
        <FormGroup sx={{ width: { xs: '100%', sm: '75%' } }}>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '32px',
              display: 'flex',
              my: '15px',
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '32px',
                background:
                  'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
              Welcome Back
            </Typography>{' '}
            ✨
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '16px',
              color: '#000000',
              mb: '5px',
            }}>
            Enter your login credentials here!!!
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', my: '10px' }}>
            <FormLabel
              sx={{ fontWeight: '500', fontSize: '14px', color: '#474747' }}>
              Email ID
              <span style={{ position: "relative", top: "-3px", }}>*</span>
            </FormLabel>
            <TextField
              size='small'
              placeholder='Enter Email ID'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type='email'
              sx={{ color: '#AFAFAF', height: '30px', "& .MuiFormHelperText-root": { marginLeft: "0px" } }}
              error={!!errors.email}
              helperText={errors.email}></TextField>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', my: '10px' }}>
            <FormLabel
              sx={{ fontWeight: '500', fontSize: '14px', color: '#474747', mt: '10px' }}>
              Password*
            </FormLabel>
            {/* <TextField
              size='small'
              placeholder='Enter password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ color: '#AFAFAF', height: '30px' }}
              error={!!errors.password}
              helperText={errors.password || ''}></TextField> */}
            <TextField
              placeholder='Enter Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              sx={{
                my: 1,
                '& input': {
                  height: '10px',
                },
              }}
              type={showPassword1 ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword1(!showPassword1)}
                      edge='end'>
                      {showPassword1 ? (
                        <VisibilityOutlinedIcon />

                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <Typography
                variant='caption'
                color='error'>
                {errors.password}
              </Typography>
            )}

            {/* <OutlinedInput
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              type={showPassword1 ? "text" : "password"}
              sx={{
                my: 1,
                "& input": {
                  height: "10px",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword1(!showPassword1)}
                    edge="end"
                  >
                    {showPassword1 ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password && (
              <Typography variant="caption" color="error">
                {errors.password}
              </Typography>
            )} */}
          </Box>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: { xs: '30px', sm: '0px' }, mt: { xs: '30px', sm: '0px' },
            // mt: !!errors.password
            //   ? { xs: "30px", sm: "70px" }
            //   : { xs: "30px", sm: "10px" },
          }}>
            <FormControlLabel sx={{ fontWeight: '400', fontSize: '12px', color: '#0F1419' }} control={<Checkbox checked={checked} onChange={handleChange} />
            } label='Remember Me' />
            <Link to={'/forgot-password'} sx={{ textDecoration: 'none', fontWeight: '400', fontSize: '12px', color: '#0F1419', }}>
              Forgot Password?
            </Link>
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}>
            {/* <Button sx={{ color: '#fff', background: '#8A18D0', textTransform: 'capitalize', height: '43px', my: '15px', '&:hover': { background: '#B37FFC' }, }} onClick={handleSubmit}>Login</Button> */}
            {/* <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ position: 'absolute', mt: '78px', width: '100%' }}><Alert onClose={handleClose} severity={severity} variant='filled' sx={{ width: '100%' }}>{message}</Alert></Snackbar> */}
            <Button disabled={load} sx={{ color: load ? 'rgba(0, 0, 0, 0.5)' : '#fff', background: '#8A18D0', textTransform: 'capitalize', height: '43px', my: '15px', '&:hover': { background: '#B37FFC' }, }} onClick={handleSubmit}>
              Login
              <Box>
                {load && (
                  <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', zIndex: 1, }}>
                    <Box sx={BtnLoad} />
                  </Box>
                )}
              </Box>
            </Button>
            {/* <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ position: 'absolute', mt: '78px', width: '100%' }}><Alert onClose={handleClose} severity={severity} variant='filled' sx={{ width: '100%' }}>{message}</Alert></Snackbar> */}
          </Box>
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} ><Alert onClose={handleClose} severity={severity} variant='filled' sx={{ width: "100%" }}>{message}</Alert></Snackbar>
          <Typography sx={{ color: '#0F1419', textAlign: 'center', fontWeight: '400', fontSize: '12px', }}>
            Don’t have an account?{' '}
            <Link to={'/reg'} style={{ fontWeight: '600', fontSize: '12px', position: 'relative', color: '#000000', }}>
              Create free account
              <span style={{ position: 'absolute', left: 0, bottom: -1, width: '100%', height: '1px', backgroundColor: '#909090', }}></span>
            </Link>
          </Typography>
        </FormGroup>
      </Box>
    </Box>
  );
};
