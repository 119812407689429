import { Box, Typography } from "@mui/material";
import TopNav from "../home/private-components/nav/TopNav";
import Content from "../home/private-components/Content";
import { Element } from "react-scroll";
import { FirstPage } from "./private/screen/FirstPage";
import { SecondPage } from "./private/screen/SecondPage";
import { ThirdPage } from "./private/screen/ThirdPage";
import { AboutWork } from "./private/screen/AboutWork";
import { WhyChoseUs } from "./private/screen/WhyChoseUs";
import Footer from "../home/private-components/screens/Footer";
import { LastPage } from "./private/screen/LastPage.jsx";
import { Analytics } from "../../../Analytics.jsx";
import { Helmet } from "react-helmet";

export const About = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#000000",
        color: "#fff",
        minHeight: "100vh",
        fontFamily: "Inter",
      }}
    >
      <Box
        sx={{
          width: "100%",
          pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "85%" },
            position: "sticky",
            top: "0",
            zIndex: "999",
          }}
        >
          <Box
            sx={{ display: { xs: "flex" }, justifyContent: { xs: "center" } }}
          >
            <Box sx={{ width: { xs: "80%", md: "100%" } }}>
              <TopNav />
              {/* <Helmet>
                <title>Empowering Businesses in the Digital Marketplace | Brand Aura</title>
                <meta name="description" content="About empowering businesses in the digital marketplace with innovative strategies and tools to boost online presence, growth, and long-term success."></meta>
              </Helmet> */}
              <Analytics />
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "100%" } }}>
          <Element name="main_About">
            <FirstPage />
          </Element>
          <Element name="why-brand-aura">
            <SecondPage />
          </Element>
          <Element name="how-we-work">
            <AboutWork />
          </Element>
          <Element name="why-chose-us">
            <WhyChoseUs />
          </Element>
          <Element name="features">
            <section className="self-stretch flex flex-row items-start justify-start py-0 px-20 box-border max-w-full mq750:pl-10 mq750:pr-10 mq750:box-border">
              <div className="flex-1 flex flex-col items-start justify-start rounded-[10px] gap-[96px] bg-[#000] max-w-full mq750:gap-[48px] mq450:gap-[24px]">
                <ThirdPage />
              </div>
            </section>
          </Element>
        </Box>
        <Box sx={{ width: "100%", background: "#fff", py: 6 }}>
          <Element name="lastPage">
            {/* <section className="self-stretch flex flex-row items-start justify-start py-0 px-20 box-border max-w-full mq750:pl-10 mq750:pr-10 mq750:box-border">
                            <div className="flex-1 flex flex-col items-start justify-start rounded-[10px] gap-[96px] bg-[#000] max-w-full mq750:gap-[48px] mq450:gap-[24px]">
                                <Content />
                            </div>
                        </section> */}
            <LastPage />
          </Element>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          <Element name="about-us">
            <Footer />
          </Element>
        </Box>
      </Box>
    </Box>
  );
};
