import { Box, Typography } from "@mui/material";
import { TableComp6 } from "../../../../../components/table/type - 6";
import {
  ConfigStyleBar_OG,
  DesignDatas,
} from "../../../../../utils/data/IndividualDashboard/Data";
import { dateClnse } from "../../../../../components/Public/DateClnse";
import { DashedIcon, LineIcon } from "../../../../../utils/icon/IndividualPages/Icon";
import { TableComp5 } from "../../../../../components/table/type - 5";

export const generateTableData = (category, countries, ecommerces, brands) => {
  const tableData = [];

  // If any field is empty, add a default row with placeholders
  if (!category || !countries || !ecommerces.length || !brands.length) {
    tableData.push({
      Category: category || "-",
      Country: countries || "-",
      "E-Commerce": ecommerces.length ? ecommerces[0] : "-",
      Brand: brands.length ? brands[0] : "-",
    });
    return tableData;
  }
  tableData.push({
    Category: category,
    Country: countries,
    "E-Commerce": ecommerces,
    Brand: brands,
  });

  // ecommerces.forEach(ecommerce => {
  //     brands.forEach(brand => {
  //     });
  // });

  // Ensure at least 7 rows in the table by adding empty rows if needed
  // while (tableData.length < 7) {
  //     tableData.push({
  //         Category: '',
  //         Country: '',
  //         'E-Commerce': '',
  //         Brand: '',
  //     });
  // }

  return tableData;
};

export const generateTableData2 = (category, countries, ecommerces, brands, cfd) => {
  const tableData = [];

  // If any field is empty, add a default row with placeholders
  if (!category || !countries || !ecommerces.length || !brands.length) {
    tableData.push({
      Category: category || "-",
      Country: countries || "-",
      "E-Commerce": ecommerces.length ? ecommerces[0] : "-",
      Brand: brands.length ? brands[0] : "-",
    });
    return tableData;
  }
  tableData.push({
    Category: category,
    Country: countries,
    "E-Commerce": ecommerces,
    Brand: brands,
    Configured_Date: cfd,
  });

  // ecommerces.forEach(ecommerce => {
  //     brands.forEach(brand => {
  //     });
  // });

  // Ensure at least 7 rows in the table by adding empty rows if needed
  // while (tableData.length < 7) {
  //     tableData.push({
  //         Category: '',
  //         Country: '',
  //         'E-Commerce': '',
  //         Brand: '',
  //     });
  // }

  return tableData;
};

export const DataComponent = ({ selectedData, data }) => {
  const category = selectedData[data[0].arrayName] || "-";
  const countries = selectedData[data[1].arrayName] || ["-"];
  const ecommerces = selectedData[data[2].arrayName] || ["-"];
  const brands = selectedData[data[3].arrayName] || ["-"];
  const tableData = generateTableData(category, countries, ecommerces, brands);

  return (
    <Box>
      <TableComp6 Data={tableData} Style={ConfigStyleBar_OG} />
    </Box>
  );
};

export const DatePlace = ({ date }) => {
  return (
    <Typography
      sx={{
        p: 1,
        border: "0.8px solid #474747CC",
        borderRadius: "20px",
        px: 1,
        color: "#474747CC",
        mr: 1,
      }}
    >
      {dateClnse(date)}
    </Typography>
  );
};

export const TopDesign = ({ data }) => {
  console.log("ddl", DesignDatas.length);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: { xs: "space-between", sm: "space-between" },
        alignItems: "center",
        flexWrap: 'wrap',
      }}
    >
      {DesignDatas.map((e, i) => (
        <Box
          key={i}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: 'flex-start', sm: "space-around" },
            my: { xs: 0.5, sm: 0 },
            minWidth: DesignDatas.length !== i + 1 ? { xs: '35%', sm: "18%" } : { xs: '35%', sm: "8%" },
            marginRight: i < DesignDatas.length - 1 ? "1px" : "0", // Adjust spacing between items
          }}
        >
          <Box
            sx={{
              height: "30px",
              minWidth: "30px",
              zIndex: 3,
              borderRadius: "100%",
              display: "flex",
              mr: { xs: 0.5, sm: 0 },
              justifyContent: "center",
              alignItems: "center",
              background: data.state + 1 > i ? "#8A18D0" : "#47474799",
              color: "#fff",
            }}
          >
            {i + 1}
          </Box>
          <Typography sx={{ textWrap: 'nowrap' }}>{e}</Typography>
          {DesignDatas.length !== i + 1 && (
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
              {data.state + 1 > i ? <LineIcon /> : <DashedIcon />}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

// --------------------------------------------------------------------------------------------------------------------------------------------------------------

// const ConfigBar_2 = ({ open, setOpen, selectedData }) => {
//     const [state, setState] = useState(0);
//     return (
//         <Box sx={{ height: '100%', width: '100%' }}>
//             {state === 0 && (
//                 // <Box sx={{ height: 'calc(100vh - 64px)', width: '450px', p: 2, position: 'fixed', top: '60px', right: 0, background: '#fff', transform: open ? 'translateX(0)' : 'translateX(100%)', transition: 'transform 0.3s ease-in-out', borderLeft: '1px solid #BCBFC7' }}>
//                 <Box sx={{ height: '100%', width: '100%', p: 2, background: '#fff', borderLeft: '1px solid #BCBFC7' }}>
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                         <Button disableRipple onClick={() => setOpen(false)}>
//                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                 <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                     <path fill-rule="evenodd" clip-rule="evenodd" d="M0.207391 5.50221C0.0745985 5.36938 0 5.18925 0 5.00142C0 4.8136 0.0745985 4.63346 0.207391 4.50063L4.21443 0.49359C4.27977 0.425937 4.35793 0.371974 4.44435 0.334851C4.53077 0.297728 4.62372 0.278188 4.71777 0.27737C4.81182 0.276553 4.9051 0.294475 4.99215 0.330091C5.0792 0.365707 5.15829 0.418303 5.2248 0.48481C5.2913 0.551317 5.3439 0.630404 5.37951 0.717455C5.41513 0.804507 5.43305 0.89778 5.43223 0.991832C5.43142 1.08588 5.41188 1.17883 5.37475 1.26525C5.33763 1.35167 5.28367 1.42983 5.21602 1.49517L1.70976 5.00142L5.21602 8.50767C5.34504 8.64127 5.41644 8.82019 5.41483 9.00591C5.41321 9.19164 5.33872 9.3693 5.20739 9.50063C5.07606 9.63196 4.8984 9.70645 4.71267 9.70807C4.52695 9.70968 4.34802 9.63828 4.21443 9.50926L0.207391 5.50221Z" fill="black" />
//                                 </svg>
//                                 <Typography sx={{ pl: 1, fontWeight: '600', fontSize: '14px', color: '#000' }}>Configuration</Typography>
//                             </Box>
//                         </Button>
//                         <Button sx={{ background: '#8A18D0', color: '#fff', px: 4, '&:hover': { background: '#B37FFC' } }} onClick={() => setState(1)}>Bundle</Button>
//                     </Box>
//                     <Typography sx={{ pl: 1, fontWeight: '500', fontSize: '12px', py: 2, color: '#000' }}>Category Selected</Typography>
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//                         {selectedData[data[0].arrayName].map((e, i) => (
//                             <Box key={i} sx={{ background: '#F3E7FA', px: 2, py: 1, minWidth: '88px', minHeight: '28px', fontWeight: '500', fontSize: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', textWrap: 'nowrap', mx: 1, my: 1 }}>{e}</Box>
//                         ))}
//                     </Box>
//                     <Typography sx={{ pl: 1, fontWeight: '500', fontSize: '12px', py: 2, color: '#000' }}>Country Selected</Typography>
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//                         {selectedData[data[1].arrayName].map((e, i) => (
//                             <Box key={i} sx={{ background: '#F3E7FA', px: 2, py: 1, minWidth: '88px', minHeight: '28px', fontWeight: '500', fontSize: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', textWrap: 'nowrap', mx: 1, my: 1 }}>{e}</Box>
//                         ))}
//                     </Box>
//                     <Typography sx={{ pl: 1, fontWeight: '500', fontSize: '12px', py: 2, color: '#000' }}>Site Selected</Typography>
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//                         {selectedData[data[2].arrayName].map((e, i) => (
//                             <Box key={i} sx={{ background: '#F3E7FA', px: 2, py: 1, minWidth: '88px', minHeight: '28px', fontWeight: '500', fontSize: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', textWrap: 'nowrap', mx: 1, my: 1 }}>{e}</Box>
//                         ))}
//                     </Box>
//                     <Typography sx={{ pl: 1, fontWeight: '500', fontSize: '12px', py: 2, color: '#000' }}>Brand Selected</Typography>
//                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//                         {selectedData[data[3].arrayName].map((e, i) => (
//                             <Box key={i} sx={{ background: '#F3E7FA', px: 2, py: 1, minWidth: '88px', minHeight: '28px', fontWeight: '500', fontSize: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', textWrap: 'nowrap', mx: 1, my: 1 }}>{e}</Box>
//                         ))}
//                     </Box>
//                 </Box>
//             )}
//             {state === 1 && (
//                 <Box sx={{ height: 'calc(100vh - 64px)', width: '450px', p: 2, position: 'fixed', top: '60px', right: 0, background: '#fff', transform: open ? 'translateX(0)' : 'translateX(100%)', transition: 'transform 0.3s ease-in-out', borderLeft: '1px solid #BCBFC7' }}>
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
//                         <Button disableRipple onClick={() => setOpen(false)}>
//                             <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                                 <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                     <path fill-rule="evenodd" clip-rule="evenodd" d="M0.207391 5.50221C0.0745985 5.36938 0 5.18925 0 5.00142C0 4.8136 0.0745985 4.63346 0.207391 4.50063L4.21443 0.49359C4.27977 0.425937 4.35793 0.371974 4.44435 0.334851C4.53077 0.297728 4.62372 0.278188 4.71777 0.27737C4.81182 0.276553 4.9051 0.294475 4.99215 0.330091C5.0792 0.365707 5.15829 0.418303 5.2248 0.48481C5.2913 0.551317 5.3439 0.630404 5.37951 0.717455C5.41513 0.804507 5.43305 0.89778 5.43223 0.991832C5.43142 1.08588 5.41188 1.17883 5.37475 1.26525C5.33763 1.35167 5.28367 1.42983 5.21602 1.49517L1.70976 5.00142L5.21602 8.50767C5.34504 8.64127 5.41644 8.82019 5.41483 9.00591C5.41321 9.19164 5.33872 9.3693 5.20739 9.50063C5.07606 9.63196 4.8984 9.70645 4.71267 9.70807C4.52695 9.70968 4.34802 9.63828 4.21443 9.50926L0.207391 5.50221Z" fill="black" />
//                                 </svg>
//                                 <Typography sx={{ pl: 1, fontWeight: '600', fontSize: '14px', color: '#000' }}>Bundle</Typography>
//                             </Box>
//                         </Button>
//                         <Button sx={{ background: '#8A18D0', color: '#fff', px: 4, '&:hover': { background: '#B37FFC' } }} onClick={() => setState(0)}>Configuration</Button>
//                     </Box>
//                 </Box>
//             )}
//         </Box>
//     );
// }
