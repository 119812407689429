import { useDispatch } from 'react-redux';
import { Profile } from '../../../components/nav/corporate_normal_user_top_nav';
import {
  BundleOffersIcon,
  MakeLetterIcon,
  NewProductIcon,
  NewSellerIcon,
  Notify,
  NotiIcons,
  OthersIcon,
  PriceDropIcon,
  PriceUpIcon,
} from '../../icon/CorporateNormalUserDatas/Icon';
import { HomeSvg, QueriesSvg } from '../../icon/IndividualPages/Icon';
import { notSubscribed, subscribed } from '../../../slice/subscribed';
import http from '../../http-common';
import PowerTool from '../../../assets/powertools.png';
import P_img from '../../../assets/P_img.png';
import P_img_2 from '../../../assets/P_img_2.png';
import P_img_3 from '../../../assets/P_img_3.png';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parseProperly } from '../../../components/@extended/CustomFunctions';

// there is some issue with dispatch find and solve this issue, the issue is : Uncaught TypeError: Cannot read properties of null (reading 'useContext'), at this file 10th line that means it is usedispatch
export const SideNavCorporateNormal = ({ dispatch, setLoad, minimize }) => {
  const [d, setD] = useState(parseProperly(localStorage.getItem('DashboardData')))
  // const [d, setD] = useState([])
  // const [d, setD] = useState(() => {
  //   const storedData = localStorage.getItem('DashboardData');
  //   console.log('stdd',storedData, storedData ? storedData : [])
  //   if (storedData) {
  //       try {
  //           return JSON.parse(storedData);
  //       } catch (error) {
  //           console.error('Error parsing DashboardData from localStorage:', error);
  //           return []; // Return an empty array if parsing fails
  //       }
  //   }
  //   return []; 
  // });
  const location = useLocation();
  const [mz, setMz] = useState(false);
  const Get = async () => {
    try {
      if (d.length === 0) {
        setLoad(true)
      }
      const response = await http.post(
        '/master_service/dashboard/page',
        JSON.stringify({ user_id: localStorage.getItem('user_id') })
      );
      // console.log('response', response.data);
      setD(response?.data?.bundles || []);
      localStorage.setItem('DashboardData', JSON.stringify(response?.data?.bundles || []));
      setLoad(false)
    } catch (error) {
      console.error('Error fetching data in subnav:', error?.message);
      setLoad(false)
    }
  };
  useEffect(() => {
    Get();
  }, []);
  useEffect(() => {
    if (location.pathname === '/user/my-zone') {
      // console.log('ent true path');
      setMz(true);
    }
  }, [location]);

  return {
    p1: [
      {
        // icon: <MakeLetterIcon L={'b'} />,
        name: mz ? 'Bundle' : 'My Zone',
        to: mz ? '/user' : '/user/my-zone',
        bgColor: '#8A18D0',
        fn: () => {
          // dispatch(subscribed());
          setMz(pre => !pre)
        },
        hov: '#B37FFC',
        ListItemSx: { my: 1 },
        center: true,
      },
      ...(!mz && Array.isArray(d)
        ? d.map((e) => ({
          bndlId: e.ind_bundle_id,
          name: e.ind_bundle_name,
          drop: true,
          fn: () => {
            let [searchParams, setSearchParams] = useSearchParams();
            searchParams.set('ind_bundle_id', e.ind_bundle_id);
            setSearchParams(searchParams);
            console.log('ent', e, searchParams)
          },
          subNav: e.brands.map((k) => ({
            name: k.brand_name,
            to: `/user/Brands?category_name=${encodeURIComponent(
              e.category.category_name
            )}&brand_name=${encodeURIComponent(k?.brand_name)}&ind_bundle_id=${encodeURIComponent(
              e.ind_bundle_id
            )}&brand_id=${encodeURIComponent(k?.brand_id)}&card=${encodeURIComponent(0)}&site_id=${encodeURIComponent(k.ecommerce?.[0]?.site_id || '')}`,
            comp: true,
          })),
        }))
        : []),
    ],
    p2: [{ icon: <QueriesSvg />, name: !minimize ? 'Any Queries?' : 'Queries?', to: '/user/raise-ticket' }],
  };
};

// {
//   // icon: <MakeLetterIcon L={'b'} />,
//   name: 'Power Tools',
//   to: '',
//   // bgColor: '#8A18D0',
//   drop: true,
//   subNav: [
//     {
//       name: 'Bosch',
//       to: '/user/brands',
//       comp: true,
//     },
//     {
//       name: 'Makita',
//       to: '/user/brands',
//       comp: true,
//     },
//     {
//       name: 'Dewalt',
//       to: '/user/brands',
//       comp: true,
//     },
//   ],
//   // center: true,
// },
// {
//   // icon: <MakeLetterIcon L={'b'} />,
//   name: 'Smart Phone',
//   to: '',
//   // bgColor: '#8A18D0',
//   drop: true,
//   subNav: [
//     {
//       name: 'Bosch',
//       to: '',
//       comp: true,
//     },
//     {
//       name: 'Makita',
//       to: '',
//       comp: true,
//     },
//     {
//       name: 'Dewalt',
//       to: '',
//       comp: true,
//     },
//   ],
//   // center: true,
// },

export const SideNavCorporateNormalAE = (dispatch) => ({
  p1: [
    {
      // icon: <MakeLetterIcon L={'b'} />,
      name: 'Bundle',
      to: '/user',
      bgColor: '#8A18D0',
      hov: '#B37FFC',
      fn: () => {
        dispatch(notSubscribed());
      },
      center: true,
    },
  ],
  p2: [],
});

export const NavItmsCorporateNormal = {
  nav: [
    {
      icon: <HomeSvg />,
      to: "/user",
      nav: -1,
    },
    {
      icon: <Notify />,
      to: '/user/notification',
      nav: -1,
    },
    {
      icon: <Profile />,
    },
  ],
};

export const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const routesMap = [
  {
    map: '/user/Individual-Dashboard/raise-ticket',
    nav: true,
    no: 4,
  },
  // {
  //   map: "/admin",
  //   nav: false,
  //   no: 0,
  // },
  // {
  //   map: "/admin/users",
  //   nav: true,
  //   no: 0,
  // },
];
export const Data = [
  {
    brand: 'Bosch',
    Brand_Url: 'www.boschtools.com',
    subBrandDetails: [
      {
        Brand: 'Flipkart',
        // leading: "true",
        Sellers: '57',
        Products: '199',
        ratings: '3.5',
        min_p: '215',
        max_p: '899',
        sentiment: 'Very Good',
        NoOfRatings: '1,320',
      },
      {
        Brand: 'Amazon',
        // leading: 'true',
        Sellers: '33',
        Products: '18',
        ratings: '4.5',
        min_p: '250',
        max_p: '20,219',
        sentiment: 'Not Bad',
        NoOfRatings: '45,220',
      },
      {
        Brand: 'Indiamart',
        // leading: "true",
        Sellers: '9',
        Products: '29',
        ratings: '2.5',
        min_p: '1,000',
        max_p: '99,080',
        sentiment: 'Poor',
        NoOfRatings: '22,220',
      },
      {
        Brand: 'Ebay',
        // leading: "true",
        Sellers: '9',
        Products: '29',
        ratings: '2.5',
        min_p: '2,000',
        max_p: '29,080',
        sentiment: 'Poor',
        NoOfRatings: '2,220',
      },
    ],
    productTableDatas: [
      {
        product_name: 'Bosch Professional GSR 120-LI Cordless Drill Driver',
        min_p: 7350,
        max_p: 7500,
        avg_p: 7349.67,
        mrp: 30000,
        discount: 10,
        rating: 4.5,
        reviews: 100,
        stock: true,
        delivery_time: '2-3 days',
        seller: 'Dynamic ECommerce',
        warranty: '1 year',
      },
      {
        product: 'Bosch GRO 12V-35 Heavy Duty Cordless Rotary Tool',
        min_p: 7279,
        max_p: 7275,
        avg_p: 7281.33,
        mrp: 7279,
        discount: 12,
        rating: 3,
        reviews: 500,
        stock: true,
        delivery_time: '3-4 days',
        seller: 'ARPANA ENTERPRISES',
        warranty: '2 years',
      },
      {
        product: 'Bosch GBL 620-Watt Air Blower (Blue)',
        min_p: 3117,
        max_p: 3119,
        avg_p: 3118,
        mrp: 3118,
        discount: 10,
        rating: 5,
        reviews: 1000,
        stock: true,
        delivery_time: '2-3 days',
        seller: 'S P KOTHARI & CO',
        warranty: '1 year',
      },
      {
        product: 'Bosch GEX 125-1 AE Heavy Duty Electric Random Orbit Sander',
        min_p: 7150,
        max_p: 6999,
        avg_p: 7085.67,
        mrp: 6829,
        discount: 20,
        rating: 5,
        reviews: 300,
        stock: true,
        delivery_time: '4-5 days',
        seller: 'Clicktech Retail',
        warranty: '1 year',
      },
      {
        product: 'BOSCH GSB 10 RE Kit Power & Hand Tool Kit (100 Tools)',
        min_p: 2687,
        max_p: 3081,
        avg_p: 3245,
        mrp: 2687,
        discount: 10,
        rating: 4.4,
        reviews: 1050,
        stock: true,
        delivery_time: '2-3 days',
        seller: 'INDITOOLS',
        warranty: '1/2 year',
      },
      {
        product: 'BOSCH GBL620 Air Blower (Corded)',
        min_p: 3479,
        max_p: 4099,
        avg_p: 3545.86,
        mrp: 3103,
        discount: 10,
        rating: 4.8,
        reviews: 800,
        stock: true,
        delivery_time: '5-6 days',
        seller: 'HomeIndustrySolutions',
        warranty: '1 year',
      },
      {
        product: 'BOSCH GHO 6500 Corded Planer (0-9mm mm)',
        min_p: 9799,
        max_p: 7895,
        avg_p: 8736.6,
        mrp: 7540,
        discount: 15,
        rating: 3.8,
        reviews: 1000,
        stock: true,
        delivery_time: '2-3 days',
        seller: 'DDE',
        warranty: '1 year',
      },
      // add more products for Flipkart
    ],
  },
  {
    brand: 'Makita',
    Brand_Url: 'www.makita.com',
    subBrandDetails: [
      {
        Brand: 'Flipkart',
        leading: 'true',
        Sellers: '56',
        Products: '40',
        ratings: '3.5',
        min_p: '1,258',
        max_p: '15,400',
        sentiment: 'Very Good',
        NoOfRatings: '1,320',
      },
      {
        Brand: 'Amazon',
        // leading: 'true',
        Sellers: '39',
        Products: '27',
        ratings: '4.5',
        min_p: '2,200',
        max_p: '61,390',
        sentiment: 'Not Bad',
        NoOfRatings: '45,220',
      },
      {
        Brand: 'Indiamart',
        leading: 'true',
        Sellers: '47',
        Products: '59',
        ratings: '2.5',
        min_p: '3,100',
        max_p: '22,000',
        sentiment: 'Poor',
        NoOfRatings: '22,220',
      },
      {
        Brand: 'Ebay',
        // leading: 'true',
        Sellers: '33',
        Products: '18',
        ratings: '4.5',
        min_p: '250',
        max_p: '20,219',
        sentiment: 'Not Bad',
        NoOfRatings: '45,220',
      },
    ],
  },

  {
    brand: 'Dewalt',
    Brand_Url: 'www.dewalt.com',
    subBrandDetails: [
      {
        Brand: 'Flipkart',
        leading: 'true',
        Sellers: '54',
        Products: '40',
        ratings: '3.5',
        min_p: '255',
        max_p: '34,000',
        sentiment: 'Very Good',
        NoOfRatings: '1,320',
      },
      {
        Brand: 'Amazon',
        // leading: 'true',
        Sellers: '33',
        Products: '10',
        ratings: '4.5',
        min_p: '2,500',
        max_p: '44,000',
        sentiment: 'Not Bad',
        NoOfRatings: '45,220',
      },
      {
        Brand: 'Indiamart',
        leading: 'true',
        Sellers: '47',
        Products: '59',
        ratings: '2.5',
        min_p: '500',
        max_p: '50,000',
        sentiment: 'Poor',
        NoOfRatings: '22,220',
      },
      {
        Brand: 'Ebay',
        leading: 'true',
        Sellers: '47',
        Products: '59',
        ratings: '2.5',
        min_p: '3,100',
        max_p: '22,000',
        sentiment: 'Poor',
        NoOfRatings: '22,220',
      },
    ],
  },
];

export const CorporateNormalStyleBar = {
  OutBox: {
    borderRadius: '0',
    // borderRadius: 'inherit',
    // borderTopLeftRadius: 0,
    // borderTopRightRadius: 0,
  },
  table: {
    borderRadius: '8px',
    width: '100%',
    // my: 2,
    // tableLayout: 'fixed', // Add this to fix the table layout
  },
  head: {
    background: '#8A18D01A',
    borderRadius: '8px',
  },
  headRow: {},
  headCell: {
    fontWeight: '600',
    fontSize: '14px',
    color: '#2B2B2B',
    textWrap: 'nowrap',
    textAlign: 'left',
    padding: '8px', // Add some padding to the header cells
  },
  body: {
    width: '100%',
  },
  row: {},
  cell: {
    fontWeight: '500',
    fontSize: '12px',
    color: '#2B2B2B',
    textAlign: 'left',
    padding: '8px', // Add some padding to the cells
    width: 'auto', // Prevent text from wrapping
  },
  isThreeDot: true,
  threeDot: {
    display: 'flex',
    justifyContent: 'end',
    // width: '25px',
  },
};

export const CorporateNormalStyleBar2 = {
  OutBox: {
    borderRadius: 'inherit',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  table: {
    borderRadius: '8px',
    width: '100%',
    // my: 2,
    // tableLayout: 'fixed', // Add this to fix the table layout
  },
  head: {
    background: '#8A18D01A',
    borderRadius: '8px',
  },
  headRow: {},
  headCell: {
    fontWeight: '600',
    fontSize: '14px',
    color: '#2B2B2B',
    textWrap: 'nowrap',
    textAlign: 'center',
    padding: '8px', // Add some padding to the header cells
  },
  body: {
    width: '100%',
  },
  row: {},
  cell: {
    fontWeight: '500',
    fontSize: '12px',
    color: '#2B2B2B',
    textAlign: 'center',
    padding: '8px', // Add some padding to the cells
    width: 'auto', // Prevent text from wrapping
  },
  isThreeDot: true,
  threeDot: {
    display: 'flex',
    justifyContent: 'end',
    // width: '25px',
  },
};
export const AllSellerData = [
  {
    seller_name: 'Clicktech Ratail',
    product_count: '32',
    seller_reviews: '1,283',
    Ratings: '141',
  },
  {
    seller_name: 'Toolsmyne',
    product_count: '32',
    seller_reviews: '2,183',
    Ratings: '41.2',
  },
  {
    seller_name: 'Diplomat',
    product_count: '32',
    seller_reviews: '283',
    Ratings: '2.5',
  },
  {
    seller_name: 'N N Power Tools',
    product_count: '32',
    seller_reviews: '812',
    Ratings: '41',
  },
  {
    seller_name: 'Vivek Electronics',
    product_count: '32',
    seller_reviews: '654',
    Ratings: '4',
  },
  {
    seller_name: 'AL Hussaini Machinary Company Private Limited',
    product_count: '32',
    seller_reviews: '1,283',
    Ratings: '41',
  },
  {
    seller_name: 'Clicktech Retail',
    product_count: '32',
    seller_reviews: '2,183',
    Ratings: '41',
  },
  {
    seller_name: 'Toolsmyne',
    product_count: '32',
    seller_reviews: '283',
    Ratings: '4.5',
  },
  {
    seller_name: 'Diplomat',
    product_count: '32',
    seller_reviews: '283',
    Ratings: '41',
  },
  {
    seller_name: 'N N Power Tools',
    product_count: '32',
    seller_reviews: '283',
    Ratings: '41',
  },
];

export const AllProductData = [
  {
    product_name: 'Bosch Professional GWS 800 Corded Electric Angle Grinder, M10, 800W',
    no_of_sellers: '32',
    max_price: '1,234',
    min_price: '1,234',
    mrp: '1,234',
  },
  {
    product_name: 'Bosch go 2.0 - kit professional cordless screwdriver',
    no_of_sellers: '32',
    max_price: '234',
    min_price: '234',
    mrp: '234',
  },
  {
    product_name: 'Bosch WC-GP-2-26B Corded Electric Rotary Hammer',
    no_of_sellers: '32',
    max_price: '1,234',
    min_price: '1,234',
    mrp: '1,234',
  },
  {
    product_name: 'Bosch GDC 120 Corded Electric Diamond Tile / Marble Cutter, 1,200W, 12,000 rpm',
    no_of_sellers: '32',
    max_price: '834',
    min_price: '8234',
    mrp: '8234',
  },
  {
    product_name: 'Bosch Professional GWS 600 Angle Grinder - 670W, 100mm, M10 (Blue), Multipurpose',
    no_of_sellers: '32',
    max_price: '734',
    min_price: '734',
    mrp: '734',
  },
  {
    product_name: 'Bosch Professional GWS 800 Corded Electric Angle Grinder, M10, 800W',
    no_of_sellers: '32',
    max_price: '1,234',
    min_price: '1,234',
    mrp: '1,234',
  },
  {
    product_name: 'Bosch go 2.0 - kit professional cordless screwdriver',
    no_of_sellers: '32',
    max_price: '2,234',
    min_price: '2,234',
    mrp: '2,234',
  },
  {
    product_name: 'Bosch Professional GWS 800 Corded Electric Angle Grinder, M10, 800W',
    no_of_sellers: '32',
    max_price: '1,234',
    min_price: '1,234',
    mrp: '1,234',
  },
  {
    product_name: 'Bosch go 2.0 - kit professional cordless screwdriver',
    no_of_sellers: '32',
    max_price: '3,234',
    min_price: '3,234',
    mrp: '3,234',
  },
  {
    product_name: 'Bosch Professional GWS 800 Corded Electric Angle Grinder, M10, 800W',
    no_of_sellers: '32',
    max_price: '1,234',
    min_price: '1,234',
    mrp: '1,234',
  },
];

export const Pimgs = [{ img: PowerTool }, { img: P_img }, { img: P_img_2 }, { img: P_img_3 }];

export const NotificationData = [
  {
    img: NotiIcons.Discount,
    title: 'Discount and Offers',
    description: 'Found new offers and discounts in your interests.',
    date: '15-05-24',
  },
  {
    img: NotiIcons.PriceDrop,
    title: 'Price Drop ',
    description: '50% Price drop on your subscribed product. Grab it now!',
    date: '15-05-24',
  },
  {
    img: NotiIcons.PriceUp,
    title: 'Price Rocketed ...🚀',
    description: 'The price has been increased 20%.',
    date: '15-05-24',
  },
  {
    img: NotiIcons.NewSeller,
    title: 'New Seller',
    description: 'Your subscribed product has a new seller, do check it now!!!',
    date: '15-05-24',
  },
  {
    img: NotiIcons.Stock,
    title: 'Stock Available',
    description: 'Grab your product, it has less stock available',
    date: '15-05-24',
  },
  {
    img: NotiIcons.Stock,
    title: 'Oops!!!',
    description: 'The stock is unavailable at your location.',
    date: '15-05-24',
  },
];

export const SampleDescription = [
  {
    title: 'Product Description',
    content: [
      'About this item High-Powered 2-In-1 For less: Power and durability within reach for screw-Driving and drilling. Designed for wood and metal work. Comes with led light for a clear and brighter view Max. Drilling diameter in wood - 20 mm; In steel - 10 mm; Max. Screw diameter - 8 mm. Easy and precise control of rpm thanks to variable speed, no-Load speed (1st/2nd gear) - 0-400 / 0-1500 Rpm',
      '',
      'High performance: Torque (soft/hard/max.) - 13/30/- Nm. +20% Soft torque improvements. With torque settings at 20+1, 2-Speed gearbox enables high productivity and excellent torque Autolock chuck - Chuck capacity, min/max. – 1.5/10 Mm, tool holder - Three-Jaw chuck.',
      '',
      'Battery voltage and type- 12V lithium-Ion, electronic cell protection for longer battery life and more durability. Weight excl. Battery - 0.8 Kg Warranty – 1 year from the date of invoice (provide invoice copy to claim warranty);',
      '',
      'For any queries please contact Bosch power tools India customer service number (toll free). Join Bosch beconnected for extended warranty & rewards.',
      '',
      'Package contents: 1 Charger GAL 1210 cv, 2 x GBA 12v 2.0Ah battery, accessory set - 6 Metal drill bit, 4 wood drill bit, 10 screw driver bit and 1 screw driver bit holder,',
    ],
  },
];

export const CategoryD = [
  {
    ikn: PriceUpIcon,
    rawText: 'Price Rocked',
    text: 'Price Rocked... 📈',
    subText: '3 Notifications',
  },
  {
    ikn: PriceDropIcon,
    rawText: 'Price Dropped',
    text: 'Price Dropped... 📉',
    subText: '3 Notifications',
  },
  {
    ikn: NewSellerIcon,
    rawText: 'New Seller',
    text: 'New Seller!!! 🧑‍💻',
    subText: '3 Notifications',
  },
  {
    ikn: NewProductIcon,
    rawText: 'New Product',
    text: 'New Product!!! 🚀 ',
    subText: '2 Notifications',
  },
  // {
  //   ikn: BundleOffersIcon,
  //   rawText: 'Bundle offers',
  //   text: 'Bundle offers 🎁',
  //   subText: '2 Notifications',
  // },
  {
    ikn: OthersIcon,
    rawText: 'Others',
    text: 'Others...',
    subText: '2 Notifications',
  },
];
export const NotifiD = [
  {
    text: 'Samsung',
    subText: 'New Product from Samsung!!!',
    time: '30 Min Ago',
    prodName: 'Samsung M24 Ultra',
    EcommName: 'Amazon',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 0,
  },
  {
    text: 'Nokia',
    subText: 'New Product from Nokia!!!',
    time: '30 Min Ago',
    prodName: 'Nokia M24 Ultra',
    EcommName: 'Ebay',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 1,
  },
  {
    text: 'Apple',
    subText: 'New Product from Apple!!!',
    time: '30 Min Ago',
    prodName: 'Apple M24 Ultra',
    EcommName: 'Flipkart',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 2,
  },
  {
    text: 'Samsung',
    subText: 'New Product from Samsung!!!',
    time: '30 Min Ago',
    prodName: 'Samsung M24 Ultra',
    EcommName: 'Amazon',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 0,
  },
  {
    text: 'Nokia',
    subText: 'New Product from Nokia!!!',
    time: '30 Min Ago',
    prodName: 'Nokia M24 Ultra',
    EcommName: 'Ebay',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 1,
  },
  {
    text: 'Apple',
    subText: 'New Product from Apple!!!',
    time: '30 Min Ago',
    prodName: 'Apple M24 Ultra',
    EcommName: 'Flipkart',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 2,
  },
  {
    text: 'Samsung',
    subText: 'New Product from Samsung!!!',
    time: '30 Min Ago',
    prodName: 'Samsung M24 Ultra',
    EcommName: 'Amazon',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 0,
  },
  {
    text: 'Nokia',
    subText: 'New Product from Nokia!!!',
    time: '30 Min Ago',
    prodName: 'Nokia M24 Ultra',
    EcommName: 'Ebay',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 1,
  },
  {
    text: 'Apple',
    subText: 'New Product from Apple!!!',
    time: '30 Min Ago',
    prodName: 'Apple M24 Ultra',
    EcommName: 'Flipkart',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 5,
  },
  {
    text: 'Samsung',
    subText: 'New Product from Samsung!!!',
    time: '30 Min Ago',
    prodName: 'Samsung M24 Ultra',
    EcommName: 'Amazon',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 3,
  },
  {
    text: 'Nokia',
    subText: 'New Product from Nokia!!!',
    time: '30 Min Ago',
    prodName: 'Nokia M24 Ultra',
    EcommName: 'Ebay',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 3,
  },
  {
    text: 'Apple',
    subText: 'New Product from Apple!!!',
    time: '30 Min Ago',
    prodName: 'Apple M24 Ultra',
    EcommName: 'Flipkart',
    Seller: 'Seller Retailer',
    prodPrice: '₹ 88,586',
    category: 4,
  },
];

export const SunburstDatas = {
  name: '',
  size: 0,
  color: 'white',
  children: [
    {
      name: 'Makita',
      size: 1,
      color: '#8A18D0',
      children: [
        {
          name: 'Ebay',
          size: 1,
          color: '#8A18D080',
          children: []
        },
        {
          name: 'Amazon',
          size: 1,
          color: '#8A18D080',
          children: []
        },
        {
          name: 'Flipkart',
          size: 1,
          color: '#8A18D080',
          children: []
        },
        {
          name: 'Indiamart',
          size: 1,
          color: '#8A18D080',
          children: []
        },
      ]
    },
    {
      name: 'Ibell',
      size: 1,
      color: '#2BA1DB',
      children: [
        {
          name: 'Ebay',
          size: 1,
          color: '#2BA1DB80',
          children: []
        },
        {
          name: 'Amazon',
          size: 1,
          color: '#2BA1DB80',
          children: []
        },
        {
          name: 'Flipkart',
          size: 1,
          color: '#2BA1DB80',
          children: []
        },
        {
          name: 'Indiamart',
          size: 1,
          color: '#2BA1DB80',
          children: []
        },
      ]
    },
    {
      name: 'Dewalt',
      size: 1,
      color: '#F68D2B',
      children: [
        {
          name: 'Ebay',
          size: 1,
          color: '#F68D2B80',
          children: []
        },
        {
          name: 'Amazon',
          size: 1,
          color: '#F68D2B80',
          children: []
        },
        {
          name: 'Flipkart',
          size: 1,
          color: '#F68D2B80',
          children: []
        },
        {
          name: 'Indiamart',
          size: 1,
          color: '#F68D2B80',
          children: []
        },
      ]
    },
  ]
}
export const SunburstDatas2 = {
  "name": "flare",
  "children": [
    {
      "name": "analytics",
      "children": [
        {
          "name": "cluster",
          "children": [
            { "name": "AgglomerativeCluster", "size": 3938 },
            { "name": "CommunityStructure", "size": 3812 },
            { "name": "HierarchicalCluster", "size": 6714 },
            { "name": "MergeEdge", "size": 743 }
          ]
        },
        {
          "name": "graph",
          "children": [
            { "name": "BetweennessCentrality", "size": 3534 },
            { "name": "LinkDistance", "size": 5731 },
            { "name": "MaxFlowMinCut", "size": 7840 },
            { "name": "ShortestPaths", "size": 5914 },
            { "name": "SpanningTree", "size": 3416 }
          ]
        },
        {
          "name": "optimization",
          "children": [{ "name": "AspectRatioBanker", "size": 7074 }]
        }
      ]
    },
    {
      "name": "animate",
      "children": [
        { "name": "Easing", "size": 17010 },
        { "name": "FunctionSequence", "size": 5842 },
        {
          "name": "interpolate",
          "children": [
            { "name": "ArrayInterpolator", "size": 1983 },
            { "name": "ColorInterpolator", "size": 2047 },
            { "name": "DateInterpolator", "size": 1375 },
            { "name": "Interpolator", "size": 8746 },
            { "name": "MatrixInterpolator", "size": 2202 },
            { "name": "NumberInterpolator", "size": 1382 },
            { "name": "ObjectInterpolator", "size": 1629 },
            { "name": "PointInterpolator", "size": 1675 },
            { "name": "RectangleInterpolator", "size": 2042 }
          ]
        },
        { "name": "ISchedulable", "size": 1041 },
        { "name": "Parallel", "size": 5176 },
        { "name": "Pause", "size": 449 },
        { "name": "Scheduler", "size": 5593 },
        { "name": "Sequence", "size": 5534 },
        { "name": "Transition", "size": 9201 },
        { "name": "Transitioner", "size": 19975 },
        { "name": "TransitionEvent", "size": 1116 },
        { "name": "Tween", "size": 6006 }
      ]
    },
    {
      "name": "data",
      "children": [
        {
          "name": "converters",
          "children": [
            { "name": "Converters", "size": 721 },
            { "name": "DelimitedTextConverter", "size": 4294 },
            { "name": "GraphMLConverter", "size": 9800 },
            { "name": "IDataConverter", "size": 1314 },
            { "name": "JSONConverter", "size": 2220 }
          ]
        },
        { "name": "DataField", "size": 1759 },
        { "name": "DataSchema", "size": 2165 },
        { "name": "DataSet", "size": 586 },
        { "name": "DataSource", "size": 3331 },
        { "name": "DataTable", "size": 772 },
        { "name": "DataUtil", "size": 3322 }
      ]
    },
    {
      "name": "display",
      "children": [
        { "name": "DirtySprite", "size": 8833 },
        { "name": "LineSprite", "size": 1732 },
        { "name": "RectSprite", "size": 3623 },
        { "name": "TextSprite", "size": 10066 }
      ]
    },
    {
      "name": "flex",
      "children": [{ "name": "FlareVis", "size": 4116 }]
    },
    {
      "name": "physics",
      "children": [
        { "name": "DragForce", "size": 1082 },
        { "name": "GravityForce", "size": 1336 },
        { "name": "IForce", "size": 319 },
        { "name": "NBodyForce", "size": 10498 },
        { "name": "Particle", "size": 2822 },
        { "name": "Simulation", "size": 9983 },
        { "name": "Spring", "size": 2213 },
        { "name": "SpringForce", "size": 1681 }
      ]
    },
    {
      "name": "query",
      "children": [
        { "name": "AggregateExpression", "size": 1616 },
        { "name": "And", "size": 1027 },
        { "name": "Arithmetic", "size": 3891 },
        { "name": "Average", "size": 891 },
        { "name": "BinaryExpression", "size": 2893 },
        { "name": "Comparison", "size": 5103 },
        { "name": "CompositeExpression", "size": 3677 },
        { "name": "Count", "size": 781 },
        { "name": "DateUtil", "size": 4141 },
        { "name": "Distinct", "size": 933 },
        { "name": "Expression", "size": 5130 },
        { "name": "ExpressionIterator", "size": 3617 },
        { "name": "Fn", "size": 3240 },
        { "name": "If", "size": 2732 },
        { "name": "IsA", "size": 2039 },
        { "name": "Literal", "size": 1214 },
        { "name": "Match", "size": 3748 },
        { "name": "Maximum", "size": 843 },
        {
          "name": "methods",
          "children": [
            { "name": "add", "size": 593 },
            { "name": "and", "size": 330 },
            { "name": "average", "size": 287 },
            { "name": "count", "size": 277 },
            { "name": "distinct", "size": 292 },
            { "name": "div", "size": 595 },
            { "name": "eq", "size": 594 },
            { "name": "fn", "size": 460 },
            { "name": "gt", "size": 603 },
            { "name": "gte", "size": 625 },
            { "name": "iff", "size": 748 },
            { "name": "isa", "size": 461 },
            { "name": "lt", "size": 597 },
            { "name": "lte", "size": 619 },
            { "name": "max", "size": 283 },
            { "name": "min", "size": 283 },
            { "name": "mod", "size": 591 },
            { "name": "mul", "size": 603 },
            { "name": "neq", "size": 599 },
            { "name": "not", "size": 386 },
            { "name": "or", "size": 323 },
            { "name": "orderby", "size": 307 },
            { "name": "range", "size": 772 },
            { "name": "select", "size": 296 },
            { "name": "stddev", "size": 363 },
            { "name": "sub", "size": 600 },
            { "name": "sum", "size": 280 },
            { "name": "update", "size": 307 },
            { "name": "variance", "size": 335 },
            { "name": "where", "size": 299 },
            { "name": "xor", "size": 354 },
            { "name": "_", "size": 264 }
          ]
        },
        { "name": "Minimum", "size": 843 },
        { "name": "Not", "size": 1554 },
        { "name": "Or", "size": 970 },
        { "name": "Query", "size": 13896 },
        { "name": "Range", "size": 1594 },
        { "name": "StringUtil", "size": 4130 },
        { "name": "Sum", "size": 791 },
        { "name": "Variable", "size": 1124 },
        { "name": "Variance", "size": 1876 },
        { "name": "Xor", "size": 1101 }
      ]
    },
    {
      "name": "scale",
      "children": [
        { "name": "IScaleMap", "size": 2105 },
        { "name": "LinearScale", "size": 1316 },
        { "name": "LogScale", "size": 3151 },
        { "name": "OrdinalScale", "size": 3770 },
        { "name": "QuantileScale", "size": 2435 },
        { "name": "QuantitativeScale", "size": 4839 },
        { "name": "RootScale", "size": 1756 },
        { "name": "Scale", "size": 4268 },
        { "name": "ScaleType", "size": 1821 },
        { "name": "TimeScale", "size": 5833 }
      ]
    },
    {
      "name": "util",
      "children": [
        { "name": "Arrays", "size": 8258 },
        { "name": "Colors", "size": 10001 },
        { "name": "Dates", "size": 8217 },
        { "name": "Displays", "size": 12555 },
        { "name": "Filter", "size": 2324 },
        { "name": "Geometry", "size": 10993 },
        {
          "name": "heap",
          "children": [
            { "name": "FibonacciHeap", "size": 9354 },
            { "name": "HeapNode", "size": 1233 }
          ]
        },
        { "name": "IEvaluable", "size": 335 },
        { "name": "IPredicate", "size": 383 },
        { "name": "IValueProxy", "size": 874 },
        {
          "name": "math",
          "children": [
            { "name": "DenseMatrix", "size": 3165 },
            { "name": "IMatrix", "size": 2815 },
            { "name": "SparseMatrix", "size": 3366 }
          ]
        },
        { "name": "Maths", "size": 17705 },
        { "name": "Orientation", "size": 1486 },
        {
          "name": "palette",
          "children": [
            { "name": "ColorPalette", "size": 6367 },
            { "name": "Palette", "size": 1229 },
            { "name": "ShapePalette", "size": 2059 },
            { "name": "SizePalette", "size": 2291 }
          ]
        },
        { "name": "Property", "size": 5559 },
        { "name": "Shapes", "size": 19118 },
        { "name": "Sort", "size": 6887 },
        { "name": "Stats", "size": 6557 },
        { "name": "Strings", "size": 22026 }
      ]
    },
    {
      "name": "vis",
      "children": [
        {
          "name": "axis",
          "children": [
            { "name": "Axes", "size": 1302 },
            { "name": "Axis", "size": 24593 },
            { "name": "AxisGridLine", "size": 652 },
            { "name": "AxisLabel", "size": 636 },
            { "name": "CartesianAxes", "size": 6703 }
          ]
        },
        {
          "name": "controls",
          "children": [
            { "name": "AnchorControl", "size": 2138 },
            { "name": "ClickControl", "size": 3824 },
            { "name": "Control", "size": 1353 },
            { "name": "ControlList", "size": 4665 },
            { "name": "DragControl", "size": 2649 },
            { "name": "ExpandControl", "size": 2832 },
            { "name": "HoverControl", "size": 4896 },
            { "name": "IControl", "size": 763 },
            { "name": "PanZoomControl", "size": 5222 },
            { "name": "SelectionControl", "size": 7862 },
            { "name": "TooltipControl", "size": 8435 }
          ]
        },
        {
          "name": "data",
          "children": [
            { "name": "Data", "size": 20544 },
            { "name": "DataList", "size": 19788 },
            { "name": "DataSprite", "size": 10349 },
            { "name": "EdgeSprite", "size": 3301 },
            { "name": "NodeSprite", "size": 19382 },
            {
              "name": "render",
              "children": [
                { "name": "ArrowType", "size": 698 },
                { "name": "EdgeRenderer", "size": 5569 },
                { "name": "IRenderer", "size": 353 },
                { "name": "ShapeRenderer", "size": 2247 }
              ]
            },
            { "name": "ScaleBinding", "size": 11275 },
            { "name": "Tree", "size": 7147 },
            { "name": "TreeBuilder", "size": 9930 }
          ]
        },
        {
          "name": "events",
          "children": [
            { "name": "DataEvent", "size": 2313 },
            { "name": "SelectionEvent", "size": 1880 },
            { "name": "TooltipEvent", "size": 1701 },
            { "name": "VisualizationEvent", "size": 1117 }
          ]
        },
        {
          "name": "legend",
          "children": [
            { "name": "Legend", "size": 20859 },
            { "name": "LegendItem", "size": 4614 },
            { "name": "LegendRange", "size": 10530 }
          ]
        },
        {
          "name": "operator",
          "children": [
            {
              "name": "distortion",
              "children": [
                { "name": "BifocalDistortion", "size": 4461 },
                { "name": "Distortion", "size": 6314 },
                { "name": "FisheyeDistortion", "size": 3444 }
              ]
            },
            {
              "name": "encoder",
              "children": [
                { "name": "ColorEncoder", "size": 3179 },
                { "name": "Encoder", "size": 4060 },
                { "name": "PropertyEncoder", "size": 4138 },
                { "name": "ShapeEncoder", "size": 1690 },
                { "name": "SizeEncoder", "size": 1830 }
              ]
            },
            {
              "name": "filter",
              "children": [
                { "name": "FisheyeTreeFilter", "size": 5219 },
                { "name": "GraphDistanceFilter", "size": 3165 },
                { "name": "VisibilityFilter", "size": 3509 }
              ]
            },
            { "name": "IOperator", "size": 1286 },
            {
              "name": "label",
              "children": [
                { "name": "Labeler", "size": 9956 },
                { "name": "RadialLabeler", "size": 3899 },
                { "name": "StackedAreaLabeler", "size": 3202 }
              ]
            },
            {
              "name": "layout",
              "children": [
                { "name": "AxisLayout", "size": 6725 },
                { "name": "BundledEdgeRouter", "size": 3727 },
                { "name": "CircleLayout", "size": 9317 },
                { "name": "CirclePackingLayout", "size": 12003 },
                { "name": "DendrogramLayout", "size": 4853 },
                { "name": "ForceDirectedLayout", "size": 8411 },
                { "name": "IcicleTreeLayout", "size": 4864 },
                { "name": "IndentedTreeLayout", "size": 3174 },
                { "name": "Layout", "size": 7881 },
                { "name": "NodeLinkTreeLayout", "size": 12870 },
                { "name": "PieLayout", "size": 2728 },
                { "name": "RadialTreeLayout", "size": 12348 },
                { "name": "RandomLayout", "size": 870 },
                { "name": "StackedAreaLayout", "size": 9121 },
                { "name": "TreeMapLayout", "size": 9191 }
              ]
            },
            { "name": "Operator", "size": 2490 },
            { "name": "OperatorList", "size": 5248 },
            { "name": "OperatorSequence", "size": 4190 },
            { "name": "OperatorSwitch", "size": 2581 },
            { "name": "SortOperator", "size": 2023 }
          ]
        },
        { "name": "Visualization", "size": 16540 }
      ]
    }
  ]
}



export const SankeyData = [
  ["From", "To", "Weight"],
  ['Ibell', 'Amazon', 16],
  ['Makita', 'Flipkart', 21],
  ['Makita', 'Indiamart', 7],
  ['Dewalt', 'Ebay', 1],
  // ['Amazon', 'Brand Products', 1],
  // ['Amazon', 'Other Products', 1],
  // ['Flipkart', 'Brand Products', 1],
  // ['Flipkart', 'Other Products', 1],
  // ['Indiamart', 'Brand Products', 1],
  // ['Indiamart', 'Other Products', 1],
  // ['Ebay', 'Brand Products', 1],
  // ['Ebay', 'Other Products', 1],
];

export const ReportColor = [
  '#2BA1DB', '#8A18D0', '#F68D2B', '#a33aeb', '#9eb9ab', '#75f7e5',
  '#84705c', '#7eb9f2', '#d39314', '#9d4863', '#9ab719', '#00a82b',
  '#848d31', '#210ca4', '#5ad5e8', '#7a85b8', '#28524f', '#6b3232',
  '#192072', '#64c683', '#f52008', '#94ee73', '#6e7d35', '#c419b1',
  '#b8b693', '#711951', '#011118', '#7f2f76', '#17bb0f', '#9d343d',
  '#3aa769', '#738680', '#d7bae1', '#2b50ac', '#c2b542', '#2f56f7',
  '#cd2e49', '#a2aa39', '#d3ef37', '#6d576d', '#35d2ce', '#4e21ff',
  '#969bbe', '#d850ed', '#c40be3', '#0b24a3', '#6a8812', '#5fd81f',
  '#1ce514', '#abe7f7', '#2e8ff6', '#e18a69', '#9da362', '#beb657',
  '#4c9b41', '#e5f658', '#1b8ade', '#e09811', '#813fef', '#b47650',
  '#38b101', '#4c4831', '#13941f', '#f23c0f', '#6049b9', '#c99830',
  '#83a51c', '#09db90', '#477a74', '#269491', '#e2a93e', '#5917cd',
  '#80c9f8', '#7936af', '#2bc395', '#456e86', '#ae6367', '#76e0a3',
  '#e4afdb', '#137fff', '#b7ca6b', '#bfcfde', '#259173', '#3b83cb',
  '#23cae7', '#00b2c9', '#eeda6b', '#6b5788', '#4af874', '#62e3e7',
  '#bcac5a', '#5a319d', '#9f882d', '#52b10a', '#763f1b', '#5f2c2f',
  '#84d8c3', '#5f0cd5', '#07c295', '#0a3ba1', '#4dd7a2', '#e5f2f4',
  '#98a1c8', '#6c4927', '#5fc657', '#c44b8f', '#8fdf2b', '#a19920',
  '#c4ca36', '#7b4e8b', '#c1151a', '#17d381',
  '#8ff31e', '#62d77b', '#ed9514', '#b47e66', '#724141', '#0c7313',
  '#7604a2', '#b8542b', '#7ec423', '#cb8596', '#b6c61c', '#1cf697',
  '#2023b0', '#a596bc', '#1d3902', '#8fe3eb', '#daf78a', '#f524b9',
  '#fa7990', '#94436d', '#281e41', '#a82157', '#e20a5f', '#19eb36',
  '#54cb07', '#32483b', '#8c74c8', '#a1fad2', '#8ca708', '#873792',
  '#d7ce15', '#271cf9', '#aa37d2', '#1d6f00', '#30b9d9', '#eea864',
  '#e9c08d', '#17d0f7', '#8c3be8', '#4a3405', '#faf842', '#f0dc4c',
  '#ba62dd', '#5eb50a', '#0a838f', '#8dc5eb', '#521dd1', '#e76a90',
  '#2f7865', '#9824f8', '#9c2fbc', '#a1077d', '#800123', '#f9f4c6',
  '#d0b265', '#017b79', '#686cf4', '#5a71e1', '#cdd785', '#752176',
  '#4487c5', '#dea957', '#7f1944', '#0bced8', '#df3915', '#41ccee',
  '#3d34d2', '#1f1a87', '#d54f39', '#0e538a', '#522490', '#ebd583',
  '#3f6a91', '#af0800', '#e18e9b', '#e8b223', '#ec0c14', '#9cc39b',
  '#99b1e6', '#ce18a7', '#4e7d98', '#9d09fc', '#37cae8', '#3a4866',
  '#27026b', '#61148f', '#cce387', '#39b2ed', '#05e153', '#e681cb',
  '#9aba65', '#a63bce', '#ae8e1c', '#1ca922', '#53c07f', '#f72bc6',
  '#ebd80e', '#00cd92', '#e1d4fb', '#b034a4',
  '#7f8d39', '#df0904', '#ee9912', '#9a5f44', '#91189b', '#f01ad6',
  '#4250a8', '#ce2213', '#d1c11b', '#c05fe7', '#8147a0', '#912531',
  '#32918e', '#32f2a5', '#83bc54', '#0d71bd', '#010846', '#adc0c4',
  '#15e704', '#19e588', '#db20c6', '#b34861', '#b8f21f', '#d69132',
  '#afc09f', '#a6971a', '#4dece6', '#9c2eea', '#f10f03', '#c63c5a',
  '#244773', '#0acecf', '#25b581', '#e420f4', '#662b19', '#556616',
  '#f988d5', '#f91abd', '#43fa96', '#9949ae', '#f98146', '#25c231',
  '#9f897d', '#79ca68', '#159537', '#bfe32d', '#a7c934', '#a6b93d',
  '#f2c255', '#0db1e9', '#c06722', '#5a8bef', '#2cce11', '#42d881',
  '#c6d4bf', '#913fa0', '#00397f', '#71eed1', '#264fe7', '#4efe58',
  '#ff4fc6', '#54bcbe', '#595083', '#5605e3', '#4c93d8', '#53de79',
  '#592151', '#dfc545', '#5e9e6c', '#a1a489', '#820c8e', '#49c1b1',
  '#c92caa', '#22ee98', '#e3b476', '#438304', '#72b6d6', '#6eef0b',
  '#71e160', '#3c5847', '#062b67', '#65b74e', '#7bd902', '#222adc',
  '#c58e8f', '#966573', '#ab5828', '#2621d4', '#a83788', '#79b922',
  '#4bdcec', '#6e2d3d', '#25be07', '#0f650b', '#b7e28d', '#51d7ac',
  '#86aae4', '#8c1fe8', '#77dec2', '#183e09',
  '#79cecd', '#1d7cc5', '#361d2a', '#ba2a4d', '#df5eed', '#8aa6bc',
  '#008e51', '#e56c32', '#556a0f', '#a1909c', '#a0de0c', '#ae5ffa',
  '#fadd0f', '#c7bc4d', '#6d8846', '#169256', '#1fdfd0', '#fcc2a2',
  '#2e461f', '#c952fc', '#d59b67', '#eda283', '#f24f4e', '#567672',
  '#f37373', '#ec7278', '#8da6fa', '#b454a3', '#de2819', '#f4bfab',
  '#4e7443', '#bebe74', '#21a746', '#45445f', '#82f922', '#b17d70',
  '#29dc0a', '#9665fd', '#bbdad1', '#e98b00', '#fc078c', '#f9ba30',
  '#f18762', '#554627', '#88b4c9', '#af99fb', '#72d424', '#6635de',
  '#7427a3', '#e32481', '#d648a8', '#78b158', '#46a99a', '#a1e48d',
  '#ea2b30', '#2e4899', '#b7daa7', '#f8fd2d', '#784e2e', '#7c667c',
  '#d3a85d', '#f792a1', '#ffb3e9', '#e6d377', '#8c0848', '#afcba2',
  '#181e63', '#71bd26', '#6c5e74', '#e56734', '#ff46c4', '#167536',
  '#87c968', '#7c82c8', '#31d107', '#5e129d', '#7e717d', '#23d3ea',
  '#d2d536', '#290149', '#6be361', '#926112', '#36ed24', '#ef5cfa',
  '#25b0d2', '#4b8e63', '#1c18e8', '#27096c', '#84062d', '#e92d52',
  '#934a5c', '#5df8ae', '#f4d8b3', '#945848',]

export const SankeyOptions = {
  sankey: {
    // height: '300px',
    link: { color: { fill: ReportColor } },
    node: {
      label: {
        fontName: 'Times-Roman',
        fontSize: 12,
        color: '#000',
        bold: true,
        italic: false
      },
      interactivity: true, // Allows you to select nodes.
      labelPadding: 6,     // Horizontal distance between the label and the node.
      nodePadding: 2,     // Vertical distance between nodes.
      width: 15,            // Thickness of the node.
      colors: ReportColor
    }
  }
};
