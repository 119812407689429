import { Avatar, Box, Button, IconButton, LinearProgress, Paper, Rating, Table, TableBody, TableCell, TableHead, TableRow, Typography, linearProgressClasses, styled } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import User from '../../../../assets/logo/user.png'
import { getParsedRating } from "../../../../components/table/type - 2";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { brandIconMap } from "../../../../components/icons";
import { useContext } from "react";
import CountryContext from "../../../../components/context/CountryContext";
import { SellerData } from "../../../../utils/data/SellerData";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '6px',
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? '#E4EAF0' : '#E4EAF0'],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#66FD40' : '#66FD40',
    },
}));

export const titleCase = (str) => {
    // return str.toLowerCase().split(' ').map(function (word) {
    //     return word.charAt(0).toUpperCase() + word.slice(1);
    // }).join(' ');
    return str;
}

const tableData = [
    {
        brand: 'Bosch',
        products: [
            'Bosch Professional GSR 120-LI Cordless Drill Driver',
            'Bosch GRO 12V-35 Heavy Duty Cordless Rotary Tool',
            'Bosch GBL 620-Watt Air Blower (Blue)',
        ]
    },
    {
        brand: 'Makita',
        products: [
            'Makita 10mm Rotary Drill (M0600B 350W)',
            'Generic Makita 12 volts max Li-ion Cordless Driver Drill DF333DWYE',
        ]
    },
    {
        brand: 'Dewalt',
        products: [
            'DEWALT D25893K-IN L-Shaped SDS-Max 10Kg Demolition Hammer',
            'DEWALT DW47401M-IN 4/100 mm Continuous Diamond Marble Cutting Blade',
            'DEWALT DW801-IN01 Heavy Duty Small Angle Grinder Engineered For Heavy Duty Applications',
        ]
    },
    // Add other brand data here
];

export const DetailedSeller = () => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, DashboardTableData } = useContext(CountryContext)
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '70px', p: 3 }}>
            <Link to={'/dashboard/Sellers'}>
                <Box sx={{ display: 'flex', alignItems: 'center', my: '10px' }}>
                    {/* <Avatar src={ArrowBackIosIcon} alt="" sx={{ height: 25, width: 'auto', mr: '5px', borderRadius: '5px' }} /> */}
                    <IconButton aria-label="Example" sx={{ display: 'flex', justifyContent: 'center', mr: '5px' }}>
                        <ArrowBackIosNewIcon sx={{ height: '14px', width: 'auto' }} color="#2B2B2B" />
                    </IconButton>
                    {/* <ArrowBackIosIcon /> */}
                    <Typography sx={{ color: '#2B2B2B', fontWeight: '600', fontSize: '16px' }}>Seller Details</Typography>
                </Box>
            </Link>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: '5px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Avatar src={User} alt={'data.Brand'} sx={{ mr: 2, width: 'auto', height: '85px', p: '10px', borderRadius: '50%', background: '#EDE6FF' }} variant="square" />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                        <Typography sx={{ color: '#25252D', fontSize: '20px', fontWeight: '500', lineHeight: '24px' }}>{SellerData[brand].subBrandDatas[subBrand].tableDatas[seller].sellername}</Typography>
                        <Typography sx={{ color: '#2B2B2B', fontSize: '12px', fontWeight: '400', lineHeight: '14px' }}>{titleCase(SellerData[brand].subBrandDatas[subBrand].tableDatas[seller].sellername)} is committed to providing each customer with the highest standard of customer service.</Typography>
                    </Box>
                </Box>
                <Button sx={{ background: '#F5F5F5', width: 'auto', flexShrink: '0', px: '25px', color: '#25252D', border: '1px solid #0000001A' }}>Last 12 months</Button>
            </Box>
            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #0000001A', my: '5px', px: '7px', py: '4px', color: '#2B2B2B', borderRadius: '3px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', fontWeight: '400', fontSize: '12px' }}>
                    <Typography sx={{ fontWeight: '600' }}>GST Number :</Typography>
                    <Typography>28NGABU7399H9Z4</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', fontWeight: '400', fontSize: '12px' }}>
                    <Typography sx={{ fontWeight: '600' }}>Contact Number : </Typography>
                    <Typography>9876543210</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', fontWeight: '400', fontSize: '12px' }}>
                    <Typography sx={{ fontWeight: '600' }}>Email ID : </Typography>
                    <Typography>Seller@gmail.com</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', fontWeight: '400', fontSize: '12px' }}>
                    <Typography sx={{ fontWeight: '600' }}>Address : </Typography>
                    <Typography>13/28, Aps Complex, Dvg Rd, Basavanagudi. Banglore - 560063</Typography>
                </Box>
            </Paper>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'normal', my: '5px', mb: '10px', color: '#2B2B2B' }}>
                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', border: '1px solid #0000001A', width: '23%', px: '14px', py: '10px', borderRadius: '3px' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '', my: '5px' }}>Seller Rating</Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '', my: '5px' }}>{SellerData[brand].subBrandDatas[subBrand].tableDatas[seller].avgRatings}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '5px' }}>
                        <Rating
                            name="rating"
                            value={getParsedRating(SellerData[brand].subBrandDatas[subBrand].tableDatas[seller].avgRatings)}
                            precision={0.1}
                            readOnly
                            emptyIcon={<StarBorderIcon fontSize="small" style={{ color: 'gray' }} />}
                            icon={<StarIcon fontSize="small" style={{ color: '#fdd835' }} />}
                        />
                        <Typography sx={{ color: '#2B2B2B', fontSize: '12px', fontWeight: '500', lineHeight: '20px' }}>({SellerData[brand].subBrandDatas[subBrand].tableDatas[seller].Total_Ratings} ratings)</Typography>
                    </Box>
                </Paper>
                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', border: '1px solid #0000001A', width: '23%', px: '14px', py: '10px', borderRadius: '3px' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '', my: '5px' }}>Positivity</Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '', my: '5px', mb: '10px' }}>88.52%</Typography>
                    {/* <LinearProgress variant="determinate" value={88.52} /> */}
                    <BorderLinearProgress variant="determinate" value={88.52} />
                </Paper>
                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', border: '1px solid #0000001A', width: '25%', px: '14px', py: '10px', borderRadius: '3px' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '16px', my: '15px' }}>Seller Total Rating </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography sx={{ fontWeight: '700', fontSize: '24px', lineHeight: '40px', my: '5px' }}>{SellerData[brand].subBrandDatas[subBrand].tableDatas[seller].Total_Ratings}</Typography>
                            <Typography sx={{ fontWeight: '500', fontSize: '12px', lineHeight: '20px', my: '5px' }}>Total Ratings</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: '700', fontSize: '24px', lineHeight: '40px', my: '5px' }}>14377</Typography>
                            <Typography sx={{ fontWeight: '500', fontSize: '12px', lineHeight: '20px', my: '5px' }}>Ratings with Feedback</Typography>
                        </Box>
                    </Box>
                </Paper>

                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', border: '1px solid #0000001A', width: '23%', px: '14px', py: '10px', borderRadius: '3px' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '', my: '5px' }}>Seller Reviews Rating </Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '', my: '5px' }}>4.8</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '5px' }}>
                        <Rating
                            name="rating"
                            value={getParsedRating(4.8)}
                            precision={0.1}
                            readOnly
                            emptyIcon={<StarBorderIcon fontSize="small" style={{ color: 'gray' }} />}
                            icon={<StarIcon fontSize="small" style={{ color: '#fdd835' }} />}
                        />
                        {/* <Typography sx={{ color: '#2B2B2B', fontSize: '12px', fontWeight: '500', lineHeight: '20px' }}>(14505 ratings)</Typography> */}
                    </Box>
                </Paper>
            </Box>
            <Table aria-label="brand table">
                <TableHead sx={{ background: "#1DA1F21A" }}>
                    <TableRow>
                        <TableCell>Brand</TableCell>
                        <TableCell>Product List</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((brandData, brandIndex) => (
                        brandData.products.map((product, productIndex) => (
                            <TableRow
                                key={`${brandIndex}-${productIndex}`}
                                sx={{}}
                            >
                                {productIndex === 0 && (
                                    <TableCell rowSpan={brandData.products.length}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar src={brandIconMap[brandData.brand + 'Nav']} alt={brandData.brand} sx={{ height: '40px', width: 'auto', borderRadius: 0, mr: '15px' }} />
                                            <Typography>{brandData.brand}</Typography>
                                        </Box>
                                    </TableCell>
                                )}
                                <TableCell sx={{ borderLeft: '1px solid #0000001A' }}>{product}</TableCell>
                            </TableRow>
                        ))
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}