import { Avatar, Box, Paper, Rating, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { brandIconMap } from "../../icons";
import { useContext } from "react";
import CountryContext from "../../context/CountryContext";
import { Link } from "react-router-dom";
import { SellerData } from "../../../utils/data/SellerData";


export const Chart = ({ i }) => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, separateProduct, setSeparateProduct, DashboardTableData } = useContext(CountryContext)
    return (
        <Paper sx={{ width: '24%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', px: '16px', py: '15px', minHeight: '10rem', boxShadow: separateProduct.subBrand === i ? 10 : 2, '&:hover': { boxShadow: 10 } }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '17px', lineHeight: '20px', color: '#474747' }}>{SellerData[brand].subBrandDetails[i].Brand}</Typography>
                {SellerData[brand].subBrandDetails[i].leading && (<Typography sx={{ fontWeight: '500', fontSize: '10px', lineHeight: '18px', color: '#FFFFFF', px: '8px', py: '2px', background: '#FBBC05', borderRadius: '16px' }}>Leading Platforms</Typography>)}
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Avatar src={brandIconMap[SellerData[brand].subBrandDetails[i].Brand]} alt={SellerData[brand].subBrandDetails[i].Brand} sx={{ mr: 2, width: 'auto', height: '45px' }} variant="square" />
                <Box sx={{ color: '#1DA1F2', display: 'flex' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Link to={'/dashboard/Sellers'} onClick={() => setSubBrand(i)}>
                            <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px', my: '3px', cursor: 'pointer' }}>Sellers:</Typography>
                        </Link>
                        <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px', my: '3px', cursor: 'pointer' }} onClick={() => setSeparateProduct({ display: true, subBrand: i })}>Products:</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: '10px' }}>
                        <Link to={'/dashboard/Sellers'} onClick={() => setSubBrand(i)}>
                            <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px', my: '3px', cursor: 'pointer' }}>{SellerData[brand].subBrandDatas[i].tableDatas.length}</Typography>
                        </Link>
                        <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px', my: '3px', cursor: 'pointer' }} onClick={() => setSeparateProduct({ display: true, subBrand: i })}>{SellerData[brand].separateProductDatas[i].productDatas.length}</Typography>
                    </Box>
                    {/* <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '20px', my: '3px' }}>{data.productTableDatas.length}</Typography> */}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Rating
                    name="rating"
                    value={parseFloat(SellerData[brand].subBrandDetails[i].ratings)}
                    precision={0.5}
                    readOnly
                    emptyIcon={<StarBorderIcon fontSize="small" style={{ color: 'gray' }} />}
                    icon={<StarIcon fontSize="small" style={{ color: '#fdd835' }} />}
                />
                <Typography sx={{ fontWeight: '400', fontSize: '12px', color: '#474747' }}>{SellerData[brand].subBrandDetails[i].NoOfRatings} reviews</Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: '600', fontSize: '12px', color: '#47474766' }}>Average Ratings</Typography>
            </Box>
        </Paper>
    );
}
