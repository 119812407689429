import { ThemeProvider } from "@mui/material";
import { store } from "./store"
import { Provider as ReduxProvider } from 'react-redux';
import theme from "./themes";
import { SnackNotification } from "./layout/PopupNotificatoin";
import { RouterProvider, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';


function Analytics2() {
  const location = useLocation();
  ReactGA.initialize('G-WGPGZK2ZCT');

  useEffect(() => {
    console.log('cookies', Cookies.get('token'), window.location.href, location, window.location);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [location]);

  return (
    <Helmet>
      {/* <link rel="canonical" href={location.pathname} /> */}
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}



function App({ children }) {
  // const location = useLocation()
  // ReactGA.initialize('G-WGPGZK2ZCT');
  // const [count, setCount] = useState(0)
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     const tempTimestamp = JSON.parse(localStorage.getItem("havudan"));
  //     console.log('end', tempTimestamp, !tempTimestamp)
  //     if (!tempTimestamp && document.visibilityState === 'hidden') {
  //       localStorage.removeItem("token");
  //       localStorage.removeItem("havudan");
  //       console.log('cleared')
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);


  // useEffect(() => {
  //   // console.log('cookies', Cookies.get('token'), window.location.href)
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  // }, [location])

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        {/* <ErrorBoundary> */}
        <SnackNotification>
          {/* <Helmet>
            <link rel="canonical" href={location.pathname} />
            <link rel="canonical" href={window.location.href} />
          </Helmet> */}
          {children}
          {/* <RouterProvider router={router} /> */}
        </SnackNotification>
        {/* </ErrorBoundary> */}
      </ThemeProvider>
    </ReduxProvider>
  )
}

export default App
